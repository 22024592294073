import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { IoIosArrowForward } from "react-icons/io";
import { _imagesURL, adminBaseURL, BaseURL, ClinicalSessionsInvitedUsersApi } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { PROFILE } from "../../constants/RoutesUrl";
import { Col, Row, Spinner } from "reactstrap";

const InvitedUsers = () => {
  const { id } = useParams(); // post_id

  // sessionStorage
  const SplashData = JSON.parse(localStorage.getItem("SplashData"));
  var user_id = SplashData?.user?.id;
  const {data:_data} = JSON.parse(localStorage.getItem("SignupData"));

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  const handleFetch = () => {
    setIsLoading(true);
    axios
      .get(adminBaseURL+`user/post/clinical-session/invited/users/${id}`,{
        headers: {
          Authorization: `Bearer ${_data?.token}`, // Ensure token is passed correctly
        },
      })
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <>
      {isLoading ? (
        <Row>
          <Col className="text-center my-3">
            <Spinner />
          </Col>
        </Row>
      ) : (
        <div>
          {data?.data ? (
  data.data.length > 0 ? (
    data.data.map((value) => (
      <div className="block-account mt-3" key={value.id}>
        <UserCard data={value} />
      </div>
    ))
  ) : (
    <h5 className="d-flex justify-content-center align-items-center text-gray" style={{ height: '200px' }}>
      There are no invited users.
    </h5>
  )
) : (
  <h5 className="d-flex justify-content-center align-items-center text-gray" style={{ height: '200px' }}>
    There are no invited users.
  </h5>
)}

        </div>
      )}
    </>
  );
};

export default InvitedUsers;

const UserCard = (props) => {
  const { data } = props;
  const Navigation = useNavigate();
  return (
    <div
      className="cursor box-shadow1 d-flex justify-content-between align-items-center p-2 manue-hover rounded"
      onClick={() => {
        Navigation(PROFILE + "/" + data?.id);
      }}
      key={data}
    >
      <div className="d-flex align-items-center gap-md-4 gap-sm-3 gap-2">
        <span>
          <img src={data?.image ? _imagesURL + data?.image : Avatar} alt="picture" />
        </span>
        <div>
          <h3 className="fs-18 fs-md-15 fw-bold text-ellips-110px">
            {data?.name}
          </h3>
          <h6 className="fs-16 text-gray m-0 text-ellips-110px">
            {data?.user_name}
          </h6>
        </div>
      </div>
      <div>
        <IoIosArrowForward size={23} />
      </div>
    </div>
  );
};
