import React from "react";

interface VideoWithAspectRatioProps {
  aspectRatio: number;
  src: string;
}

const VideoWithAspectRatio: React.FC<VideoWithAspectRatioProps> = ({
  aspectRatio,
  src,
}) => {
  const height = 555 / aspectRatio;

  return (
    <div
      style={{
        height: height > 630 ? "630px" : height + "px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <video
        src={src}
        controls
        style={{
          width: "100%",
          height: height > 630 ? "630px" : height + "px",
          objectFit: height > 630 ? "contain" : "cover",
        }}
      />
    </div>
  );
};

export default VideoWithAspectRatio;
