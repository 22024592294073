import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import login_top_img from "../../assets/images/auth/login_top_img.svg";
import login_bottom_img from "../../assets/images/auth/login_bottom_img.svg";
import email_icon from "../../assets/images/auth/login_email.svg";
import pass_icon from "../../assets/images/auth/login_password.svg";
import show_eye from "../../assets/images/auth/show_eye.svg";
import hide_eye from "../../assets/images/auth/hide_eye.svg";
import { toast } from "react-toastify";
import {
  FEED,
  FORGOT_PASSWORD,
  SPECIALIZATION,
} from "../../constants/RoutesUrl";
import { Modal, Spinner } from "reactstrap";
import axios from "axios";
import { adminBaseURL, BaseURL, LoginApi } from "../../constants/API";
import { Language } from "../../App";
import SignupForm from "../../components/auth/SignupForm";
import HospitalSignup from "../../components/auth/HospitalSignup";

const Login = () => {
  const { lang } = useContext(Language);
  const [type, setType] = useState(true);

  const [formData, setFormData] = useState({
    emailOrUsername: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [accountType, setAccountType] = useState(null);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { emailOrUsername, password } = formData;

    if (emailOrUsername === "" && password === "") {
      toast.error(lang?.please_enter_email_username_pass);
      setIsLoading(false);
      return;
    }

    if (emailOrUsername === "") {
      toast.error(lang?.please_enter_email_username);
      setIsLoading(false);
      return;
    }

    if (password === "") {
      toast.error(lang?.please_enter_pass);
      setIsLoading(false);
      return;
    }

    // const timeZone = sessionStorage.getItem("user_timezone");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    try {
      const response = await axios.post(LoginApi, {
        // loginName: emailOrUsername,
        // loginPassword: password,
        // timezone: timeZone,
         login: emailOrUsername,
        password: password,
        timezone: timeZone,
      });

      if (response.data.status) {
        if (response.data.data.verified === 1) {
          localStorage.setItem("SignupData", JSON.stringify(response.data));

          const url = sessionStorage.getItem("postUrl");
          const redirectUrl = url ? url : FEED;

          navigate(
            response.data.data.subcategory !== "" ? redirectUrl : SPECIALIZATION
          );
        } else {
          setModalStatus(true);
        }
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(lang?.an_error_ocured_please_try_again);
    } finally {
      setIsLoading(false);
    }
  };

  // set countries in local storage
  useEffect(() => {
    const url = adminBaseURL + "app/country";

    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        sessionStorage.setItem("countriesData", JSON.stringify(data.data));
      })
      .catch((err) => {
        console.error("Fetch error:", err);
      });
  }, []);
  return (
    <>
      <main
        className={`d-flex justify-content-center align-items-center py-2 min-vh-100 login-bg ${
          accountType === null ? "bg-auth" : "d-none "
        }`}
      >
        <div className="container">
          {/* Modal */}
          <Modal isOpen={modalStatus} centered zIndex={9}>
            <div className="bg-white rounded p-4">
              <div>
                <h2 className="text-blue fs-24 fs-md-16 roboto-bold">
                  {lang?.awaiting_approval}
                </h2>
                <p className="fs-18 fs-md-16 roboto-regular">
                  {lang?.awaiting_approval1} <br />
                  {lang?.awaiting_approval2} <br />
                  {lang?.awaiting_approval3}
                </p>
              </div>
              <hr />
              <div className="text-end">
                <button
                  className="btn-blue border-0 rounded px-md-3 w-lg-100 py-2"
                  onClick={() => {
                    setModalStatus(false);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </Modal>
          {/* Modal Ended */}
          <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto">
            <div className="position-relative">
              <img
                src={login_top_img}
                className="position-absolute"
                alt="loginTop"
              />
              <img
                src={login_bottom_img}
                className="position-absolute"
                alt="loginBottom"
              />
              <div className="card position-relative border-0">
                <div className="card-body px-4 py-4 overflow-y-scroll scroll-hide">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="text-center mb-lg-3 mb-md-2 mb-sm-1 mb-1">
                      <Link className="fs-4 fw-semibold text-decoration-none text-normal-primay">
                        Medical Radar
                      </Link>
                    </div>
                    <div className="mb-lg-4 mb-md-3 mb-sm-3 mb-2">
                      <h5 className="fs-md-16 text-normal-black">
                        {lang?.welcome_to} Medical Radar!
                      </h5>
                      <p className="text-normal-black m-0">
                        {lang?.sign_in_desc}
                      </p>
                    </div>

                    <div className="mb-3">
                      <label
                        for="email"
                        className="form-label fw-semibold text-normal-black"
                      >
                        {lang?.email_or_Username}
                      </label>
                      <div className="position-relative">
                        <img
                          src={email_icon}
                          className="position-absolute"
                          alt="login-email"
                        />
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              emailOrUsername: e.target.value,
                            });
                          }}
                          style={{ paddingLeft: "40px" }}
                          placeholder={lang?.enter_email_username}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex justify-content-between mb-1">
                        <label className="form-label fw-semibold mb-0 text-normal-black">
                          {lang?.password}
                        </label>
                      </div>
                      <div className="position-relative">
                        <img
                          src={pass_icon}
                          className="position-absolute"
                          alt="login-pass"
                        />
                        <input
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              password: e.target.value,
                            });
                          }}
                          type={type ? "password" : "text"}
                          className="form-control"
                          style={{ paddingLeft: "40px" }}
                          placeholder={lang?.enter_password}
                        />
                        <div>
                          <img
                            src={show_eye}
                            onClick={() => setType(!type)}
                            className={`position-absolute cursor ${
                              !type ? "d-none" : "d-block"
                            }`}
                            alt="login-show-eye"
                          />
                          <img
                            src={hide_eye}
                            onClick={() => setType(!type)}
                            className={`position-absolute cursor ${
                              type ? "d-none" : "d-block"
                            }`}
                            alt="login-show-eye"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center my-3">
                      <Link
                        to={FORGOT_PASSWORD}
                        className="text-decoration-none text-normal-primay"
                      >
                        {lang?.forgot_Password}
                      </Link>
                    </div>
                    <div className="mb-3">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="btn btn-login text-white fw-bold w-100"
                      >
                        {isLoading ? <Spinner size="sm" /> : lang?.sign_in}
                      </button>
                    </div>
                    <p className="text-center text-normal-black dropdown">
                      {lang?.new_platform}?{" "}
                      <Link
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="text-decoration-none text-normal-primay"
                      >
                        {lang?.create_account}
                      </Link>
                      <ul
                        className="dropdown-menu p-0 border-0"
                        style={{
                          color: "#000000bd",
                          boxShadow:
                            "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                        }}
                      >
                        <div className="rounded bg-white overflow-hidden">
                          <div
                            onClick={() => setAccountType(1)}
                            className="px-3 py-1 manue-hover cursor"
                          >
                            <h6 className="m-0 fs-md-12 fw-md-700 py-2">
                              {lang?.doctors}
                            </h6>
                          </div>
                          <hr className="my-0" />
                          <div
                            onClick={() => setAccountType(2)}
                            className="px-3 py-1 manue-hover cursor"
                          >
                            <h6 className="m-0 fs-md-12 fw-md-700 py-2">
                              {lang?.hospital_Private_Society}
                            </h6>
                          </div>
                          <hr className="my-0" />
                          <div
                            onClick={() =>
                              toast.info(lang?.this_sec_available_soon)
                            }
                            className="px-3 py-1 manue-hover cursor"
                          >
                            <h6 className="m-0 fs-md-12 fw-md-700 py-2">
                              {lang?.organizational_Company}
                            </h6>
                          </div>
                        </div>
                      </ul>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* create account page */}
      {accountType === 1 ? (
        <SignupForm setAccountType={setAccountType} />
      ) : accountType === 2 ? (
        <HospitalSignup setAccountType={setAccountType} />
      ) : (
        ""
      )}
    </>
  );
};

export default Login;
