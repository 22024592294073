import { ShimmerCategoryItem } from "react-shimmer-effects";
import { EventCard } from "../feeds/SuggestedCoursesEvents";
import SearchResultCard from "./SearchResultCard";

export default function EventsResultComp({ data, keyword, loading }) {
  return (
    <SearchResultCard
      title="Events"
      link={`/search/events?keyword=${keyword}`}
      seeAll={!loading && data?.length > 2}
    >
      {loading ? (
        <div className="mx-3 pt-3">
          {[1, 2, 3].map(() => (
            <ShimmerCategoryItem
              hasImage
              imageType="thumbnail"
              imageWidth={50}
              imageHeight={50}
              text
              contentCenter
            />
          ))}
        </div>
      ) : data && data?.length > 0 ? (
        data?.slice(0, 3).flatMap((item, index) => (
          <div className="p-3" key={index}>
            <EventCard event={item} />
          </div>
        ))
      ) : data && data?.length === 0 ? (
        <div className="text-center py-2">
          <p className="fs-14 mb-0 roboto-medium text-muted">
            No results found
          </p>
        </div>
      ) : null}
    </SearchResultCard>
  );
}
