
import { useContext, useState, useRef, useEffect } from "react";
import { LuImagePlus, LuSmile } from "react-icons/lu";
import { LoginUserData } from "../../App";
import { FiSend } from "react-icons/fi";
import {  selectedChatContext } from "../../pages/chat/ChatModule";
import { toast } from "react-toastify";
import { adminBaseURL, BaseURL } from "../../constants/API";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import {  RefetchMessagesContext } from "./Chats";
import { ChatsContext } from "./ChatsProvider";
import { getToken } from "../../shared/token";

export default function ChatInput() {
  const { splashData } = useContext(LoginUserData);
  const { selectedChat } = useContext(selectedChatContext);
const { updateChatMessage ,setChats} = useContext(ChatsContext);
 
  const {refetchUserMessages, refetchGroupMessages } = useContext(
    RefetchMessagesContext
  );

  const [message, setMessage] = useState("");
  const [image, setImage] = useState(null);
  const [isApiCalling, setIsApiCalling] = useState(false);
  const textareaRef = useRef(null);


  
  const fetchChats = async () => {
   console.log("run function")
    try {
      const response = await axios.get(
        `${adminBaseURL}user/chat/conversation`
      ,{
        headers:{
          Authorization: `Bearer ${getToken()}`
        }
      });
      if (response.data.status === true) {
        setChats(response.data.data);
        console.log("response.data.data",response.data.data)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching chats. Please try again.");
    } finally {
      // setLoading(false);
    }
  };
const handleUsersMessage = async () => {
  if (!message && !image) {
    toast.error("Please enter a message or select an image to send");
    return;
  }

  const formData = new FormData();

  if (selectedChat?.group_id === 0) {
    // For individual chat
    formData.append(
      "to",
      selectedChat?.to === splashData?.user.id
        ? selectedChat?.from
        : selectedChat?.to
    );
  } else {
    // For group chat
    formData.append("group_id", selectedChat?.group_id);
  }

  // Check if image is present first
  if (image) {
    formData.append("attachment_type", "image");
    formData.append("attachment", image); // Send selected image
    if (message.length > 0) {
      formData.append("message", message.trim()); // Send message with line breaks preserved
    }
  } else if (message.length > 0) {
    // If no image, but message is present, set attachment_type to text
    formData.append("message", message.trim());
    formData.append("attachment_type", "text");
  }

  let apiUrl;
  setIsApiCalling(true);
  if (selectedChat?.group_id === 0) {
    apiUrl = `${adminBaseURL}user/chat/messaging`; // URL for individual chat
  } else {
    apiUrl = `${adminBaseURL}user/group-chat/messaging`; // URL for group chat
  }
  setMessage("");
  const response = await axios.post(apiUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getToken()}`
    },
  });

  console.log("response.data.result", response.data.result);
  if (response.data.result === true || response.data.status) {
    setMessage("");
    setImage(null); // Clear image after sending
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset textarea height
    }
    setIsApiCalling(false);
    // fetchChats();

    if (selectedChat?.group_id === 0) {
      refetchUserMessages();
    fetchChats();

    } else {
      refetchGroupMessages();
    fetchChats();

    }
  } else if (response.data.status === false) {
    toast.error(response.data.action);
  }
};

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Set the selected image
  };
  const handleInputChange = (e) => {
    const textarea = textareaRef.current;
  
    // Store current scroll position
    const currentScrollTop = textarea.scrollTop;
  
    // Reset height to auto for recalculation
    textarea.style.height = "auto";
  
    // Expand textarea height based on scroll height up to a max height
    if (textarea.scrollHeight <= 50) {
      textarea.style.height = `${textarea.scrollHeight}px`;
    } else {
      textarea.style.height = "50px"; // Lock height at 70px with scrollbar
    }
  
    setMessage(e.target.value); // Set the message content
  
    // Check if the textarea is not fully scrolled down before adjusting
    if (textarea.scrollHeight > textarea.clientHeight && currentScrollTop === textarea.scrollHeight - textarea.clientHeight) {
      textarea.scrollTop = textarea.scrollHeight; // Keep the textarea scrolled to the bottom
    }
  };
  
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default Enter behavior
      handleUsersMessage(); // Send the message and image if available
    }
  };

  return (
    <div className="container mt-2">
      <div className="chat-input-container d-flex align-items-center p-2 border rounded">
        {/* Attachment Icon */}
        {image ? (
  
          <div className="mt-1 position-relative" style={{ display: "inline-block" }}>
  <img
    src={URL.createObjectURL(image)}
    width={40}
    className="rounded"
    alt="preview"
    style={{ display: "block" }} // Ensure image is block-level to control its container
  />
  
  <IoClose
    size={18}
    className="text-danger cursor position-absolute"
    style={{
      top: "-5px", // Position the close button above the image corner
      right: "-5px", // Slightly outside the top-right corner of the image
      background: "white", // Optional: add a white background for better visibility
      borderRadius: "50%", // Make the background circular
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add a slight shadow for contrast
    }}
    onClick={() => setImage(null)} // Remove the image preview
  />
</div>

        ) : (
          <LuImagePlus
            size={24}
            onClick={() => document.getElementById("imageInput").click()}
            className="mr-4 cursor"
          />
        )}

        {/* Textarea for message input */}
        <textarea
          ref={textareaRef}
          className="form-control border-0 mx-2"
          placeholder="Message..."
          rows="1"
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={{
            flex: 1,
            borderRadius: "20px",
            resize: "none",
            minHeight: "40px",
            maxHeight: "150px",
            overflowY: "auto",
            outline: 'none', boxShadow: 'none',
            whiteSpace: "pre-wrap", // Ensure the textarea preserves newlines
          }}
          
        />

        {/* Image upload trigger */}
        <input
          type="file"
          id="imageInput"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />

        {/* Send Button */}
        {(message.length > 0 || image)  && !isApiCalling&&(
          <button className="btn" onClick={handleUsersMessage} style={{color:"white",backgroundColor:"#1dc1dd"}}>
            <FiSend size={20} />
          </button>
     
        ) } 
        {isApiCalling&& (
            <button class="btn" type="button" style={{color:"white",backgroundColor:"#1dc1dd"}} disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="visually-hidden">Loading...</span>
        </button>
        )}
      </div>
    </div>
  );
}


