import React, { useContext, useState } from "react";
import {
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroller";
// Components
import Cropper from "../../cropper/components/CroppersWizard.tsx";
import { _imagesURL, adminBaseURL, BaseURL } from "../../constants/API.js";
import { FEED, PROFILE } from "../../constants/RoutesUrl.js";
// Images
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import UploadIcon from "../../assets/images/icon/upload.png";
import { Language } from "../../App.js";
import { getToken } from "../../shared/token.js";
import moment from "moment";

const AddWebinar = () => {
  const { lang } = useContext(Language);
  const Navigate = useNavigate();
  // sessionStorage
  const SplashData = JSON.parse(localStorage.getItem("SplashData"));
  const subcategories = SplashData?.home?.subcategories;
  const user_id = SplashData?.user?.id;
  const {data:userToken} = JSON.parse(localStorage.getItem("SignupData"));
  const navigate = useNavigate();
  
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [invitePage, setInvitePage] = useState(1);
  const [inviteSpin, setInviteSpin] = useState(false);
  const [webinarId, setWebinarId] = useState("");
  const [formInput, setFormInput] = useState({
    caption: null,
    shedule_date: "",
    shedule_time: null,
    subcategory_id: null,
    title: null,
    visibility: null,
    duration: 0,
    type: "webinar",
  });
  const [progress, setProgress] = useState(0);
  const [coverImg, setCoverImg] = useState([]);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [fileUploadRefs, setFileUploadRefs] = useState({});
  const [ar, setAR] = useState(undefined);
  const [minARatio, setMinAR] = useState(undefined);
  const [maxARatio, setMaxAR] = useState(undefined);
  const [resizable, setResizable] = useState(true);
  const [hasSettings, setHasSettings] = useState(true);
  const [updateCoordinatesCheck, setUpdateCoordinatesCheck] = useState(true);
  const [height, setHeight] = useState(undefined);
  const [width, setWidth] = useState(undefined);
  const [top, setTop] = useState(undefined);
  const [left, setLeft] = useState(undefined);
  const [inviteData, setInviteData] = useState({});
  const [numberOfImages, setNumberOfImages] = useState(
    coverImg.length < 1 ? coverImg.length : 1
  );
  const [imagePreviewModal, setImagePreviewModal] = useState(false);

  // Functions
  function isValidFutureDate(inputDate) {
    // Parse the input date
    const userDate = new Date(inputDate);

    // Check if the parsed date is a valid date
    if (isNaN(userDate.getTime())) {
      toast.error(lang?.error_note_number);
      return false;
    }

    // Get today's date
    const today = new Date();

    // Set the time to midnight for both dates to compare only the dates
    userDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    // Compare the dates
    if (userDate < today) {
      toast.error(lang?.date_should_greater_todays_date);
      return false;
    }
    return true;
  }
  const authorize ={
        
    headers: {
        Authorization: `Bearer ${getToken()}`, // Set the Authorization header
    },

}

  const handleInvitationList = (webinar_id) => {
    axios
      .get(
        BaseURL +
          "user/post/webinar/users/" +
          webinar_id +
          "/" +
          user_id +
          "?page=" +
          JSON.stringify(invitePage)
      )
      .then((res) => {
        if (inviteList.length) {
          setInviteList(inviteList.concat(res.data.data.data));
          setInviteData(res.data.data);
          setInvitePage(res.data.data.current_page + 1);
          return 0;
        }
        if (res.data.status) {
          setInviteList(res.data.data.data);
          setInviteData(res.data.data);
          setInvitePage(res.data.data.current_page + 1);
          setInviteSpin(false);
        } else {
          toast.error(res.data.error);
          setInviteSpin(false);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleInvite = (userId) => {
    setIsLoading(true);
    axios
      .get(adminBaseURL + "user/post/webinar/invite/" + webinarId + "/" + userId,authorize)
      .then((res) => {
        if (res.data.status) {
          const update = inviteList.map((value) => {
            if (value.id === userId) {
              return { ...value, invite: !value.invite };
            }
            return value;
          });
          setInviteList(update);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    // Converting to unixTimeStamp
    const combinedDateTimeString = `${formInput.shedule_date}T${formInput.shedule_time}`;
    const combinedDateTime = new Date(combinedDateTimeString);
    const start_timestamp = combinedDateTime.getTime();
    const _start_timestamp = Math.floor(start_timestamp / 1000);

    // converting to 12-hrs
    const shedule_time = combinedDateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    if (formInput.duration > 120) {
      toast.error(lang?.webinar_duration_greater_120_mins);
      return 0;
    }

    if (formInput.duration < 0) {
      toast.error(lang?.enter_correct_time_limit);
      return 0;
    }
    const currentTime = moment(); // Current time
    const selectedTime = moment(formInput.shedule_time, 'HH:mm'); // Parse selected time
  
    // Check if the selected time is at least 5 minutes from the current time
    if (selectedTime.diff(currentTime, 'minutes') < 5) {
      toast.error("Select atleast after 5 minutes of current time.");
      return;
    }
    if (combinedDateTime < new Date()) {
      toast.error(lang?.start_time_less_current_time);
      return 0;
    }

    isValidFutureDate(formInput.shedule_date);

    const data = {
      start_timestamp:_start_timestamp,
      type: formInput.type,
      caption: formInput.caption,
      shedule_date: formInput.shedule_date,
      shedule_time,
      // :shedule_time,
      // formInput.shedule_time,
      category_id: "3",
      subcategory_id: formInput.subcategory_id,
      title: formInput.title,
      visibility: formInput.visibility,
      duration: formInput.duration,
      mediaBase64: coverImg,
    };
    console.log("data",data)
    setIsLoading(true);
    axios
      .post(adminBaseURL + "user/post/create" , data,{
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken?.token}`,
        },
      }, {
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          res.data.action.map((value) => {
            toast.success(value);
          });
          setInviteModal(true);
          setInviteSpin(true);
          setWebinarId(res.data.post.id);
          handleInvitationList(res.data.post.id);
          navigate(FEED);
        } else {
          setIsLoading(false);
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
   setFormInput({
  ...formInput,
  caption: null,
  shedule_date: "",
  shedule_time: null,
  subcategory_id: null,
  title: null,
  visibility: null,
  duration: 0,
  type: "webinar",
});

  };

  return (
    <>
      <Col md={8} className="bg-white p-3 br-16">
        <Row>
          <Col className="text-center">
            <h5 className="mb-0">{lang?.create_webinar}</h5>
          </Col>
        </Row>

        <Row className="my-3">
          <Col>
            {coverImg.length ? (
              <div
                className="position-relative cursor"
                style={{
                  minHeight: "15rem",
                  borderRadius: "6px",
                  backgroundColor: "rgba(0,0,0,0.70)",
                }}
              >
                <button
                  className="bg-white position-absolute end-0 me-3 mt-3 br-6 bg-white border-0 px-2 py-0 fs-16 roboto-bold"
                  style={{ zIndex: "1" }}
                  onClick={() => {
                    setCoverImg([]);
                  }}
                >
                  x
                </button>
                <img
                  src={coverImg}
                  className="w-100 br-16"
                  style={{ objectFit: "cover", height: "15rem" }}
                  onClick={() => setImagePreviewModal(true)}
                  alt="picture"
                />
              </div>
            ) : (
              <div
                className="cursor"
                style={{
                  border: "1px dashed #A2A2A2",
                  backgroundColor: "#F2FCFE",
                  minHeight: "12rem",
                  borderRadius: "6px",
                }}
                onClick={() =>
                  fileUploadRefs[numberOfImages - 1].current.click()
                }
              >
                <div className="text-center mt-5">
                  <img src={UploadIcon} width={40} height={40} alt="picture" />
                  <h4
                    className="fs-14 roboto-medium pt-3"
                    style={{ color: "#00c5de" }}
                  >
                    {lang?.add_webinar_image}
                  </h4>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Row className="my-3">
            <Col>
              <Label>{lang?.title} *</Label>
              <Input
                required
                type="text"
                placeholder={lang?.webinar_title}
                value={formInput.title}
                onChange={(e) => {
                  setFormInput({ ...formInput, title: e.target.value });
                }}
              ></Input>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <Label>{lang?.category} *</Label>
              <Input
                required
                type="select"
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    subcategory_id: e.target.value,
                  });
                }}
              >
                <option value="">{lang?.webinar_category}</option>
                {subcategories.map((value) => (
                  <option key={value.id} value={value.id}>
                    {value.name}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <Label>{lang?.start_date} *</Label>
              <Input
                required
                type="date"
                onChange={(e) => {
                  setFormInput({ ...formInput, shedule_date: e.target.value });
                }}
              ></Input>
            </Col>
            <Col>
              <Label>{lang?.start_time} *</Label>
              <input
                required
                type="time"
                onChange={(e) => {
                  const selectedTime = e.target.value;
                  // const newTime = moment(selectedTime, 'HH:mm')
                  //   .add(5, 'minutes')
                  //   .format('HH:mm'); // Format it back to 'HH:mm'
                  setFormInput({ ...formInput, shedule_time: selectedTime });
                }}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm={6}>
              <Label>{lang?.time_limit} *</Label>
              <InputGroup>
                <Input
                  required
                  value={formInput?.duration}
                  type="number"
                  placeholder={lang?.webinar_duration_limit}
                  onChange={(e) => {
                    setFormInput({
                      ...formInput,
                      duration: e.target.value,
                    });
                  }}
                />
                <InputGroupText>{lang?.min}</InputGroupText>
              </InputGroup>
            </Col>
            <Col sm={6} className="mt-sm-0 mt-3">
              <Label>{lang?.webinar_visibility} *</Label>
              <Input
                required
                type="select"
                onChange={(e) => {
                  setFormInput({ ...formInput, visibility: e.target.value });
                }}
              >
                <option value="">{lang?.chose_webinar_comitment}</option>
                <option value="Public">{lang?.public}</option>
                <option value="Private">{lang?.private}</option>
              </Input>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <Label>{lang?.description} *</Label>
              <Input
                required
                type="textarea"
                placeholder={lang?.description}
                onChange={(e) => {
                  setFormInput({ ...formInput, caption: e.target.value });
                }}
              ></Input>
            </Col>
          </Row>

          {!isLoading ? (
            <Row>
              <Col>
                <button
                  disabled={
                    !coverImg.length ||
                    isLoading ||
                    !formInput.caption ||
                    !formInput.duration ||
                    !formInput.shedule_date ||
                    !formInput.shedule_time ||
                    !formInput.title ||
                    !formInput.visibility
                  }
                  type="submit"
                  className="btn-blue border-0 rounded w-100 py-3"
                >
                  {lang?.submit}
                </button>
              </Col>
            </Row>
          ) : (
            <Row className="my-3">
              <Col>
                <div className="w-50 pb-2">
                  <p>
                    {lang?.uploaded_para}
                    <br />- {progress} {lang?.uploaded}
                  </p>
                </div>
                <div>
                  <Progress animated color="primary" striped value={progress} />
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </Col>

      {/* Invite Modal */}
      <Modal isOpen={inviteModal} centered zIndex={9} scrollable size="lg">
        <ModalHeader
          toggle={() => {
            setInviteModal(false);
            Navigate(FEED);
          }}
        >
          {lang?.invite}
        </ModalHeader>
        <ModalBody>
          {/* <Row>
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder={lang?.search}
              />
            </Col>
          </Row> */}

          {inviteSpin ? (
            <Row>
              <Col className="text-center">
                <Spinner />
              </Col>
            </Row>
          ) : (
            <InfiniteScroll
              pageStart={0}
              loadMore={() => handleInvitationList(webinarId)}
              hasMore={
                inviteData?.current_page !== inviteData?.last_page
                  ? true
                  : false
              }
              loader={
                <Row>
                  <Col className="text-center">
                    <Spinner size="sm" />
                  </Col>
                </Row>
              }
              useWindow={false}
            >
              <Row>
                {inviteList.map((value) => (
                  <Col xs={12}>
                    <div className="border border-1 border-inherit rounded my-2">
                      <div className="d-flex m-3 justify-content-between">
                        <div className="d-flex gap-2">
                          <Link
                            to={PROFILE + "/" + value.id}
                            className="bg-white dropdown-avatar-shadow align-self-center"
                            style={{
                              borderRadius: "19px",
                              maxWidth: "4rem",
                              maxHeight: "4rem",
                              padding: "1.8px",
                            }}
                          >
                            <img
                              src={
                                value?.image ? _imagesURL + value.image : Avatar
                              }
                              alt="picture"
                              className="img-fluid "
                              style={{ borderRadius: "17px" }}
                            />
                          </Link>
                          <div className="align-self-center">
                            <Link
                              to={PROFILE + "/" + value.id}
                              className="fs-14 roboto-bold text-dark"
                            >
                              {value.name}
                            </Link>
                            <p className="mb-0 fs-12 roboto-regular">
                              {value.user_name}
                            </p>
                          </div>
                        </div>
                        <div className="align-self-center">
                          <button
                            className="btn btn-white border border-1 px-3 py-1"
                            disabled={isLoading}
                            onClick={() => {
                              handleInvite(value.id);
                            }}
                          >
                            <span className="roboto-medium">
                              {value.invite ? lang?.undo : lang?.invite}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          )}
        </ModalBody>
      </Modal>
      {/* End invite modal */}

      {/* Image Preview Modal */}
      <Modal
        isOpen={imagePreviewModal}
        zIndex={9}
        size="xl"
        centered
        toggle={() => setImagePreviewModal(false)}
      >
        <div>
          <img src={coverImg} alt="picture" className="img-fluid" />
        </div>
      </Modal>
      {/* End Image preview modal */}

      <Cropper
        images={coverImg}
        setImages={setCoverImg}
        setImagesArr={setCoverImg}
        value={coverImg}
        max={1}
        allowCrop={true}
        ar={ar}
        setAR={setAR}
        setMaxAR={setMaxAR}
        setMinAR={setMinAR}
        resizable={resizable}
        setResizable={setResizable}
        handleClose={() => {
          setCropperOpen(false);
        }}
        fileUploadRefs={fileUploadRefs}
        setFileUploadRefs={setFileUploadRefs}
        numberOfImages={numberOfImages}
        setNumberOfImages={setNumberOfImages}
        hasSettings={hasSettings}
        setHasSettings={setHasSettings}
        updateCoordinatesCheck={updateCoordinatesCheck}
        setUpdateCoordinatesCheck={setUpdateCoordinatesCheck}
        height={height}
        setHeight={setHeight}
        width={width}
        setWidth={setWidth}
        top={top}
        setTop={setTop}
        left={left}
        setLeft={setLeft}
        cropperOpen={cropperOpen}
        setCropperOpen={setCropperOpen}
      />
    </>
  );
};

export default AddWebinar;
