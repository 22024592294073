import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { savePaypalEmail } from '../../../services/walletApi';

const PayPalCallback = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // React Query Mutation
    const { mutate: saveEmail, isLoading } = useMutation(savePaypalEmail, {
        onSuccess: (data) => {
            toast.success('PayPal email saved successfully');
            // Navigate to the main page or wallet
            if(data.status)
            navigate('/wallet');
        },
        onError: (error) => {
            console.error('Error saving PayPal email:', error);
            toast.error('Failed to save PayPal email. Please try again.');
            // Navigate to failure page if needed
            navigate('/wallet');
        }
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const success = queryParams.get('success');
        const email = queryParams.get('email');
        const code = queryParams.get('code');
        // success === 'true' && email &&
        if ( code) {
            console.log('PayPal Login Successful:', { email, code });
            
            // Call the mutation to save email and code
            saveEmail(code);
        } else {
            console.error('PayPal login failed or missing data');
            // toast.error('PayPal login failed or incomplete data provided.');
            // Redirect back on failure
            navigate('/wallet');
        }
    }, [location, navigate, saveEmail]);

    return (
        <div style={styles.container}>
        <div style={styles.content}>
            {isLoading ? 'Processing PayPal Login...' : 'Redirecting...'}
        </div>
    </div>
    );
};
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        width: '100vw', // Full viewport width
        backgroundColor: '#f8f9fa' // Optional background color
    },
    content: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textAlign: 'center'
    }
};
export default PayPalCallback;

// import React, { useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';

// const PayPalCallback = () => {
//     const location = useLocation();
//     const navigate = useNavigate();

//     useEffect(() => {
//         const queryParams = new URLSearchParams(location.search);
//         const success = queryParams.get('success');
//         const email = queryParams.get('email');
//         const code = queryParams.get('code');
// console.log("code",code)
//         if (success === 'true' && email) {
//             console.log('PayPal Login Successful:', { email, code });
            
//             // Update your app state or context with the email
//             // For example, call a function to save the email in your application state
//             saveEmail(email, code); 

//             // Redirect back to Wallet or main page
//             navigate('/');
//         } else {
//             console.error('PayPal login failed or missing data');
//             // Redirect back on failure or show an error message
//             navigate('/');
//         }
//     }, [location, navigate]);

//     const saveEmail = (email, code) => {
//         // Update your global app state, context, or make an API call to save the email and code
//         console.log("Saving email:", email);
//         // Example API call to save email
//     };

//     return <div>Processing PayPal Login...</div>;
// };

// export default PayPalCallback;
