import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { Language, LoginUserData } from "../../App";
import Search from "../../assets/images/icons/search.svg";
import InfiniteScroll from "react-infinite-scroller";
import ImageComponent from "../common/ImageComponent";
import { adminBaseURL, BaseURL, GetGroupParticipants } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import axios from "axios";
import { toast } from "react-toastify";
import { getToken } from "../../shared/token";

const EditGroupParticipantsModal = ({ isOpen, toggle,currentId }) => {
  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);
  const authorize ={
        
    headers: {
        Authorization: `Bearer ${getToken()}`, // Set the Authorization header
    },

}
  const [state, setState] = useState({
    search: "",
    isLoading: false,
    page: 1,
    hasMoreFollowing: true,
    groupParticipants: [],
    isLoadingParticipants: false,
    selectedParticipants: [],
    isLoadingAddParticipants: false,
    searchResults: [],
  });

  const {
    search,
    isLoading,
    page,
    hasMoreFollowing,
    groupParticipants,
    searchResults,
    selectedParticipants,
  } = state;

  const handleCheckboxChange = (item) => {
    const userId = item.id;
    setState((prevState) => {
      const isSelected = prevState.selectedParticipants.includes(userId);
      return {
        ...prevState,
        selectedParticipants: isSelected
          ? prevState.selectedParticipants.filter((id) => id !== userId)
          : [...prevState.selectedParticipants, userId],
      };
    });
  };

  const handleSearch = useCallback(
    async (e) => {
      const searchValue = e.target.value;
      setState((prevState) => ({ ...prevState, search: searchValue }));

      try {
        const response = await axios.post(
          "https://medicalradar.es/api/global/search",
          {
            user_id: splashData?.user?.id,
            keyword: searchValue,
            type: "user",
          }
        );
        setState((prevState) => ({
          ...prevState,
          searchResults: response?.data?.data?.data,
        }));
      } catch (error) {
        toast.error("Failed to fetch search results. Please try again.");
      }
    },
    [splashData?.user?.id]
  );

  const fetchData = useCallback(async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const res = await axios.get(
        `${adminBaseURL}user/group-chat/user/list/${currentId}?page=${page}`
      ,authorize);

      const updatedValue = res?.data?.data?.data;
      setState((prevState) => ({
        ...prevState,
        groupParticipants:
          page === 1
            ? updatedValue
            : [...prevState.groupParticipants, ...updatedValue],
        hasMoreFollowing: res?.data?.data?.current_page < res?.data?.data?.last_page,
        isLoading: false,
      }));
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, [page, splashData?.user?.id]);

  useEffect(() => {
    if (hasMoreFollowing) {
      fetchData();
    }
  }, [page, fetchData, hasMoreFollowing]);

  const loadMore = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }));
  }, []);

  const renderParticipants = (participants) =>
    participants.map((item) => (
      <div
        className="d-flex align-items-center justify-content-between p-2 border-bottom border-inherit"
        key={item.id}
      >
        <div className="d-flex align-items-center gap-3">
          <div>
            <ImageComponent
              src={item.image ? BaseURL + item.image : Avatar}
              alt="..."
              loader_height="60px"
              loader_width="60px"
              img_height="60px"
              img_width="60px"
              compClassName="rounded-circle"
              roundShimmerSize={40}
            />
          </div>
          <div>
            <h3 className="fs-18 fs-md-15 roboto-medium mb-0 text-ellips-110px">
              {item.name}
            </h3>
            <h6 className="fs-14 fs-md-12 roboto-regular text-gray mb-0 text-ellips-110px">
              {item.user_name}
            </h6>
          </div>
        </div>
        <div>
          <input
            type="checkbox"
            onChange={() => handleCheckboxChange(item)}
            className="bg-blue border-0 pxy-17 br-6 text-white rounded-circle fs-18 fs-md-16"
            style={{ transform: "scale(1.5)" }}
          />
        </div>
      </div>
    ));
  return (
    <>
      <Modal scrollable size="lg" centered isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4 className="mb-0 roboto-bold fs-18">{lang?.add_participants}</h4>
        </ModalHeader>
        <ModalBody>
          <div className="py-2">
            <div className="position-relative">
              <img
                src={Search}
                className="position-absolute start-0 ms-3 mt-11"
                width={20}
                height={20}
                alt="..."
              />
              <input
                type="text"
                className="form-control searchgroupmembers py-2 ps-5 rounded-pill outline-none"
                placeholder={lang?.search}
                onChange={handleSearch}
                value={search}
              />
            </div>
          </div>

          <Container fluid>
            {isLoading ? (
              <Spinner />
            ) : (
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMoreFollowing}
                dataLength={groupParticipants?.length}
                loader={<Spinner />}
                useWindow={false}
              >
                <div>
                  {search && searchResults?.length > 0
                    ? renderParticipants(searchResults)
                    : renderParticipants(groupParticipants)}
                </div>
              </InfiniteScroll>
            )}
          </Container>
        </ModalBody>
        <ModalFooter>
          <button className="btn-blue border-0 roboto-medium fs-16 px-4 py-2">
            Save
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditGroupParticipantsModal;
