import React, { useState } from "react";
import { Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import SearchFilter from "../../components/search/SearchFilter";
import SearchSidebarFilter from "../../components/search/SearchSidebarFilter";
import DoctorsResultsComp from "../../components/search/DoctorsResultsComp";
import SearchHashtags from "../../components/search/SearchHashtags";
import PostsResultsComp from "../../components/search/PostsResultsComp";
import EventsResultComp from "../../components/search/EventsResultComp";
import CoursesResultComp from "../../components/search/CoursesResultComp";
import WebinarResultComp from "../../components/search/WebinarResultComp";
import JobsResultComp from "../../components/search/JobsResultComp";
import ClinicalStudiesResults from "../../components/search/ClinicalStudiesResults";
import { useLocation } from "react-router-dom";
import { getAllSearchAgainstKeywordApi } from "../../services/searchApis";
import { useQuery } from "react-query";
import FeedAdvertisement from "../../components/feeds/FeedAdvertisement";
const Search = () => {
  const query = useLocation().search;
  const keyword = decodeURIComponent(query.split("=")[1]);
  const [leftAds,setLeftAds] = useState([])
  const [rightAds,setRightAds] = useState([])
  const { data: response, isLoading } = useQuery({
    queryKey: ["search-against-keyword", keyword],
    queryFn: () => getAllSearchAgainstKeywordApi({keyword}),
    onSuccess: (data) => {
      if (data?.status) {
        setLeftAds(data?.ads?.search_left)
        setRightAds(data?.ads?.search_right)
      }},
    enabled: !!keyword,
  });
  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
          <div className="position-relative px-0">
            <SearchFilter keyword={keyword} />
          </div>
        </Row>
        <div className="d-flex justify-content-center gap-3">
          <div>
            <div
              className="d-flex flex-column gap-3 position-sticky"
              style={{ width: "335px", top: "3rem" }}
            >
              <SearchSidebarFilter />
              <FeedAdvertisement ads={leftAds} />
            </div>
          </div>
          <div className="mb-3" style={{ width: "570px" }}>
           {response?.data?.doctors?.length>0&& <DoctorsResultsComp
              loading={isLoading}
              data={response?.data?.doctors}
              keyword={keyword}
            />
           }
           {response?.data?.posts?.length>0&& <PostsResultsComp
              loading={isLoading}
              data={response?.data?.posts}
              keyword={keyword}
            />}

           {response?.data?.webinars?.length>0&& <WebinarResultComp
              data={response?.data?.webinars}
              keyword={keyword}
              loading={isLoading}
            />
           }
           {response?.data?.studies?.length>0&& <ClinicalStudiesResults
              loading={isLoading}
              data={response?.data?.studies}
              keyword={keyword}
            />
           }
           {response?.data?.courses?.length>0&&  <CoursesResultComp
              data={response?.data?.courses}
              keyword={keyword}
              loading={isLoading}
            />}

            {response?.data?.events?.length>0&&<EventsResultComp
              data={response?.data?.events}
              keyword={keyword}
              loading={isLoading}
            />
            }
            {response?.data?.jobs?.length>0&&
            <JobsResultComp
              data={response?.data?.jobs}
              keyword={keyword}
              loading={isLoading}
            />}
          </div>
          <div
            className="d-flex flex-column gap-3"
            style={{ width: "335px", top: "5rem" }}
          >
            <SearchHashtags data={response?.hash_tags} />
            <FeedAdvertisement ads={rightAds} />
          </div>
        </div>
      </Container>
    </>
  );
};

export default Search;
