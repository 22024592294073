import { useNavigate } from "react-router-dom";

export default function SearchResultCard({ title, children, link, seeAll }) {
  const navigate = useNavigate();
  return (
    <div
      id={title.toLowerCase()}
      className="bg-white border rounded"
      style={{ marginTop: "1rem", width: "570px" }}
    >
      <div
        className="p-3"
        style={{ borderBottom: "1px solid rgba(29, 193, 221, 0.2)" }}
      >
        <h5 className="mb-0 fs-16 roboto-bold">{title}</h5>
      </div>
      {children}
      {seeAll && (
        <div
          className="text-center py-3"
          style={{ borderTop: "1px solid rgba(29, 193, 221, 0.2)" }}
        >
          <h5
            className="roboto-medium fs-14 cursor mb-0"
            style={{ color: "rgba(29, 193, 221, 1)" }}
            onClick={() => {
              navigate(link);
            }}
          >
            See all results
          </h5>
        </div>
      )}
    </div>
  );
}
