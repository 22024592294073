import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Col,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    Row,
    Spinner,
} from "reactstrap";
import { Language, LoginUserData } from "../../App";
import axios from "axios";
import { toast } from "react-toastify";
import { _imagesURL, adminBaseURL, imagesURL } from "../../constants/API";
import { CiSearch } from "react-icons/ci";
import { PROFILE } from "../../constants/RoutesUrl";
import InfiniteScroll from "react-infinite-scroller";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Link } from "react-router-dom";
import { useQueryClient } from "react-query";

const SearchChatUsersModal = ({ isOpen, toggle, setSelectedChat }) => {
    const queryClient = useQueryClient();
    const { lang } = useContext(Language);
    const { splashData } = useContext(LoginUserData);
    const localData = JSON.parse(localStorage.getItem("SplashData"));
    const user_id = localData?.user?.id;
    const { data: userToken } = JSON.parse(localStorage.getItem("SignupData"));
    const [isLoading, setIsLoading] = useState(false);
    const [userList, setUserList] = useState([]);
    const [searchArr, setSearchArr] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const debounceTimeout = useRef(null);

    const fetchUsers = async (pageNumber = 0) => {
        try {
            const response = await axios.get(`${adminBaseURL}user/users?page=${pageNumber}`, {
                headers: {
                    'Authorization': `Bearer ${userToken?.token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching users:', error);
            throw error;
        }
    };

    const loadUsers = async () => {
        setIsLoading(true);
        try {
            const data = await fetchUsers(page);
            console.log("data?.data?.data", data?.data?.data)
            if (data?.data?.data.length) {
                setUserList((prev) => {
                    // Filter out any duplicate users based on user IDs
                    const newUsers = data?.data?.data.filter(
                        (newUser) => !prev.some((existingUser) => existingUser.id === newUser.id)
                    );
                    return [...prev, ...newUsers];
                });
                // setUserList((prev) => [...prev, ...data?.data?.data]);
                setPage((prev) => prev + 1);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            toast.error("Error loading users.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
        setIsLoading(true);
    };

    useEffect(() => {
        // Fetch initial users on component mount
        loadUsers();
    }, []);

    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        if (!searchTerm.length) {
            // Reset searchArr when there's no search term to show initial user list
            setSearchArr([]);
            return;
        }

        if (searchTerm) {
            debounceTimeout.current = setTimeout(() => {
                axios
                    .post(
                        `${adminBaseURL}user/search`,
                        { keyword: searchTerm },
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${userToken?.token}`,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.status) {
                            setSearchArr(res?.data?.data);
                        } else {
                            toast.error(res.data.error);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }, 500);
        }

        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, [searchTerm]);
    console.log("userList", userList)
    const handleUserSelect = (users) => {
        setSelectedChat({
            ...users,
            from: user_id,
            to: users.id,
            group_id: 0,
            user: {
                id: users.id,
                user_name: users.user_name,
                name: users.name,
                image: users.image,
                email: users.email,
                verified: users.verified
            }
        });
        toggle();
    };

    return (
        <Offcanvas isOpen={isOpen} toggle={toggle}>
            <OffcanvasHeader toggle={toggle}>
                {"Search User For Chat"}
            </OffcanvasHeader>
            <OffcanvasBody>
                <Row className="justify-content-center">
                    <Col xs="auto">
                        <div
                            className="input-group mb-3 input-group-sm overflow-hidden border"
                            style={{ width: "300px", borderRadius: "5px" }}
                        >
                            <input
                                type="text"
                                className="form-control hide-focus border-0"
                                placeholder="Search"
                                aria-label="Search"
                                style={{
                                    outline: "none", /* Removes the blue outline */
                                    boxShadow: "none" /* Removes the shadow effect */
                                }}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            <span className="input-group-text bg-white border-0 ps-1">
                                <CiSearch />
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {!searchTerm && (
                            <InfiniteScroll
                                pageStart={1}
                                loadMore={loadUsers}
                                hasMore={hasMore}
                                loader={
                                    <Row>
                                        <Col className="text-center my-3">
                                            <Spinner />
                                        </Col>
                                    </Row>
                                }
                                useWindow={false}
                            >
                                {userList.length > 0 && (
                                    userList.map((value) => (
                                        <Col xs={12} key={value.id}>
                                            <div
                                                className="my-2"
                                                onClick={() => handleUserSelect(value)}
                                            >
                                                <div className="d-flex mx-4 justify-content-between">
                                                    <div className="d-flex gap-2">
                                                        <Link
                                                            className="bg-white dropdown-avatar-shadow align-self-center"
                                                            style={{
                                                                borderRadius: "19px",
                                                                maxWidth: "4rem",
                                                                maxHeight: "4rem",
                                                                padding: "1.8px",
                                                            }}
                                                        >
                                                            <img
                                                                onClick={() => handleUserSelect(value)}
                                                                src={
                                                                    value?.image
                                                                        ? _imagesURL + value.image
                                                                        : Avatar
                                                                }
                                                                alt="picture"
                                                                className="img-fluid"
                                                                style={{ borderRadius: "20px" }}
                                                            />
                                                        </Link>
                                                        <div className="align-self-center">
                                                            <p
                                                                onClick={() => handleUserSelect(value)}
                                                                className="fs-14 roboto-bold text-dark cursor"
                                                            >
                                                                {value.name}
                                                            </p>
                                                            <p className="mb-0 fs-12 roboto-regular">
                                                                {value.user_name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mx-4" style={{ opacity: "0.1" }} />
                                            </div>
                                        </Col>
                                    ))
                                )}
                            </InfiniteScroll>
                        )}
                        {searchTerm && (
                            <Row>
                                {isLoading ? (
                                    <Col className="text-center my-3">
                                        <Spinner />
                                    </Col>
                                ) : searchArr.length > 0 ? (
                                    searchArr.map((value) => (
                                        <Col xs={12} key={value.id}>
                                            <div
                                                className="my-2"
                                                onClick={() => handleUserSelect(value)}
                                            >
                                                <div className="d-flex mx-4 justify-content-between">
                                                    <div className="d-flex gap-2">
                                                        <Link
                                                            className="bg-white dropdown-avatar-shadow align-self-center"
                                                            style={{
                                                                borderRadius: "19px",
                                                                maxWidth: "4rem",
                                                                maxHeight: "4rem",
                                                                padding: "1.8px",
                                                            }}
                                                        >
                                                            <img
                                                                onClick={() => handleUserSelect(value)}
                                                                src={
                                                                    value?.image
                                                                        ? _imagesURL + value.image
                                                                        : Avatar
                                                                }
                                                                alt="picture"
                                                                className="img-fluid"
                                                                style={{ borderRadius: "20px" }}
                                                            />
                                                        </Link>
                                                        <div className="align-self-center">
                                                            <Link
                                                               
                                                                className="fs-14 roboto-bold text-dark"
                                                            >
                                                                {value.name}
                                                            </Link>
                                                            <p className="mb-0 fs-12 roboto-regular cursor" onClick={() => handleUserSelect(value)}>
                                                                {value.user_name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mx-4" style={{ opacity: "0.1" }} />
                                            </div>
                                        </Col>
                                    ))
                                ) : (
                                    <h5 className="text-center text-gray">No User Found</h5>
                                )}
                            </Row>
                        )}
                    </Col>
                </Row>
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default SearchChatUsersModal;
