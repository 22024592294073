import { useContext, useState, useEffect } from "react";
import ImageComponent from "../common/ImageComponent";
import { Language } from "../../App";
import { _imagesURL, BaseURL } from "../../constants/API";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import { COURSE_DETAILS, COURSES, EVENT_DETAILS } from "../../constants/RoutesUrl";
import { useLocation, useNavigate } from "react-router-dom";

export default function SuggestedCoursesEvents({
  type,
  suggestedEvents,
  joinedEvents,
  popularCourses,
  purchasedCourses,
  setSelectedCourseType = null,
  viewAllPurchased,
}) {
  const { lang } = useContext(Language);
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const coursesTabs = [
    {
      id: 1,
      title: "Popular Courses",
    },
    {
      id: 2,
      title: lang.purchased_courses,
    },
  ];

  const eventTabs = [
    {
      id: 1,
      title: "Suggested Events",
    },
    {
      id: 2,
      title: lang.joined_events,
    },
  ];

  const getEventsToDisplay = () => {
    if (type === "courses") {
      return activeTab === 1 ? popularCourses : purchasedCourses;
    } else {
      return activeTab === 1 ? suggestedEvents : joinedEvents;
    }
  };

  const eventsToDisplay = getEventsToDisplay();

  return (
    <>
      <div
        className="bg-white d-xl-block d-none mb-3"
        style={{
          width: "335px",
          // height: type === "courses" ? "652px" : "695px",
          borderRadius: "8px",
          boxShadow: "0px 2px 20px 4px rgba(0, 0, 0, 0.05)",
          border: "0.5px solid rgba(0,0,0,0.25)",
        }}
      >
        <Tabs
          tabsArray={type === "courses" ? coursesTabs : eventTabs}
          setActiveTab={setActiveTab}
        />
        <div 
        // style={{ height: type === "courses" ? "565px" : "606px" }}
        >
          {loading ? (
            <div className="mx-3 pt-3">
              {[1, 2, 3, 4, 5].map((_, idx) => (
                <ShimmerCategoryItem
                  key={idx}
                  hasImage
                  imageType="thumbnail"
                  imageWidth={90}
                  imageHeight={90}
                  text
                  contentCenter
                />
              ))}
            </div>
          ) : eventsToDisplay?.length > 0 ? (
            eventsToDisplay.slice(0, 5).map((event) => (
              <div className="p-3 border-bottom" key={event.id}>
                {type === "courses" ? (
                  <CourseCard course={event} />
                ) : (
                  <EventCard event={event} />
                )}
              </div>
            ))
          ) : (
            <div className="text-center p-3 mt-3" style={{
              // margin: 'auto',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <h6 className="fs-14 roboto-medium text-muted">
                No content available yet
              </h6>
            </div>
          )}
        </div>
        {eventsToDisplay && eventsToDisplay.length > 0 && (
          <div className="text-center cursor" style={{
            margin: 'auto',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <h6
              className="fs-14 roboto-medium hover-blue view-more-btn m-0 align-self-center"
              // onClick={() => {
              //   if (type === "courses") {
              //     if (activeTab === 1) {
              //       navigate("/courses/all/popular-courses");
              //     } else {
              //       viewAllPurchased(1, "/courses/1");
              //     }
              //   } else if (type === "events") {
              //     if (activeTab === 1) {
              //       navigate("/events/all/suggested-events");
              //     } else {
              //       viewAllPurchased(1, "/events/1");
              //       // navigate("/events/all/joined-events");
              //     }
              //   }
              // }}
              onClick={() => {
                if (type === "courses") {
                  if (activeTab === 1) {
                    navigate("/courses/all/popular-courses");
                  } else {
                    viewAllPurchased(1, "/courses/1", "courses"); // Pass type as "courses"
                  }
                } else if (type === "events") {
                  if (activeTab === 1) {
                    navigate("/events/all/suggested-events");
                  } else {
                    window.scrollTo(0, 0);
                    navigate("/events/3");
                    // viewAllPurchased("joined-events", "/events/3", "events"); // Pass type as "events"
                  }
                }
              }}
            >
              {lang?.view_all_recommendations}
            </h6>
          </div>
        )}
      </div>
    </>
  );
}


const Tabs = ({ tabsArray, setActiveTab }) => {
  const [tabs, setTabs] = useState(1);
  return (
    <div className="d-flex justify-content-between text-center">
      {tabsArray.map((tab) => (
        <div
          className="w-50 py-3 cursor border-end"
          onClick={() => {
            setTabs(tab.id);
            setActiveTab(tab.id);
          }}
          style={{
            borderBottom: tabs === tab.id ? "3px solid #1DC1DD" : "none",
          }}
        >
          <h5
            className={`fs-16 mb-0  ${
              tabs === tab.id ? "roboto-bold " : "roboto-medium text-secondary"
            }`}
          >
            {tab.title}
          </h5>
        </div>
      ))}
    </div>
  );
};

export const CourseCard = ({ course }) => {
  const navigate = useNavigate();
  return (
    <div
      className="d-flex align-items-center cursor"
      onClick={() => {
        sessionStorage.setItem("CourseId", course.id);
        navigate(COURSE_DETAILS);
      }}
    >
      <div style={{ border: "1px solid #1DC1DD" }} className="rounded">
        <ImageComponent
          src={_imagesURL + course?.cover}
          compClassName="br-5 object-cover"
          img_height={72}
          img_width={72}
          loader_height={72}
          loader_width={72}
        />
      </div>
      <div className="ms-3">
        <h5 className="fs-14 roboto-bold mb-0">
          {course?.title?.length > 40
            ? course?.title?.substring(0, 40) + "..."
            : course?.title}
        </h5>
        <p className="fs-14 roboto-regular text-muted mb-0 py-1"></p>
        <p className="fs-12 roboto-bold mb-0" style={{ color: "#1DC1DD" }}>
          {course?.user?.name}
        </p>
        <p className="fs-12 roboto-medium text-primary mb-0">
          {course?.price === "0" ? "€0.0 - Free" : "€" + course?.price}
        </p>
      </div>
    </div>
  );
};

export const EventCard = ({ event }) => {
  const navigate = useNavigate();
  return (
    <div
      className="d-flex align-items-center cursor"
      onClick={() => {
        navigate(EVENT_DETAILS + event?.id);
      }}
    >
      <div style={{ border: "1px solid #1DC1DD" }} className="rounded">
        <ImageComponent
          src={_imagesURL + event?.cover}
          compClassName="br-5 object-cover"
          img_height={58}
          img_width={94}
          loader_height={58}
          loader_width={94}
        />
      </div>
      <div className="ms-3">
        <h5 className="fs-14 roboto-bold mb-0">
          {event?.title?.length > 20
            ? event?.title?.substring(0, 20) + "..."
            : event?.title}
        </h5>
        <p className="fs-14 roboto-regular text-muted mb-0 py-1">
          {event?.description?.length > 40
            ? event?.description?.substring(0, 40) + "..."
            : event?.description}
        </p>
        <p
          className="fs-12 roboto-bold mb-0"
          style={{ color: "rgba(255, 149, 0, 1)" }}
        >
          {event?.start} - {event?.end}
        </p>
      </div>
    </div>
  );
};
