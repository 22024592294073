import { RouterProvider } from "react-router-dom";
import Router from "./constants/Routing";
import { createContext, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import English from "./lang/en.json";
import Spanish from "./lang/sp.json";

import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

export const Sponsorpost = createContext();
export const Language = createContext();
export const ScrollCordinates = createContext()
export const LoginUserData = createContext();
export const FeedType = createContext();
export const FeedDataRefetch = createContext()
export const PostDetailContext = createContext()

function App() {

  
  const id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id || null;

  const [postImage, setPostImage] = useState("");
  const [postDetail, setPostDetail] = useState({});
  const [isOpen, setIsOpen] = useState(!postDetail.original_id);
  const [lang, setLang] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [splashData, setSplashData] = useState({
    user: {
      id: id
    }
  })
  const [feedType, setFeedType] = useState(() => sessionStorage.getItem("FeedType") || "all");

  const updateScrollPosition = (newValue) => setScrollPosition(newValue);
  const updateContext = (newValue) => setPostImage(newValue);
  const updateSplash = (newValue) => setSplashData(newValue);
  const updateLang = (newValue) => setLang(newValue);
  const updateFeedType = (newValue) => {
    setFeedType(newValue);
    sessionStorage.setItem("FeedType", newValue);
  };
  const updateRefetch = (newValue) => setRefetch(newValue);



  const updatePostDetail = (newValue) => {
    setPostDetail(newValue);
    setIsOpen(true);
  };

  const postModalClose = () => {
    setPostDetail({});
    setIsOpen(false);
  };

  useEffect(() => {
    document.body.classList.add('scroll-hide');
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    sessionStorage.setItem("user_timezone", userTimezone);

    if (navigator.language.startsWith("en")) {
      setLang(English);
    } else {
      setLang(Spanish);
    }
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <LoginUserData.Provider value={{ splashData, updateSplash }}>
        <Language.Provider value={{ lang, updateLang }}>
          <ScrollCordinates.Provider value={{ scrollPosition, updateScrollPosition }}>
            <Sponsorpost.Provider value={{ postImage, updateContext }}>
              <FeedType.Provider value={{ feedType, updateFeedType }}>
                <FeedDataRefetch.Provider value={{ refetch, updateRefetch }}>
                  <PostDetailContext.Provider value={{ postDetail, isOpen, postModalClose, updatePostDetail }}>
                    <ToastContainer
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="dark"
                    />
                    <RouterProvider router={Router} />

                  </PostDetailContext.Provider>
                </FeedDataRefetch.Provider>
              </FeedType.Provider>
            </Sponsorpost.Provider>
          </ScrollCordinates.Provider>
        </Language.Provider>
      </LoginUserData.Provider>
    </QueryClientProvider>
  );
}

export default App;
