import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import './transactionDetail.css';
import Avatar from "../../../assets/images/icon/placeholder_Awatar.png";
import { _imagesURL } from '../../../constants/API';

const TransactionDetails = ({ payments }) => {
  const [loading, setLoading] = useState(true);
  const message = "No transaction history is available to show at the moment.";

  useEffect(() => {
    // Simulate data loading
    setLoading(false); // Set loading to false after data is ready
  }, [payments]);

  if (loading) return <p>Loading...</p>;
  const _formatDate = (dateString) => {
    
    const date = new Date(dateString);
    // date.setDate(date.getDate() + clearanceDays); 
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  return (
    <>
     {
        payments.map((payment) => (
          <div key={payment.id} className="transaction-wrapper">
            <div className="transaction-header">
              <h6 className="credit-text">Amount {payment.type === "credit" ? "Credit" : "Debit"}</h6>
              <p className="transaction-date">
                {new Date(payment.date).toLocaleTimeString()} - {_formatDate(payment.date)}
              </p>
            </div>
            <div className="transaction-details mt-3 roboto-bold">
              <Row className="transaction-item">
                <Col>Course actual amount:</Col>
                <Col className="text-end">€{payment.amount}</Col>
              </Row>
              <Row className="transaction-item">
                <Col>Platform services fee 20%:</Col>
                <Col className="text-end">€{(payment.amount * 0.2).toFixed(2)}</Col>
              </Row>
              <Row className="transaction-item">
                <Col>Final received amount:</Col>
                <Col className="text-end ">€{(payment.amount * 0.8).toFixed(2)}</Col>
              </Row>
            </div>
            <hr />
            <div className="details-section mt-4 mb-2">
              <h6>Course Detail:</h6>
              <div className="user-info mt-2 ">
                <img src={_imagesURL+payment.course.cover || Avatar} alt="Course Cover" className="user-avatar" />
                <div className="user-text">
                  <p>{payment.course.title}</p>
                  <span>€{payment.course.price}</span>
                </div>
              </div>
              <hr></hr>
            </div>
            <div className="details-section mt-4">
              <h6>Customer Detail:</h6>
              <div className="user-info mt-2">
                <img src={_imagesURL+ payment.person.image || Avatar} alt="Customer User" className="user-avatar" />
                <div className="user-text">
                  <p>{payment.person.name||"person"}</p>
                  <span>{payment.person.user_name||"username"}</span>
                </div>
              </div>
            </div>
          </div>
        ))
}
    </>
  );
};

export default TransactionDetails;


