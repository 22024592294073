import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosSearch } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";


export function Filter({ title, options, onCountrySelect,onSelectCost, onCitySelect,onMonthSelect, onSpecializationSelect,onSelectDate}) {
  const navigate = useNavigate();
  const query = useLocation().search;
  const keyword = decodeURIComponent(query.split("=")[1]);
  const [datePickerOpen, setDatePickerOpen] = useState(false); // Control date picker visibility
  const [dateValue, setDateValue] = useState(null); // Date picker value

  const handleDateChange = (newDate) => {
    setDateValue(newDate);
    setDatePickerOpen(false); // Close date picker after selecting a date
  };
  function getFormattedMonth(dateString) {
    if (!dateString) return null; // Guard against invalid inputs
    const date = new Date(dateString);
    if (isNaN(date)) return null; // Check for invalid dates
    const month = date.getMonth() + 1;
    return month < 10 ? `0${month}` : `${month}`;
  }
  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    return `${year}-${month}-${day}`;
  }
  const handleSelection = (dropdownTitle, selectedItem) => {
    console.log("dropdownTitle",dropdownTitle)
    if (dropdownTitle === "Country") {
      // setSelectedCountry(selectedItem.id);
      onCountrySelect(selectedItem.id); // Pass selected country ID to parent
    } else if (dropdownTitle === "City") {
      // setSelectedCities(selectedItem); 
      console.log("selectedItem",selectedItem)
      onCitySelect(selectedItem.id); // Pass selected city names array to parent
    } else if (dropdownTitle === "Specialization") {
      // setSelectedSpecializations(selectedItem); 
      console.log("selectedItem",selectedItem)

      onSpecializationSelect(selectedItem); // Pass selected specialization names array to parent
    }else if (dropdownTitle === "Date" ) {
      const formattedDate = formatDate(selectedItem);
      
      setDatePickerOpen(true); // Open date picker when "Date" is selected under "Courses"
      onSelectDate(formattedDate)
    }else if(dropdownTitle ==="Month"){
    const month=  getFormattedMonth(selectedItem)
    if (month) {
      onMonthSelect(month); // Update immediately
    }
    }else if(dropdownTitle==="Cost"){
      onSelectCost(selectedItem)
    }

  };


  // const customSelectStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     width: "auto",
  //     maxWidth: "400px", // Set a maximum width to limit expansion
  //     borderRadius: "8px",
  //     borderColor: state.isFocused ? "#DEE2E6" : "#DEE2E6",
  //     boxShadow: state.isFocused ? "0 0 0 1px #1DC1DD" : "none",
  //     fontWeight: "bold",
  //     fontSize: "14px",
  //     "&:hover": {
  //       borderColor: "#DEE2E6",
  //     },
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#6c757d",
  //   }),
  //   menu: (provided) => ({
  //     ...provided,
  //     width: "366px",
  //     borderRadius: "8px",
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     color: state.isSelected ? "#1DC1DD" : "black",
  //     fontWeight: state.isSelected ? "bold" : "normal",
  //     padding: "8px",
  //     backgroundColor: state.isFocused ? "#f0f9ff" : "white",
  //     "&:hover": {
  //       backgroundColor: "#f7f7f7",
  //       border: "#DEE2E6",
  //     },
  //   }),
  //   multiValue: (provided) => ({
  //     ...provided,
  //     display: "inline-flex", // Keep items in a single horizontal line
      
  //   }),
  //   multiValueContainer: (provided) => ({
  //     ...provided,
  //     overflow: "hidden",
  //     textOverflow: "ellipsis",
  //   }),
  //   valueContainer: (provided) => ({
  //     ...provided,
  //     display: "flex",
  //     flexWrap: "nowrap", // Prevent wrapping to new lines
  //     // overflowX: "auto", // Enable horizontal scrolling
  //     maxHeight: "40px", // Set a fixed height for the container
  //     maxWidth: "400px", // Set a maximum width
  //   }),
  // };
  
  // const customSelectStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     width: "auto",
  //     maxWidth: "420px", // Set a maximum width to limit expansion
  //     borderRadius: "8px",
  //     borderColor: state.isFocused ? "#DEE2E6" : "#DEE2E6",
  //     boxShadow: state.isFocused ? "0 0 0 1px #1DC1DD" : "none",
  //     fontWeight: "bold",
  //     fontSize: "14px",
  //     "&:hover": {
  //       borderColor: "#DEE2E6",
  //     },
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     color: "#6c757d",
  //   }),
  //   menu: (provided) => ({
  //     ...provided,
  //     width: "366px",
  //     borderRadius: "8px",
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     color: state.isSelected ? "#1DC1DD" : "black",
  //     fontWeight: state.isSelected ? "bold" : "normal",
  //     padding: "8px",
  //     backgroundColor: state.isFocused ? "#f0f9ff" : "white",
  //     "&:hover": {
  //       backgroundColor: "#f7f7f7",
  //       border: "#DEE2E6",
  //     },
  //   }),
  //   multiValue: (provided) => ({
  //     ...provided,
  //     display: "inline-flex", // Keep items in a single horizontal line
  //     whiteSpace: "nowrap", // Prevent text from wrapping within each selected item
  //     overflow: "hidden", // Hide any overflow
  //     textOverflow: "ellipsis", // Show ellipsis (...) for overflowed text
  //   }),
  //   multiValueLabel: (provided) => ({
  //     ...provided,
  //     whiteSpace: "nowrap", // Prevent wrapping
  //     overflow: "hidden",
  //     textOverflow: "ellipsis", // Show ellipsis for overflowed text
  //   }),
  //   valueContainer: (provided) => ({
  //     ...provided,
  //     display: "flex",
  //     flexWrap: "nowrap", // Prevent wrapping to new lines
  //     overflowX: "auto", // Enable horizontal scrolling if items overflow
  //     maxHeight: "40px", // Set a fixed height for the container
  //     maxWidth: "420px", // Set a maximum width
  //   }),
  // };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "auto",
      maxWidth: "570px", // Set a maximum width to limit expansion
      borderRadius: "8px",
      borderColor: state.isFocused ? "#DEE2E6" : "#DEE2E6",
      boxShadow: state.isFocused ? "0 0 0 1px #1DC1DD" : "none",
      fontWeight: "bold",
      fontSize: "14px",
      "&:hover": {
        borderColor: "#DEE2E6",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
    }),
    menu: (provided) => ({
      ...provided,
      width: "366px",
      borderRadius: "8px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#1DC1DD" : "black",
      fontWeight: state.isSelected ? "bold" : "normal",
      padding: "8px",
      backgroundColor: state.isFocused ? "#f0f9ff" : "white",
      "&:hover": {
        backgroundColor: "#f7f7f7",
        border: "#DEE2E6",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      display: "inline-flex", // Keep items in a single horizontal line
      whiteSpace: "nowrap", // Prevent text from wrapping within each selected item
      overflow: "hidden", // Hide any overflow
      textOverflow: "ellipsis", // Show ellipsis (...) for overflowed text
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      whiteSpace: "nowrap", // Prevent wrapping
      overflow: "hidden",
      textOverflow: "ellipsis", // Show ellipsis for overflowed text
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: "flex",
      flexWrap: "nowrap", // Prevent wrapping to new lines
      overflowX: "auto", // Enable horizontal scrolling if items overflow
      maxHeight: "40px", // Set a fixed height for the container
      maxWidth: "570px", // Set a maximum width
      "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar on Webkit browsers
      msOverflowStyle: "none", // Hide scrollbar on IE and Edge
      scrollbarWidth: "none", // Hide scrollbar on Firefox
    }),
  };
  
  


  return (
    <div className="position-relative" style={{ zIndex: 1 }}>
      <div className="position-sticky top-0">
        <div
          className="d-flex gap-3 justify-content-center bg-white pb-2"
          style={{
            paddingTop: "4rem",
            boxShadow: "0px 10px 10px 2px rgba(0, 0, 0, 0.05)",
            width: "100%",
          }}
        >
          <div className="border-end pe-3">
            <button
              type="button"
              className="rounded px-4 py-2 roboto-bold border border-1 border-inherit text-white"
              style={{ backgroundColor: "#1DC1DD" }}
              onClick={() => {
                navigate(`/search?keyword=${keyword}`);
              }}
            >
              {title}
            </button>
          </div>
          <div className="d-flex gap-3">
            {options &&
              options.map((option, i) => {
                if (option.title === "Specialization") {
                  return (
                    <div key={i} className="w-100 scroll-hide">
                      <Select
                        options={option?.options?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        onChange={(selectedOption) =>
                          handleSelection(option.title, selectedOption)
                        }
                        placeholder={option.title}
                        isMulti={true}
                        styles={customSelectStyles}
                      />
                    </div>
                  );
                }
              
                return (
                  <DropDownFilter
                    key={i}
                    title={option.title}
                    options={option.options}
                    onSelect={(selectedItem) =>
                      handleSelection(option.title, selectedItem)
                    }
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
export function DropDownFilter({ title, options, onSelect }) {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [pickerOpen, setPickerOpen] = useState(false); // Controls picker visibility
  const [dateValue, setDateValue] = useState(null); // Stores selected date or month

  const handleDateChange = (newDate) => {
    console.log("newDate",newDate)
    setDateValue(newDate);
    setPickerOpen(false); // Close picker after selecting a date/month
    onSelect(newDate); // Notify parent with selected date or month
  };

  const handleDropdownClick = () => {
    if (title === "Date" || title === "Month") {
      setPickerOpen(true); // Open date/month picker directly if title is "Date" or "Month"
    } else {
      setShow(!show); // Toggle dropdown for other titles
    }
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    onSelect(item);
    setShow(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".position-relative")) {
        setShow(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="position-relative">
        {/* Dropdown button, styled like other dropdowns, even for Date or Month */}
        <button
          type="button"
          onClick={handleDropdownClick}
          style={{ 
            width: title === "Date" || title === "Month" ? "167px" : "fit-content",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          className="d-flex scroll-hide align-items-center gap-2 rounded px-4 py-2 roboto-bold manue-hover hover-blue border border-1 border-inherit"
        >
          {title === "Date" || title === "Month"
            ? dateValue
              ? dateValue.format(title === "Date" ? "MM/DD/YYYY" : "MMMM YYYY")
              : `Select ${title}`
            : selectedItem
            ? typeof selectedItem === "string"
              ? selectedItem
              : selectedItem.name
            : title}
          <IoIosArrowDown size={18} />
        </button>

        {/* Render dropdown options if title is not "Date" or "Month" */}
        {show && title !== "Date" && title !== "Month" && (
          <div className="position-absolute" style={{ zIndex: "2" }}>
            <div
              className="bg-white mt-2 p-3 rounded border border-1 border-inherit"
              style={{
                width: "366px",
                ...(title === "Country" && {
                  height: "500px",
                  overflow: "scroll",
                }),
              }}
            >
              <div className="position-relative">
                <IoIosSearch
                  size={20}
                  className="position-absolute my-auto top-0 bottom-0 m-2 left-0 text-secondary outline-none"
                />
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                  className="form-control text-secondary"
                  style={{
                    backgroundColor: "rgba(247, 247, 247, 1)",
                    paddingLeft: "2rem",
                  }}
                />
              </div>
              <div className="py-3">
                <h6 className="roboto-medium fs-14 text-secondary mb-0">
                  {title}
                </h6>
              </div>
              <div>
                {options && options.length > 0 ? (
                  options
                    .filter((val) =>
                      (typeof val === "string" ? val : val.name)
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    .map((val, index) => (
                      <p
                        key={index}
                        onClick={() => handleItemClick(val)}
                        className="fs-14 cursor roboto-regular mb-0 py-2"
                        style={{
                          color:
                            selectedItem === val
                              ? "#1DC1DD" // Highlight if selected
                              : "black",
                          fontWeight:
                            selectedItem === val ? "bold" : "normal",
                        }}
                      >
                        {typeof val === "string" ? val : val.name}
                      </p>
                    ))
                ) : title === "City" ? (
                  <p className="fs-14 text-secondary">No cities found</p>
                ) : null}
              </div>
            </div>
          </div>
        )}

        {/* MobileDatePicker or StaticDatePicker based on title */}
        {pickerOpen && title === "Date" && (
          <MobileDatePicker
            open={pickerOpen}
            value={dateValue}
            onChange={handleDateChange}
            onClose={() => setPickerOpen(false)}
            slotProps={{
              textField: { style: { display: "none" } },
            }}
          />
        )}
        
        {pickerOpen && title === "Month" && (
            <MobileDatePicker
            open={pickerOpen}
            value={dateValue}
            views={["month", "year"]}
            onChange={handleDateChange}
            onClose={() => setPickerOpen(false)}
            slotProps={{
              textField: { style: { display: "none" } },
            }}
          />
        )}
      </div>
    </LocalizationProvider>
  );
}

// export function DropDownFilter({ title, options, onSelect }) {
//   const [show, setShow] = useState(false);
//   const [search, setSearch] = useState("");
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [datePickerOpen, setDatePickerOpen] = useState(false); // Controls date picker visibility
//   const [dateValue, setDateValue] = useState(null); // Stores selected date

//   const handleDateChange = (newDate) => {
//     setDateValue(newDate);
//     setDatePickerOpen(false); // Close date picker after selecting a date
//     onSelect(newDate); // Notify parent with selected date
//   };

//   const handleDropdownClick = () => {
//     if (title === "Date") {
//       setDatePickerOpen(true); // Open date picker directly if title is "Date"
//     } else {
//       setShow(!show); // Toggle dropdown for other titles
//     }
//   };

//   const handleItemClick = (item) => {
//     setSelectedItem(item);
//     onSelect(item);
//     setShow(false);
//   };
//   useEffect(() => {
//     const handleOutsideClick = (event) => {
//       if (!event.target.closest(".position-relative")) {
//         setShow(false);
//       }
//     };
//     document.addEventListener("click", handleOutsideClick);
//     return () => {
//       document.removeEventListener("click", handleOutsideClick);
//     };
//   }, []);

//   // const handleItemClick = (item) => {
//   //   setSelectedItem(item); // Set the selected item
//   //   onSelect(item); // Pass the selected item to parent
//   //   setShow(false); // Close the dropdown
//   // };

//   return (
//     <LocalizationProvider dateAdapter={AdapterMoment}>
//     <div className="position-relative">
//       {/* Dropdown button, styled like other dropdowns, even for Date */}
//       <button
//         type="button"
//         onClick={handleDropdownClick}
//         style={{ 
//           // width: title === "Date"||"Cities" ? "167px" : "fit-content" ,

//           whiteSpace: "nowrap",
//           overflow: "scroll",
//           textOverflow: "ellipsis",
//         }}
//         className="d-flex scroll-hide align-items-center gap-2 rounded px-4 py-2 roboto-bold manue-hover hover-blue border border-1 border-inherit"
//       >
//         {title === "Date"
//           ? dateValue
//             ? dateValue.format("MM/DD/YYYY")
//             : "Select Date"
//           : selectedItem
//           ? typeof selectedItem === "string"
//             ? selectedItem
//             : selectedItem.name
//           : title}
//         <IoIosArrowDown size={18} />
//       </button>

//       {/* Render dropdown options if title is not "Date" */}
//       {show && title !== "Date" && (
//         <div className="position-absolute" style={{ zIndex: "2" }}>
//           <div
//             className="bg-white mt-2 p-3 rounded border border-1 border-inherit"
//             style={{
//               width: "366px",
//               ...(title === "Country" && {
//                 height: "500px",
//                 overflow: "scroll",
//               }),
//             }}
//           >
//             <div className="position-relative">
//               <IoIosSearch
//                 size={20}
//                 className="position-absolute my-auto top-0 bottom-0 m-2 left-0 text-secondary outline-none"
//               />
//               <input
//                 type="text"
//                 value={search}
//                 onChange={(e) => setSearch(e.target.value)}
//                 placeholder="Search"
//                 className="form-control text-secondary"
//                 style={{
//                   backgroundColor: "rgba(247, 247, 247, 1)",
//                   paddingLeft: "2rem",
//                 }}
//               />
//             </div>
//             <div className="py-3">
//               <h6 className="roboto-medium fs-14 text-secondary mb-0">
//                 {title}
//               </h6>
//             </div>
//             <div>
//               {options && options.length > 0 ? (
//                 options
//                   .filter((val) =>
//                     (typeof val === "string" ? val : val.name)
//                       .toLowerCase()
//                       .includes(search.toLowerCase())
//                   )
//                   .map((val, index) => (
//                     <p
//                       key={index}
//                       onClick={() => handleItemClick(val)}
//                       className="fs-14 cursor roboto-regular mb-0 py-2"
//                       style={{
//                         color:
//                           selectedItem === val
//                             ? "#1DC1DD" // Highlight if selected
//                             : "black",
//                         fontWeight:
//                           selectedItem === val ? "bold" : "normal",
//                       }}
//                     >
//                       {typeof val === "string" ? val : val.name}
//                     </p>
//                   ))
//               ) : title === "City" ? (
//                 <p className="fs-14 text-secondary">No cities found</p>
//               ) : null}
//             </div>
//           </div>
//         </div>
//       )}

//       {/* MobileDatePicker for "Date" title, opens directly without an input */}
//       <MobileDatePicker
//         open={datePickerOpen}
//         value={dateValue}
//         onChange={handleDateChange}
//         onClose={() => setDatePickerOpen(false)} // Close when selection is made
//         slotProps={{
//           textField: { style: { display: "none" } }, // Hide the input field
//         }}
//       />
//     </div>
//   </LocalizationProvider>
//     // <div className="position-relative">
//     //   <button
//     //     type="button"
//     //     onClick={() => setShow(!show)}
//     //     className="d-flex align-items-center gap-2 rounded px-4 py-2 roboto-bold manue-hover hover-blue border border-1 border-inherit"
//     //   >
//     //     {selectedItem ? (typeof selectedItem === "string" ? selectedItem : selectedItem.name) : title}
//     //     <IoIosArrowDown size={18} />
//     //   </button>
//     //   {show && (
//     //     <div className="position-absolute" style={{ zIndex: "2" }}>
//     //       <div
//     //         className="bg-white mt-2 p-3 rounded border border-1 border-inherit"
//     //         style={{ width: "366px", ...(title === "Country" && {
//     //           height: "500px",
//     //           overflow: "scroll",
//     //         }),}}
//     //       >
//     //         <div className="position-relative">
//     //           <IoIosSearch
//     //             size={20}
//     //             className="position-absolute my-auto top-0 bottom-0 m-2 left-0 text-secondary outline-none"
//     //           />
//     //           <input
//     //             type="text"
//     //             value={search}
//     //             onChange={(e) => setSearch(e.target.value)}
//     //             placeholder="Search"
//     //             className="form-control text-secondary"
//     //             style={{
//     //               backgroundColor: "rgba(247, 247, 247, 1)",
//     //               paddingLeft: "2rem",
//     //             }}
//     //           />
//     //         </div>
//     //         <div className="py-3">
//     //           <h6 className="roboto-medium fs-14 text-secondary mb-0">
//     //             {title}
//     //           </h6>
//     //         </div>
//     //         <div>
//     //           {options && options.length > 0 ? (
//     //             options
//     //               .filter((val) =>
//     //                 (typeof val === "string" ? val : val.name)
//     //                   .toLowerCase()
//     //                   .includes(search.toLowerCase())
//     //               )
//     //               .map((val, index) => (
//     //                 <p
//     //                   key={index}
//     //                   onClick={() => handleItemClick(val)}
//     //                   className="fs-14 cursor roboto-regular mb-0 py-2"
//     //                   style={{
//     //                     color:
//     //                       selectedItem === val
//     //                         ? "#1DC1DD" // Highlight only if selected
//     //                         : "black",  // Default color is black
//     //                     fontWeight:
//     //                       selectedItem === val
//     //                         ? "bold" // Bold if selected
//     //                         : "normal",
//     //                   }}
//     //                 >
//     //                   {typeof val === "string" ? val : val.name}
//     //                 </p>
//     //               ))
//     //           ) : title === "City" ? ( // Only show "No cities found" for City dropdown
//     //             <p className="fs-14 text-secondary">No cities found</p>
//     //           ) : null}
//     //         </div>
//     //       </div>
//     //     </div>
//     //   )}
//     // </div>
//   );
// }


// export function DropDownFilter({ title, options, onSelect }) {
//   const [show, setShow] = useState(false);
//   const [search, setSearch] = useState("");
//   const [selectedItem, setSelectedItem] = useState(null);

//   useEffect(() => {
//     const handleOutsideClick = (event) => {
//       if (!event.target.closest(".position-relative")) {
//         setShow(false);
//       }
//     };
//     document.addEventListener("click", handleOutsideClick);
//     return () => {
//       document.removeEventListener("click", handleOutsideClick);
//     };
//   }, []);

//   const handleItemClick = (item) => {
//     setSelectedItem(item); // Set the selected item
//     onSelect(item); // Pass the selected item to parent
//     setShow(false); // Close the dropdown
//   };

//   return (
//     <div className="position-relative">
//       <button
//         type="button"
//         onClick={() => setShow(!show)}
//         className="d-flex align-items-center gap-2 rounded px-4 py-2 roboto-bold manue-hover hover-blue border border-1 border-inherit"
//       >
//         {selectedItem ? (typeof selectedItem === "string" ? selectedItem : selectedItem.name) : title}
//         <IoIosArrowDown size={18} />
//       </button>
//       {show && (
//         <div className="position-absolute" style={{ zIndex: "2" }}>
//           <div
//             className="bg-white mt-2 p-3 rounded border border-1 border-inherit"
//             style={{ width: "366px" }}
//           >
//             <div className="position-relative">
//               <IoIosSearch
//                 size={20}
//                 className="position-absolute my-auto top-0 bottom-0 m-2 left-0 text-secondary outline-none"
//               />
//               <input
//                 type="text"
//                 value={search}
//                 onChange={(e) => setSearch(e.target.value)}
//                 placeholder="Search"
//                 className="form-control text-secondary"
//                 style={{
//                   backgroundColor: "rgba(247, 247, 247, 1)",
//                   paddingLeft: "2rem",
//                 }}
//               />
//             </div>
//             <div className="py-3">
//               <h6 className="roboto-medium fs-14 text-secondary mb-0">
//                 {title}
//               </h6>
//             </div>
//             <div>
//               {options &&
//                 options
//                   .filter((val) =>
//                     (typeof val === "string" ? val : val.name)
//                       .toLowerCase()
//                       .includes(search.toLowerCase())
//                   )
//                   .map((val, index) => (
//                     <p
//                       key={index}
//                       onClick={() => handleItemClick(val)}
//                       className="fs-14 cursor roboto-regular mb-0 py-2"
//                       style={{
//                         color:
//                           selectedItem === val
//                             ? "#1DC1DD" // Highlight only if selected
//                             : "black",  // Default color is black
//                         fontWeight:
//                           selectedItem === val
//                             ? "bold" // Bold if selected
//                             : "normal",
//                       }}
//                     >
//                       {typeof val === "string" ? val : val.name}
//                     </p>
//                   ))}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// import { useEffect, useState } from "react";
// import { IoIosArrowDown, IoIosSearch } from "react-icons/io";
// import { useLocation, useNavigate } from "react-router-dom";

// export function Filter({ title, options }) {
//   const navigate = useNavigate();
//   const query = useLocation().search;
//   const keyword = decodeURIComponent(query.split("=")[1]);
//   return (
//     <div className="position-relative" style={{ zIndex: 1 }}>
//       <div className="position-sticky top-0">
//         <div
//           className="d-flex gap-3 justify-content-center bg-white pb-2"
//           style={{
//             paddingTop: "4rem",
//             boxShadow: "0px 10px 10px 2px rgba(0, 0, 0, 0.05)",
//             width: "100%",
//           }}
//         >
//           <div className="border-end pe-3">
//             <button
//               type="button"
//               className="rounded px-4 py-2 roboto-bold border border-1 border-inherit text-white"
//               style={{ backgroundColor: "#1DC1DD" }}
//               onClick={() => {
//                 navigate(`/search?keyword=${keyword}`);
//               }}
//             >
//               {title}
//             </button>
//           </div>
//           <div className="d-flex gap-3">
//             {options &&
//               options?.map((option, i) => (
//                 <DropDownFilter
//                   key={i}
//                   title={option.title}
//                   options={option.options}
//                 />
//               ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export function DropDownFilter({ title, options }) {
//   const [show, setShow] = useState(false);
//   const [search, setSearch] = useState("");
//   const [isActive, setIsActive] = useState(false);
//   useEffect(() => {
//     const handleOutsideClick = (event) => {
//       if (!event.target.closest(".position-relative")) {
//         setShow(false);
//         setIsActive(false);
//       }
//     };

//     document.addEventListener("click", handleOutsideClick);

//     return () => {
//       document.removeEventListener("click", handleOutsideClick);
//     };
//   }, [show, isActive]);
//   return (
//     <>
//       <div className="position-relative">
//         <button
//           type="button"
//           onClick={() => {
//             setIsActive(!isActive);
//             setShow(!show);
//           }}
//           className="d-flex align-items-center gap-2 rounded px-4 py-2 roboto-bold manue-hover hover-blue border border-1 border-inherit"
//         >
//           {title} <IoIosArrowDown size={18} />
//         </button>
//         {show && (
//           <div className="position-absolute" style={{ zIndex: "2" }}>
//             <div
//               className="bg-white mt-2 p-3 rounded border border-1 border-inherit"
//               style={{ width: "366px" }}
//             >
//               <div className="position-relative">
//                 <IoIosSearch
//                   size={20}
//                   className="position-absolute my-auto top-0 bottom-0 m-2 left-0 text-secondary outline-none"
//                 />
//                 <input
//                   type="text"
//                   value={search}
//                   onChange={(e) => setSearch(e.target.value)}
//                   placeholder="Search"
//                   className="form-control text-secondary"
//                   style={{
//                     backgroundColor: "rgba(247, 247, 247, 1)",
//                     paddingLeft: "2rem",
//                   }}
//                 />
//               </div>
//               <div className="py-3">
//                 <h6 className="roboto-medium fs-14 text-secondary mb-0">
//                   {title}
//                 </h6>
//               </div>
//               <div>
//                 {options &&
//                   options?.map((val, index) => (
//                     <p key={index} className="fs-14 cursor roboto-regular mb-0 py-2">
//                       {val?.name}
//                     </p>
//                   ))}
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//       {isActive && (
//         <div className="overlay" style={{ zIndex: "1", top: "7rem" }}></div>
//       )}
//     </>
//   );
// }