import { authorize } from "../shared/authorize";
import { api, apiAdmin } from "./api";
import { toast } from 'react-toastify';

export const getRecentSearchApi = async () => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await apiAdmin.get(`user/web/recent-search`,authorize());
        return response.data;
    } catch (error) {
        toast.error('Error fetching recent search:', error);
        throw error;
    }
}

export const getSearchAgainstKeywordApi = async (keyword) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.token;
    try {
        const response = await apiAdmin.post(`user/web/recent-search/keyword`,{keyword },authorize());
        return response.data;
    } catch (error) {
        toast.error('Error fetching search against keyword:', error);
        throw error;
    }
}

export const getAllSearchAgainstKeywordApi = async ({keyword, type, pageParam, country, 
    city, 
    specialization,month,date,cost}) => {
        console.log("cost",cost)
    const payload = { keyword, type };
    // Include country in the payload if type is 'doctors'
    if (type === "doctors") {
        if (country) payload.country = country;
        if (city) payload.city = city;
        if (specialization && specialization.length > 0) payload.specialization = specialization;
    }
    if(type==="posts"||type==="courses"||type==="webinars"||type==="studies"
    ){
        if (specialization && specialization.length > 0) payload.specialization = specialization;
        if(date) payload.date=date
        
    }
    if(type==="events"){
      if(month)  payload.month=month
      if (specialization && specialization.length > 0) payload.specialization = specialization;
    }
    if(type==="courses"){
        if(cost) payload.cost=cost
    }
    try {
        const response = await apiAdmin.post(`user/web/recent-search?page=${pageParam || 1}`, payload,authorize());
        return response.data;
    } catch (error) {
        toast.error('Error fetching search against keyword:', error);
        throw error;
    }
}