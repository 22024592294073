import { _imagesURL, BaseURL } from "../../constants/API";
import ImageComponent from "../common/ImageComponent";

export default function EventsCard({ data }) {
  return (
    <div className="rounded bg-white p-3 my-2">
      <div className="d-flex align-items-center">
        <div style={{ border: "1px solid #1DC1DD" }} className="rounded">
          <ImageComponent
            src={_imagesURL + data?.cover}
            compClassName="rounded object-cover"
            img_height={115}
            img_width={188}
            loader_height={115}
            loader_width={188}
          />
        </div>
        <div className="ms-3">
          <h5 className="fs-16 roboto-bold">
            {data?.title?.length > 30
              ? data?.title.substring(0, 30) + "..."
              : data?.title}
          </h5>
          <p className="fs-14 roboto-medium text-muted mb-0">
            {new Date(data?.start_timestamp * 1000).toLocaleDateString()} -{" "}
            {new Date(data?.end_timestamp * 1000).toLocaleDateString()}
          </p>
          <p className="fs-14 roboto-regular text-muted mb-0 py-1">
            {data?.description?.length > 80
              ? data?.description.substring(0, 80) + "..."
              : data?.description}
          </p>
        </div>
      </div>
    </div>
  );
}
