import stretho from "../../assets/images/icons/stretho.svg";
import calender_line from "../../assets/images/icons/calendar-line.svg";

import { FaStethoscope } from "react-icons/fa";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import AnimatedImage from "../../../src/assets/images/live_lotte.json";
import time_icon from "../../assets/images/icons/time.svg";
import { CLINICAL_SESSIONS_DETAILS, PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../../components/common/ImageComponent";
import { _imagesURL, BaseURL } from "../../constants/API";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
const ClinicalSessionCard = ({session}) => {

    const {
        caption = "No caption provided",
        category = "No category",
        duration = "N/A",
        id,
        meeting_host_url,
        meeting_url,
        schedule_date,
        schedule_time,
        scheduled,
        start_timestamp = 0,
        status,
        subcategory = "No subcategory",
        time = 0,
        title = "Untitled Session",
        visibility = "Public",
        user = {}
    } = session || {};
    const [formattedTime, setFormattedTime] = useState("");
    const [relativeTime, setRelativeTime] = useState("");
    const navigate = useNavigate()
    useEffect(() => {
        if (start_timestamp) {
            const formatted = formatTimestamp(Number(start_timestamp));
            setFormattedTime(formatted);
        }
        if (time) {
            const relative = calculateRelativeTime(Number(time));
            setRelativeTime(relative);
        }
    }, [start_timestamp,time]);

    function formatTimestamp(timestamp) {
        // Convert the timestamp from seconds to milliseconds
        const date = new Date(timestamp * 1000);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return "Invalid Date";
        }

        // Format the date using options for day, month, year, time, etc.
        return new Intl.DateTimeFormat('en-US', {
            weekday: 'short',
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        }).format(date);
    }

    function calculateRelativeTime(timestamp) {
        const now = Date.now();
        const timeDifference = now - timestamp * 1000; // Convert timestamp to milliseconds
    
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30); // Approximate month duration as 30 days
        const years = Math.floor(days / 365); // Approximate year duration as 365 days
    
        if (years > 0) {
            return `Posted ${years}y ago`;
        } else if (months > 0) {
            return `Posted ${months}mo ago`;
        } else if (days > 0) {
            return `Posted ${days}d ago`;
        } else if (hours > 0) {
            return `Posted ${hours}h ago`;
        } else if (minutes > 0) {
            return `Posted ${minutes}m ago`;
        } else {
            return `Posted just now`;
        }
    }
    
    const handleViewSessionClick = () => {
        navigate(`${CLINICAL_SESSIONS_DETAILS}${id}`);
    };
    return (
        
            <div className="card  p-3" style={{ maxWidth: '400px' }}>
                <h5 className="card-title caption-text fw-bold">{title}</h5>
                <p className="card-text caption-text">{caption}</p>
                <div className="mt-3">
                    <div className="d-flex align-items-center mb-2 ">
                        <p >
                        <img className="me-2 py-1 rounded " src={stretho} alt="stethoscope" style={{backgroundColor:"#EBEDF0",paddingRight: "10px",paddingLeft:"10px"
    }}/>
                        <span>{category}</span>
                        </p>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <p>
                    <img className="me-2 px-2 py-1 rounded " src={calender_line} alt="calender" style={{backgroundColor:"#EBEDF0"}}/>
                        <span>{formattedTime}</span>
                        </p>
                    </div>
                    {/* <div className="d-flex align-items-center">
                        <p>
                    <img className="me-2 px-2 py-1 rounded " src={time_icon} alt="time" style={{backgroundColor:"#EBEDF0"}}/>
                        <span>{visibility} - {duration}Mins</span>
                        </p>
                        <div
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <Lottie
                        animationData={AnimatedImage}
                        loop
                        autoplay
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    </div> */}
                    <div className="d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
    <p className="mb-0 d-flex align-items-center">
        <img
            className="me-2 px-2 py-1 rounded"
            src={time_icon}
            alt="time"
            style={{ backgroundColor: "#EBEDF0" }}
        />
        <span>{visibility} - {duration} Mins</span>
    </p>
    {status===1&&
    <div style={{ width: "40px",  }}>
        <Lottie
            animationData={AnimatedImage}
            loop
            autoplay
            style={{ width: "100%", height: "100%" }}
        />
    </div>}
</div>

                </div>
                <hr />
                <h5>Created By:</h5>
                <div className="d-flex align-items-center gap-2 mt-2 mb-3">
                   
                    <div
            className="bg-white rounded-circle align-self-center"
            style={{
              padding: "2px",
              width: "50px",
              height: "50px",
              border: "1px solid rgba(29, 193, 221, 1)",
            }}
          >
            <Link to={PROFILE + "/"
                //  + postDetails?.user?.id
                 }>
              <ImageComponent
                src={
                user?.image?
                     _imagesURL 
                    + user?.image
                    : 
                    Avatar
                }
                img_height="100%"
                img_width="100%"
                loader_height="100%"
                loader_width="100%"
                compClassName="rounded-circle"
                roundShimmerSize={45}
              />
            </Link>
          </div>
                    <div>
                        <p className="mb-0 fw-bold">{user?.name}</p>
                        <small className="text-muted">{relativeTime}</small>
                    </div>
                </div>
                <hr />
                <button className="btn  w-100"style={{background: "rgb(29, 193, 221)",
    color: "white"}} onClick={handleViewSessionClick} >View Session</button>
            </div>
        // </div>
    );
};

export default ClinicalSessionCard;
