import { useContext, useState, useCallback } from "react";
import { Modal, ModalBody } from "reactstrap";
import { selectedChatContext } from "../../pages/chat/ChatModule";
import { Language } from "../../App";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { _imagesURL, BaseURL } from "../../constants/API";
import ImageComponent from "../common/ImageComponent";
import { FaPlus, FaRegImage } from "react-icons/fa6";
import { RxExit } from "react-icons/rx";
import { MdBlock } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosArrowForward, IoMdPersonAdd } from "react-icons/io";
import EditGroupInfo from "./EditGroupInfo";
import ChatMediaModal from "./ChatMediaModal";
import GroupParticipantsModal from "./GroupParticipantsModal";
import EditGroupParticipantsModal from "./EditGroupParticipantsModal";
import { toast } from "react-toastify";

const MenuItem = ({ icon: Icon, text, textColor = "black", onClick }) => (
  <div
    className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 cursor manue-hover hover-blue my-2"
    onClick={onClick}
  >
    <div className="d-flex gap-2 align-items-center">
      <Icon size={24} />
      <h3
        className="fs-18 fs-md-16 m-0 text-ellips-149px"
        style={{ color: textColor }}
      >
        {text}
      </h3>
    </div>
    <IoIosArrowForward color={textColor} size={20} />
  </div>
);

export default function GroupInfoModal({ isOpen, setIsOpen,refetchGroupMessages,createdAt,participentsCount }) {
  const { selectedChat,setSelectedChat } = useContext(selectedChatContext);
  const { lang } = useContext(Language);

  const [modals, setModals] = useState({
    editGroupInfo: false,
    groupMedia: false,
    groupParticipants: false,
    editGroupParticipants: false,
  });
  const formatDate = (dateString) => {
    const date = new Date(dateString||"");
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };
  const formattedDate = formatDate(createdAt||"");
  const toggleModal = useCallback(
    (modal) => {
      setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));
    },
    [setModals]
  );

  const menuItems = [
    {
      icon: IoSettingsOutline,
      text: lang?.edit_group_info,
      onClick: () => toggleModal("editGroupInfo"),
    },
    {
      icon: FaRegImage,
      text: lang?.media_photo_videos,
      onClick: () => toggleModal("groupMedia"),
    },
    {
      icon: FaPlus,
      text: lang?.add_participants,
      onClick: () => toggleModal("editGroupParticipants"),
    },
    {
      icon: IoMdPersonAdd,
      text: lang?.participants,
      onClick: () => toggleModal("groupParticipants"),
    },
    {
      icon: RxExit,
      text: lang?.leave_group,
      textColor: "red",
      onClick: () =>
        toast.info("This feature is not available yet, working on it"),
    },
    {
      icon: MdBlock,
      text: lang?.report_group,
      textColor: "red",
      onClick: () =>
        toast.info("This feature is not available yet, working on it"),
    },
  ];

  return (
    <>
      <Modal isOpen={isOpen} centered toggle={() => setIsOpen(!isOpen)} size="lg">
        <ModalBody>
          <div className="edit-profile">
            {selectedChat?.group_id !== 0 ? (
              <>
                <div className="d-flex justify-content-center mt-4">
                  <div
                    className="profile_img position-relative cursor rounded-circle"
                    style={{ border: "1px solid #1DC1DD" }}
                  >
                    <ImageComponent
                      src={
                        selectedChat.image
                          ? _imagesURL + selectedChat.image
                          : Avatar
                      }
                      compClassName="rounded-circle"
                      loader_height="100%"
                      loader_width="100%"
                      roundShimmerSize={100}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <h4 className="mt-2">{selectedChat.group_name}</h4>
                  <h6>
                    {selectedChat.name || selectedChat.user.name}{" "}
                    {lang?.created_this_group}
                  </h6>
                  <h6>
                    {lang?.created} {formattedDate || ""}
                  </h6>
                  <h6>{participentsCount} {lang?.participants}</h6>
                </div>
              </>
            ) : (
              <div className="Chatinfo px-3 p-2">
                &nbsp;&nbsp;
                <span className="chatheadertext"></span>
              </div>
            )}
            <hr />
            {menuItems.map((item, index) => (
              <MenuItem key={index} {...item} />
            ))}
          </div>
        </ModalBody>
      </Modal>
     {modals.editGroupInfo&& <EditGroupInfo
        isOpen={modals.editGroupInfo}
        toggle={() => toggleModal("editGroupInfo")}
        currentName ={selectedChat?.group_name}
        currentImage={ selectedChat.image
          ? _imagesURL + selectedChat.image
          : Avatar}
          currentId={selectedChat?.group_id}
          
      />}
      <ChatMediaModal
        isOpen={modals.groupMedia}
        toggle={() => toggleModal("groupMedia")}
      />
     {modals.groupParticipants&& <GroupParticipantsModal
        isOpen={modals.groupParticipants}
        toggle={() => toggleModal("groupParticipants")}
        currentId={selectedChat?.group_id}
        
      />}
      <EditGroupParticipantsModal
        isOpen={modals.editGroupParticipants}
        toggle={() => toggleModal("editGroupParticipants")}
        currentId={selectedChat?.group_id}
      />
    </>
  );
}
