import { Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import SearchHashtags from "../../components/search/SearchHashtags";
import { Filter } from "./Filter";
import FeedAdvertisement from "../../components/feeds/FeedAdvertisement";
import { useInfiniteQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { getAllSearchAgainstKeywordApi } from "../../services/searchApis";
import { EventCard } from "../../components/feeds/SuggestedCoursesEvents";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { LoginUserData } from "../../App";

export default function EventsSearch() {
  const query = useLocation().search;
  const keyword = decodeURIComponent(query.split("=")[1]);
  const { splashData } = useContext(LoginUserData);
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  const [month, setMonth] = useState(null);
  let specialization = selectedSpecializations
  let _month=month
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage,refetch } =
    useInfiniteQuery({
      queryKey: ["search-events", keyword,selectedSpecializations, month],
      queryFn: ({ pageParam = 1 }) =>
        getAllSearchAgainstKeywordApi({keyword, type:"events", pageParam,specialization,month:_month}),
      enabled: !!keyword,
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.data.current_page + 1;
        return nextPage <= lastPage.data.last_page ? nextPage : undefined;
      },
      keepPreviousData: true,
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const events = data?.pages?.flatMap((page) => page.data.data);
  const leftAds = data?.pages?.flatMap((page) => page?.ads?.search_left);
  const rightAds = data?.pages?.flatMap((page) => page?.ads?.search_right);
  const hashTags = data?.pages?.flatMap((page) => page.hash_tags);
  useEffect(()=>{
    refetch()
      },[selectedSpecializations, month, refetch])
  const storedValue = JSON.parse(localStorage.getItem("contest_obj"));

  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
          <div className="position-relative px-0">
            <Filter
              title="Events"
              options={[
                // { title: "Category", options: splashData?.home?.categories },
                {
                  title: "Specialization",
                  options: splashData?.home?.subcategories,
                },
                { title: "Month", options: [] },
                // { title: "Location", options: [] },
              ]}
              onSpecializationSelect={(specializations) => setSelectedSpecializations(specializations.map(spec => spec.value))}
              onMonthSelect = {(month)=>{
                console.log("month",month)
                setMonth(month)}}
            />
          </div>
        </Row>
        <div className="d-flex justify-content-center gap-3">
          <div>
            <div
              className="position-sticky"
              style={{ width: "335px", top: "8.2rem" }}
            >
              <FeedAdvertisement ads={leftAds} new_contest_obj={storedValue}/>
            </div>
          </div>
          <div>
            <EventsList
              data={events}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              loading={isLoading}
            />
          </div>
          <div className="d-flex flex-column gap-3" style={{ width: "335px" }}>
            <SearchHashtags data={hashTags} />
            <FeedAdvertisement ads={rightAds} new_contest_obj={storedValue}/>
          </div>
        </div>
      </Container>
    </>
  );
}

const EventsList = ({
  data,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  loading,
}) => {
  return (
    <div
      className="bg-white border rounded"
      style={{ marginTop: "1rem", width: "570px" }}
    >
      <div
        className="p-3"
        style={{ borderBottom: "1px solid rgba(29, 193, 221, 0.2)" }}
      >
        <h5 className="mb-0 fs-16 roboto-bold">Events</h5>
      </div>
      <div className="p-2">
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (!isFetchingNextPage) {
              fetchNextPage();
            }
          }}
          hasMore={hasNextPage}
          loader={
            <ShimmerCategoryItem
              hasImage
              imageType="thumbnail"
              imageWidth={50}
              imageHeight={50}
              text
              contentCenter
            />
          }
        >
          {loading ? (
            <div className="mx-2 pt-2">
              {[1, 2, 3, 4, 5].map(() => (
                <ShimmerCategoryItem
                  hasImage
                  imageType="thumbnail"
                  imageWidth={50}
                  imageHeight={50}
                  text
                  contentCenter
                />
              ))}
            </div>
          ) : (
            data?.map((item, index) => (
              <div className="p-2" key={index}>
                <EventCard event={item} />
              </div>
            ))
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};
