import { Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import SearchHashtags from "../../components/search/SearchHashtags";
import { Filter } from "./Filter";
import FeedAdvertisement from "../../components/feeds/FeedAdvertisement";
import { useLocation } from "react-router-dom";
import { useInfiniteQuery } from "react-query";
import { getAllSearchAgainstKeywordApi } from "../../services/searchApis";
import { toast } from "react-toastify";
import { PostsCard } from "../../components/search/PostsResultsComp";
import InfiniteScroll from "react-infinite-scroller";
import { ShimmerSocialPost } from "react-shimmer-effects";
import { LoginUserData } from "../../App";
import { useContext, useEffect, useState } from "react";

export default function PostsSearch() {
  const query = useLocation().search;
  const keyword = decodeURIComponent(query.split("=")[1]);
  const { splashData } = useContext(LoginUserData);
  const [date,setDate] = useState(null)
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  let specialization = selectedSpecializations

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage,refetch } =
    useInfiniteQuery({
      queryKey: ["search-posts", keyword],
      queryFn: ({ pageParam = 1 }) =>
        getAllSearchAgainstKeywordApi({keyword, type:"posts", pageParam,specialization,date}),
      enabled: !!keyword,
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.data.current_page + 1;
        return nextPage <= lastPage.data.last_page ? nextPage : undefined;
      },
      keepPreviousData: true,
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const posts = data?.pages?.flatMap((page) => page?.data?.data)||[];
  const leftAds = data?.pages?.flatMap((page) => page?.ads?.search_left);
  const rightAds = data?.pages?.flatMap((page) => page?.ads?.search_right);
  const hashTags = data?.pages?.flatMap((page) => page?.hash_tags);
  useEffect(()=>{
refetch()
  },[selectedSpecializations,date, refetch])
  const storedValue = JSON.parse(localStorage.getItem("contest_obj"));

  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
          <div className="position-relative px-0">
            <Filter
              title="Posts"
              options={[
                // { title: "Sort By", options: [] },
                { title: "Date", options: [] },
                // { title: "Media Type", options: [] },
                { title: "Specialization", options: splashData?.home?.subcategories },
              ]}
              onSelectDate={(date)=>setDate(date)}
              onSpecializationSelect={(specializations) => setSelectedSpecializations(specializations.map(spec => spec.value))}
            />
          </div>
        </Row>
        <div className="d-flex justify-content-center gap-3">
          <div>
            <div
              className="position-sticky"
              style={{ width: "335px", top: "5rem" }}
            >
              <FeedAdvertisement ads={leftAds} new_contest_obj={storedValue}/>
            </div>
          </div>
          <div>
            <PostsList
              loading={isLoading}
              data={posts}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          </div>
          <div style={{ width: "335px" }}>
            <SearchHashtags data={hashTags} />
            <FeedAdvertisement ads={rightAds} new_contest_obj={storedValue}/>
          </div>
        </div>
      </Container>
    </>
  );
}

const PostsList = ({
  loading,
  data,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) => {
  return (
    <div
      className="bg-white border rounded"
      style={{ marginTop: "1rem", width: "570px" }}
    >
      <div
        className="p-3"
        style={{ borderBottom: "1px solid rgba(29, 193, 221, 0.2)" }}
      >
        <h5 className="mb-0 fs-16 roboto-bold">Posts</h5>
      </div>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          if (!isFetchingNextPage) {
            fetchNextPage();
          }
        }}
        hasMore={hasNextPage}
        loader={<ShimmerSocialPost type="image" />}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((_, index) => (
              <ShimmerSocialPost type="image" key={index} />
            ))}
          </>
        ) : (
          data?.map((post, index) => <PostsCard key={index} post={post} />)
        )}
      </InfiniteScroll>
    </div>
  );
};
