import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import EventsCard from "./EventsCard";
import { fetchMyEvents } from "../../services/eventsApis";
import { ShimmerContentBlock } from "react-shimmer-effects";
import { Language } from "../../App";

const MyEvents = () => {
  const [eventsData, setEventsData] = useState([]);
  const [status, setStatus] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const { lang } = useContext(Language);

  const handleEvents = async (status) => {
    setIsLoading(true);
    try {
      const data = await fetchMyEvents({ status });
      if (data.status) {
        setEventsData(data.data);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      toast.error("Error fetching events:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleEvents(status);
  }, [status]);
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="roboto-bold mb-0 fs-16">{lang.my_events}</h5>
        <div className="d-flex gap-2">
          <button
            onClick={() => setStatus("0")}
            className={`btn bg-white shadow-sm ${
              status === "0" ? "text-dark fw-bold" : "text-muted"
            }`}
            style={{ borderRadius: "8px 0px 0px 8px" }}
          >
            {lang.upcoming}
          </button>
          <button
            onClick={() => setStatus("1")}
            className={`btn bg-white shadow-sm ${
              status === "1" ? "text-dark fw-bold" : "text-muted"
            }`}
            style={{ borderRadius: "0px 8px 8px 0px" }}
          >
            {lang.completed}
          </button>
        </div>
      </div>
      {isLoading ? (
        <>
          <ShimmerContentBlock
            title
            text
            thumbnailWidth={250}
            thumbnailHeight={250}
          />
          <ShimmerContentBlock
            title
            text
            thumbnailWidth={250}
            thumbnailHeight={250}
          />
        </>
      ) : eventsData && eventsData.length > 0 ? (
        eventsData?.map((value) =>
          value.data.map((val) => <EventsCard key={val.id} data={val} />)
        )
      ) : (
        <div className="text-center my-3">
          <p className="roboto-medium fs-14 text-muted">No events found</p>
        </div>
      )}
    </>
  );
};

export default MyEvents;
