import React, { useState, useCallback } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../hooks/useCropImage";

const ImageCropModal = ({ isOpen, toggle, imageSrc, onCropComplete,aspect }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = (crop) => setCrop(crop);
  const onZoomChange = (zoom) => setZoom(zoom);

  const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    try {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    const blobUrl = croppedImage;
    
    fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a FileReader to read the Blob as base64
          const reader = new FileReader();
          reader.onload = () => {
            // The result will be the Blob content as base64
            const base64Data = reader.result;
            onCropComplete(base64Data);
          };
          // Read the Blob as data URL
          reader.readAsDataURL(blob);
        })
        .catch((error) => console.error(error));
    toggle(); // Close modal
    } catch (error) {
        console.log(error);
        
    }
     // Pass the cropped image URL to parent component
   
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Image Cropper</ModalHeader>
      <ModalBody>
        <div style={{ position: "relative", width: "100%", height: "400px" }}>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={onCropChange}
            onZoomChange={onZoomChange}
            onCropComplete={onCropCompleteHandler}
          />
        </div>
      </ModalBody>
      <ModalFooter className="gap-3">
        <button className="btn btn-secondary border-0 px-4 py-2" onClick={toggle}>
          Cancel
        </button>
        <button className="btn-blue border-0 px-4 py-2" onClick={handleCrop}>
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ImageCropModal;
