
import React from 'react'
import NavBar from '../NavBar'
import { Container, Row } from 'reactstrap'

const NewCourseDetail = () => {
    return (
    <div style={{ backgroundColor: "#ffffff", minHeight: "100vh" }}> {/* White background for the whole page */}
        <Container fluid className="p-0">
            <Row style={{ backgroundColor: "#f8f9fa" }}> {/* Light background for the header */}
                <NavBar />
            </Row>
            <Row>
                <div className="container my-5" style={{backgroundColor:"#f0f7fa"}}>
                    {/* Main Course Card Section */}
                    <div className="card border-0  p-3 mb-4" style={{backgroundColor:"#f0f7fa"}} >
                        <div className="row g-0">
                            {/* Course Information Section */}
                            <div className="col-md-9">
                                <div className="card-body">
                                    <h5 className="card-title fs-4 fw-bold">
                                        The Theory Behind Electronics – A Beginners Guide
                                    </h5>
                                    <p className="text-primary mb-1">Ophthalmology - Uveitis</p>
                                    <p className="card-text text-muted">
                                        Scelerisque taciti integer vel quis mi ridiculus sollicitudin praesent bibendum.
                                        Torquent nisi pharetra penatibus ut lectus commodo amet porta enim ullamcorper orci.
                                    </p>
                                    <a href="#" className="btn btn-dark">Enroll Now - Free</a>
                                </div>
                            </div>
                            {/* Image Section */}
                            <div className="col-md-3 d-flex align-items-center">
                                <img src="https://medicalradar.es//uploads/user/11/courses/11-cover-6710f32ea2105.jpeg" className="img-fluid rounded" alt="Course Cover"/>
                            </div>
                        </div>
                    </div>
                    {/* Features Section */}
                    <div
      className="row text-center bg-white mx-4 shadow p-4 rounded"
      style={{ borderRadius: '8px' }} // Custom inline style for border radius
    >
      <div className="col-md-3">
        <div className="p-3">
          <h6 className="fw-bold">17 Course Chapters</h6>
          <p className="text-muted mb-0">Vehicula magnis primis sed tortor</p>
        </div>
      </div>
      <div className="col-md-3">
        <div className="p-3">
          <h6 className="fw-bold">37 Hours & 38 Minutes</h6>
          <p className="text-muted mb-0">Vehicula magnis primis sed tortor</p>
        </div>
      </div>
      <div className="col-md-3">
        <div className="p-3">
          <h6 className="fw-bold">Beginner Level</h6>
          <p className="text-muted mb-0">Vehicula magnis primis sed tortor</p>
        </div>
      </div>
      <div className="col-md-3">
        <div className="p-3">
          <h6 className="fw-bold">Earn Certificates</h6>
          <p className="text-muted mb-0">Vehicula magnis primis sed tortor</p>
        </div>
      </div>
    </div>

                </div>
            </Row>
        </Container>
    </div>
    )
}

export default NewCourseDetail
