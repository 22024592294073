import React, { useState } from 'react';
import NavBar from '../../../components/NavBar';
import { Container, Row, Col, Button } from 'reactstrap';
import './wallet.css'; // Import individual component styling if needed
import './WalletLayout.css'; // Import the new common styling for uniform layout
import WithdrawRequests from './WithdrawRequests';
import DebitCreditAmounts from './DebitCreditAmounts';
import PayPalDetailsModal from './PayPalDetailsModal ';
import WithdrawRequestModal from './WithdrawRequestModal ';
import TransactionDetails from './TransactionDetails';
import { useQuery } from 'react-query';
import { fetchPayments, useRemovePaypalAccount } from '../../../services/walletApi';

const Wallet = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [detailPaypalModal, setDetailPaypalModal] = useState(false);
  const [withdrawPayments,setWithDrawPayments] = useState([])
  const [debitAccounts,setDebitAccounts] = useState([])

  const toggleModal = () => setModalOpen(!modalOpen);
  const togglePaypalDetailModal = () => setDetailPaypalModal(!detailPaypalModal);
  const { mutate: removeAccount, isLoading } = useRemovePaypalAccount();
  const handleRemoveAccount = () => {
    removeAccount()
    togglePaypalDetailModal()
  };
  
  const {
    data,
    isLoading: isLoadingPayments,
    isError,
    error,
  } = useQuery({
    queryKey: ["payments"],
    queryFn: fetchPayments,
    onSuccess: (data) => {
      if (data?.status) {
  const filteredData = data?.payments.filter((item) => item.type === "debit");
  setDebitAccounts(filteredData)
        setWithDrawPayments(data.withdraw_payments)
      } else {
    
      }
    },
    refetchOnWindowFocus: false,
    onError: (err) => {
     
    },
  });
let disabled = (data?.data?.available < 100) || (data?.data?.email === null);
const message = "No transaction history is available to show at the moment.";
  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
        </Row>
        <Row className="justify-content-center mt-5 pt-5">
          <Col xs="12" md="8" lg="6" className="wallet-card card-container">
            <div className="text-center">
              <p className="withdrawal-text">Available withdrawal amount</p>
              <h2 className="amount">{data?.data?.available}€</h2>
            </div>
            <Row className="button-row mt-4">
              <Col className="text-center p-2 button-col">
                <Button style={{backgroundColor:"#f9f9f9",border:"none"}} disabled={disabled}  onClick={toggleModal} className={`wallet-button cursor custom-link-color`}>Get Payment</Button>
              </Col>
              <Col className="text-center p-2">
                <Button style={{backgroundColor:"#f9f9f9",border:"none"}} onClick={togglePaypalDetailModal} className="wallet-button cursor custom-link-color">{data?.data?.paypal_email ? "See PayPal Details" : "Connect PayPal"}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {withdrawPayments.length>0&&<div className="mt-3">
        <WithdrawRequests withdrawPayments={withdrawPayments} clearanceDays={data?.data?.clearance_days}/>
      </div>}
<>

      <Row className="justify-content-center mt-2">
        <div className="content-wrapper">
<h5 className="section-heading justify-content-start">Debit & Credit Amounts:</h5>
</div>
</Row>


      {data?.payments.length>0?
     <>
    {debitAccounts.length>0&& <div className="mt-2">
        <DebitCreditAmounts />
      </div>}

      <Container fluid className="mt-4 mb-2">
        <Row className="d-flex justify-content-center">
          <Col xs="12" md="8" lg="6" className="card-container p-4">
          <div className="mt-2 ">
            <TransactionDetails payments={data?.payments}/>
            </div>
          </Col>
        </Row>
      </Container>
      </>:<p className="text-gray text-center">{message}</p>
      }
</>
      <WithdrawRequestModal isOpen={modalOpen} toggle={toggleModal} 
   
      />
      <PayPalDetailsModal
        isOpen={detailPaypalModal}
        toggle={togglePaypalDetailModal}
        email={data?.data?.paypal_email}
        onRemove={handleRemoveAccount}
        isLoading={isLoading}
      />
    </>
  );
};

export default Wallet;
