import React, { useContext } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Language } from "../../App";
// import ImageComponent from "../common/ImageComponent";
// import { BaseURL } from "../../constants/API";

const ChatMediaModal = ({ isOpen, toggle }) => {
  const { lang } = useContext(Language);
  return (
    <Modal isOpen={isOpen} centered toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        <h3 className="fs-18 fs-md-15 text-center m-0 text-ellips-149px">
          {lang?.media_photo_videos}
        </h3>
      </ModalHeader>
      <ModalBody>
        <div className="job-scroll">
          <div className="container-fluid">
            {/* <div className="row text-center ">
              {groupphotoLoading ? (
                <div className="vh-100 d-flex justify-content-center align-items-center">
                  <SpecSpinner />
                </div>
              ) : groupPhoto.length > 0 ? (
                groupPhoto.map((item) => (
                  <>
                    <div
                      key={item.id}
                      className="col-md-3 my-1 p-1 text-center"
                    >
                      <div className="card p-1">
                        <ImageComponent
                          src={BaseURL + item.attachment}
                          compClassName="groupimageshadow rounded object-fit-cover cursor"
                          img_height={250}
                          loader_height="100%"
                          loader_width="100%"
                        />
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div className="col-md-12 text-center">
                  <p>{lang?.no_photos_available}</p>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChatMediaModal;
