import React, { useContext, useCallback, useEffect, useState } from "react";
import {
  Col,
  Form,
  Modal,
  ModalFooter,
  ModalHeader,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import { Language, LoginUserData } from "../../../App";
import { RiImageAddFill } from "react-icons/ri";
import ImageCropModal from "../../common/ImageCropModal";
import { TiCancel } from "react-icons/ti";
import { toast } from "react-toastify";
import { AddEventApi, AddEventQuestionApi } from "../../../constants/API";
import axios from "axios";
import AddQuestionModal from "./AddQuestionModal";
import LocationModal from "./LocationModal";
import { getToken } from "../../../shared/token";
const CreateEventModal = ({ isOpen, toggle }) => {
    const { lang } = useContext(Language);
    const { splashData } = useContext(LoginUserData);
    const [addQuestionModal, setAddQuestionModal] = useState(false);
    const [isCropModalOpen, setIsCropModalOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
    const [locationModal, setLocationModal] = useState(false);
    const [eventInput, setEventInput] = useState(null);
    const [formData, setFormData] = useState({
      eventTitle: "",
      organizerName: "",
      eventCategory: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      location: "",
      address: "",
      website: "",
      registrationLink: "",
      availability: "",
      audienceLimit: "",
      password: "",
      eventPrice: "",
      description: "",
      lat_lng: ""
    });
    const [city, setCity] = useState(null)
    const [country, setCountry] = useState(null)
    const [mapCenter, setMapCenter] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [timeInput, setTimeInput] = useState({
      hr_start: "",
      min_start: "",
      median_start: "",
      hr_end: "",
      min_end: "",
      median_end: "",
    });
    const [questionsData, setQuestionsData] = useState([]);
    const [questionsCount, setQuestionsCount] = useState(0);
    // sessionStorage
    const SplashData = JSON.parse(localStorage.getItem("SplashData"));
    const user_id = SplashData?.user?.id;
  
    const HH = [];
    for (let i = 1; i <= 12; i++) {
      const num = i > 9 ? `${i}` : `0${i}`;
      HH.push(num);
    }
    const MM = [];
    for (let i = 1; i <= 60; i++) {
      const num = i > 9 ? `${i}` : `0${i}`;
      MM.push(num);
    }
    // Generic handleChange function
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
  // Function to validate the form
  const validateForm = () => {
    const numericRegex = /^[0-9]+$/;
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const currentDay = currentDate.getDate().toString().padStart(2, "0");
    const currentDateString = `${currentYear}-${currentMonth}-${currentDay}`;
  
    // Validate Start Time
    if (timeInput.hr_start || timeInput.min_start || timeInput.median_start) {
      if (!timeInput.hr_start || !timeInput.min_start || !timeInput.median_start) {
        toast.error(lang?.please_enter_event_start_time);
        return false;
      }
    }
  
    // Validate End Time
    if (timeInput.hr_end || timeInput.min_end || timeInput.median_end) {
      if (!timeInput.hr_end || !timeInput.min_end || !timeInput.median_end) {
        toast.error(lang?.please_enter_event_time);
        return false;
      }
    }
  
    // Validate audience limit
    // if (
    //   typeof formData.audienceLimit !== "undefined" &&
    //   formData?.audienceLimit !== "" &&
    //   !numericRegex.test(formData?.audienceLimit.toString())
    // ) {
    //   toast.error(lang?.audience_limit_should_numeric);
    //   return false;
    // }
  
    // // Validate price
    // if (
    //   typeof formData.price !== "undefined" &&
    //   formData?.price !== "" &&
    //   !numericRegex.test(formData?.price.toString())
    // ) {
    //   toast.error(lang?.price_should_numeric);
    //   return false;
    // }
  
    // Validate start and end date
    if (formData?.startDate > formData?.endDate) {
      toast.error(lang?.start_date_should_greater_end_date);
      return false;
    }
  
    if (formData?.startDate < currentDateString) {
      toast.error(lang?.start_date_cannot_less_today_date);
      return false;
    }
  
    if (formData?.endDate < currentDateString) {
      toast.error(lang?.end_date_cannot_less_today_date);
      return false;
    }
  
    // Validate time difference between start and end
    const startUnixTime = new Date(formData?.startDate).getTime() / 1000;
    const endUnixTime = new Date(formData?.endDate).getTime() / 1000;
    const timeDifferenceInSeconds = endUnixTime - startUnixTime;
    const tenMinutesInSeconds = 10 * 60;
  
    if (timeDifferenceInSeconds < tenMinutesInSeconds) {
      toast.error(lang?.there_should_least_minute_betwen_s_time_e_time);
      return false;
    }
  
    // Validate website URL
    // if (formData?.website && !urlRegex.test(formData?.website)) {
    //   toast.error(lang?.website_url_note_valid);
    //   return false;
    // }
  
    // Validate registration link URL
    // if (formData?.registrationLink && !urlRegex.test(formData?.registrationLink)) {
    //   toast.error(lang?.registration_link_url_not_valid);
    //   return false;
    // }
  
    // Validate password length
    // if (formData?.password && formData?.password.length < 6) {
    //   toast.error(lang?.password_should_min_characters);
    //   return false;
    // }
  
    // If all validations pass, return true
    return true;
  };
  
  const handleSubmit = () => {
    // Perform validation
    if (!validateForm()) {
      return; // Exit if validation fails
    }
  
    // Prepare Start and End Time
    const StartTime = `${timeInput.hr_start}:${timeInput.min_start} ${timeInput.median_start}`;
    const EndTime = `${timeInput.hr_end}:${timeInput.min_end} ${timeInput.median_end}`;
  
    // Prepare API call payload
    const data = {
      cover_base64: croppedImage,
      start_timestamp: new Date(formData?.startDate).getTime() / 1000,
      end_timestamp: new Date(formData?.endDate).getTime() / 1000,
      user_id,
      title: formData?.eventTitle,
      organizer: formData?.organizerName,
      description: formData?.description,
      date: formData?.startDate,
      time: StartTime,
      availability: formData?.availability,
      location: formData?.lat_lng ? `${city}, ${country}` : "",
      short_location: formData?.address || "",
      lat_lng: formData?.lat_lng
        ? `${formData?.lat_lng.lat},${formData?.lat_lng.lng}`
        : "",
      website: formData?.website || "",
      link: formData?.registrationLink || "",
      end_date: formData?.endDate,
      end_time: EndTime,
      password: formData?.password || "",
      limit: formData?.audienceLimit || "",
      price: formData?.price || "",
      tags: "@nnn",
      category_id: "3",
      subcategory_id: formData?.eventCategory,
    };
    // Set loading state
    setIsLoading(true);
  
    // Perform API call
    axios
      .post(AddEventApi, data, {
        headers: {
          "Content-Type": "multipart/form-data",
           Authorization: `Bearer ${getToken()}`
        },
      })
      .then((res) => {
        if (questionsData && questionsData.length > 0 && questionsData[0].question) {
          const questionData = {
            event_id: res.data.event_id,
            questions: questionsData,
          };
          axios
            .post(AddEventQuestionApi, questionData,{
        
              headers: {
                  Authorization: `Bearer ${getToken()}`, // Set the Authorization header
              },
          
          })
            .then((res) => {
              setIsLoading(false);
              if (res.data.status) {
                window.location.reload();
              } else {
                toast.error("Error", res.data.action);
              }
            })
            .catch((error) => {
              toast.error("Error", error);
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        toast.error(error);
        setIsLoading(false);
      })
      
  };
  
    
  
    const toggleImagePreview = () => setIsImagePreviewOpen(!isImagePreviewOpen);
  
    const handleCropComplete = (croppedImageURL) => {
      setCroppedImage(croppedImageURL);
      setIsCropModalOpen(false);
    };
    const handleFileChange = (event) => {
      const file = event.target.files[0];
    setEventInput(file)
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setImageSrc(reader.result);
          setIsCropModalOpen(true);
        };
      }
    };
    const handleRemoveImage = () => {
      setCroppedImage(null);
      setImageSrc(null);
    };
    const handleLocation = () => {
      if (formData?.lat_lng || markerPosition) {
        setLocationModal(true);
        return;
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setMapCenter({ lat: latitude, lng: longitude });
            setMarkerPosition({ lat: latitude, lng: longitude });
          },
          () => { }
        );
        setTimeout(() => {
          setLocationModal(true);
        }, 500);
      } else {
        toast.error(lang?.geolocation_not_suported);
      }
    };
    const onSave = useCallback((markerPosition) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        lat_lng: markerPosition,
      }));
    }, [])
    const getQuestions = (questions,count) => {
      
      setQuestionsData(questions);
      setQuestionsCount(count)
    };
    return (
      <>
        <Offcanvas isOpen={isOpen} toggle={toggle}>
          <OffcanvasHeader>Create Event</OffcanvasHeader>
          <OffcanvasBody>
            <p className="fs-14 roboto-regular text-gray">
              Fill out the fields below to create your new event, including the
              title, date, time, location, and some other description.
            </p>
            <Row>
              <Col>
                <Row>
                  <Col>
                    {croppedImage ? (
                      <div
                        className="position-relative"
                        style={{
                          cursor: "pointer",
                          border: "1px dashed #A2A2A2",
                          minHeight: "128px",
                          borderRadius: "6px",
                        }}
                        onClick={toggleImagePreview}
                      >
                        <img
                          src={croppedImage}
                          name="cover"
                          alt="Cropped Preview"
                          className="w-100 br-16"
                          style={{ objectFit: "cover", height: "15rem" }}
                        />
                        <button
                          className="bg-white position-absolute end-0 me-3 mt-3 br-6 bg-white border-0 px-2 py-0 fs-16 roboto-bold"
                          style={{
                            top: "-10px",
                            right: "-10px",
                            background: "transparent",
                            border: "none",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveImage();
                          }}
                        >
                          x
                        </button>
                      </div>
                    ) : (
                      <div
                        style={{
                          cursor: "pointer",
                          border: "1px dashed #A2A2A2",
                          minHeight: "128px",
                          borderRadius: "6px",
                        }}
                        className="d-flex flex-column align-items-center justify-content-center"
                        onClick={() => {
                          document.getElementById("fileInput").click();
                        }}
                      >
                        <RiImageAddFill size={20} color="#848798" />
                        <h4 className="fs-14 roboto-medium pt-3" style={{ color: "#848798" }}>
                          {lang?.add_event_cover}
                        </h4>
  
                      </div>
                    )}
                    <input
                      type="file"
                      id="fileInput"
                      name="cover"
                      className="d-none"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                    {errors.cover && <p className="text-danger">{errors.cover}</p>}
                  </Col>
  
                </Row>
                <Form className="mt-3">
                  <Row className="mb-3">
                    <Col>
                      <input
                        type="text"
                        className="py-2 form-control"
                        name="eventTitle"
  
                        placeholder={lang?.event_title}
                        value={formData.eventTitle}
                        onChange={handleChange}
                      />
                      {errors.eventTitle && <p className="text-danger">{errors.eventTitle}</p>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <input
                        type="text"
                        className="py-2 form-control"
                        value={formData.organizerName}
                        onChange={handleChange}
                        name="organizerName"
                        placeholder={lang?.organizer_name}
                      />
                      {errors.organizerName && <p className="text-danger">{errors.organizerName}</p>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <select className="form-select py-2"
                        name="eventCategory"
                        // value={formData.eventCategory}
                        onChange={handleChange}
                      >
                        
                        <option disabled selected hidden>
                          {lang?.event_category} 
                        </option>
  
                        {splashData &&
                          splashData.home &&
                          splashData.home.subcategories &&
                          splashData.home.subcategories.map((value, index) => (
                            <option value={value.id} key={index}>
                              {value.name}
                            </option>
                          ))}
                      </select>
                      {errors.eventCategory && <p className="text-danger">{errors.eventCategory}</p>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <label
                        className="roboto-regular fs-12 text-gray"
                        htmlFor="startDate"
                      >
                        {lang.start_date}
                    
                      </label>
                      <input
                        name="startDate"
                        type="date" className="form-control py-2"
                        value={formData.startDate}
                        onChange={handleChange}
                      />
                      {errors.startDate && <p className="text-danger">{errors.startDate}</p>}
  
                    </Col>
                    <Col>
                  <label className="pb-1 fs-15 roboto-medium">
                    {lang?.start_time}{" "}
                    <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                  </label>
                  <div className="d-flex">
                    <select
                      className="form-control rounded-0"
                      onChange={(e) => {
                        setTimeInput({ ...timeInput, hr_start: e.target.value });
                      }}
                    >
                      <option value="" selected>
                        --
                      </option>
                      {HH.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-control rounded-0"
                      onChange={(e) => {
                        setTimeInput({ ...timeInput, min_start: e.target.value });
                      }}
                    >
                      <option value="" selected>
                        --
                      </option>
                      {MM.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-control rounded-0"
                      onChange={(e) => {
                        setTimeInput({
                          ...timeInput,
                          median_start: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected>
                        --
                      </option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <label
                        className="roboto-regular fs-12 text-gray"
                        htmlFor="endDate"
                      >
                        {lang.end_date}
                      </label>
                      <input
                        name="endDate"
                        type="date" required className="form-control py-2"
                        value={formData.endDate}
                        onChange={handleChange}
                      />
                      {errors.endDate && <p className="text-danger">{errors.endDate}</p>}
  
                    </Col>
                    <Col>
                  <label className="pb-1 fs-15 roboto-medium">
                    {lang?.end_time}{" "}
                    <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                  </label>
                  <div className="d-flex">
                    <select
                      className="form-control rounded-0"
                      onChange={(e) => {
                        setTimeInput({ ...timeInput, hr_end: e.target.value });
                      }}
                    >
                      <option value="" selected>
                        --
                      </option>
                      {HH.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-control rounded-0"
                      onChange={(e) => {
                        setTimeInput({ ...timeInput, min_end: e.target.value });
                      }}
                    >
                      <option value="" selected>
                        --
                      </option>
                      {MM.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-control rounded-0"
                      onChange={(e) => {
                        setTimeInput({
                          ...timeInput,
                          median_end: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected>
                        --
                      </option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <label className="pb-1 fs-15 roboto-medium">
                        {lang?.location}{" "}
                        <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                      </label>
                      <div className="position-relative">
                        <input
                          onClick={handleLocation} // Opens the modal when clicked
                          type="text"
                          className="py-2 form-control"
                          placeholder="City, Country"
                          value={
                            formData?.lat_lng && city && country
                              ? `${city}, ${country}`
                              : ""
                          }
                          readOnly
                        />
                        {city && country && (
                          <div
                            className="position-absolute end-0 top-0 me-2"
                            style={{ marginTop: "13px" }}
                          >
                            <h6
                              className="text-primary cursor"
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  lat_lng: "", // Clear lat_lng in formData
                                });
                                setCity(""); // Clear city
                                setCountry(""); // Clear country
                              }}
                            >
                              <TiCancel size={18} />
                              {lang?.remove || "Remove"}
                            </h6>
                          </div>
                        )}
                      </div>
                    </Col>
                 
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <input
                        type="text"
                        name="address"
                        className="py-2 form-control"
                        placeholder="Address (optional)"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <input
                        type="url"
                        name="website"
                        className="py-2 form-control"
                        value={formData.website}
                        onChange={handleChange}
                        placeholder={lang?.website + " " +lang.opti}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <input
                        type="url"
                        className="py-2 form-control"
                        placeholder={lang?.registration_link + " " +lang.opti}
                        value={formData.registrationLink}
                        name="registrationLink"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <select name="availability" className="form-select py-2"
                        value={formData.availability}
                        onChange={handleChange}
                      >
                        <option selected hidden value="">
                          {lang?.choose_availablity + " " +lang.opti}
                        </option>
                        <option value="online">{lang?.online}</option>
                        <option value="offline">{lang?.offline}</option>
                        <option value="online-offline">
                          {lang?.online_offline}
                        </option>
                      </select>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <input
                        type="text"
                        autoComplete="off"
                        name="audienceLimit"
                        className="py-2 form-control"
                        placeholder={lang?.audience_limit + " " +lang.opti}
                        value={formData.audienceLimit}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <input
                        autoComplete="new-password"
                        type="password"
                        name="password"
                        className="py-2 form-control"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder={lang?.set_password + " " +lang.opti}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <input
                        autoComplete="off"
                        type="text"
                        name="eventPrice"
                        className="py-2 form-control"
                        placeholder={lang?.event_price + " " +lang.opti}
                        value={formData.eventPrice}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <textarea
                        className="form-control resize"
                        name="description"
                        rows="5"
                        placeholder={lang?.description}
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea>
                      {errors.description && <p className="text-danger">{errors.description}</p>}
  
                    </Col>
                  </Row>
  
                  <hr style={{ opacity: "0.2" }} />
                  <Row className="mb-3 justify-content-between">
                    <Col>
                      <div className="d-flex">
                        <button
                          type="button"
                          onClick={() => {
                            // toggle();
                            setAddQuestionModal(true);
                          }}
                          className="btn bg-gray text-black roboto-bold px-4"
                        >
                          <b>{lang?.add_question}</b>
                          <span className="text-secondary">{lang.opti}</span>
                        </button>
                     {questionsCount>0&& <div className="align-self-center mt-4 col-md-1">
                        <div className="bg-blue text-center rounded-circle p-2">
                          <h5 className="text-white mb-0">{questionsCount}</h5>
                          </div>
                          </div>}
                      </div>
                      
                    </Col>
                  </Row>
                  <hr style={{ opacity: "0.2" }} />
  
                  <Row>
                    <Col>
                      <button
                        type="button"
                        className="bg-secondary text-white border-0 w-100 my-4 py-3 rounded fw-bold"
                      >
                        {lang?.cancel}
                      </button>
                    </Col>
                    <Col>
                      <button
                        type="button"
                        className="btn-blue border-0 w-100 my-4 py-3 rounded fw-bold"
                        onClick={handleSubmit}
                        disabled={
                          isLoading ||
                          !eventInput ||
                          !formData?.eventTitle ||
                          !formData?.organizerName ||
                          !formData?.description ||
                          !formData?.startDate ||
                          !formData?.endDate ||
                          !formData?.eventCategory 
                          ||
                          !formData?.availability
                        }
                      >
                        {lang?.add_event}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </OffcanvasBody>
        </Offcanvas>
        <AddQuestionModal
          isOpen={addQuestionModal}
          toggle={() => setAddQuestionModal(!addQuestionModal)}
          getQuestions={getQuestions}
        />
        <ImageCropModal
          isOpen={isCropModalOpen}
          toggle={() => setIsCropModalOpen(!isCropModalOpen)}
          imageSrc={imageSrc}
          aspect={1.91}
          onCropComplete={handleCropComplete}
        />
        <Modal isOpen={isImagePreviewOpen} toggle={toggleImagePreview} centered className="image-preview-modal">
          <img src={croppedImage} alt="Full Preview" className="img-fluid" />
        </Modal>
        {locationModal && <LocationModal
          isOpen={locationModal}
          toggle={() => setLocationModal(!locationModal)}
          mapCenter={mapCenter}
          setMapCenter={setMapCenter}
          markerPosition={markerPosition}
          setMarkerPosition={setMarkerPosition}
          setCity={setCity}
          setCountry={setCountry}
          onSave={onSave}
        />}
  
      </>
    );
  };

  export default CreateEventModal