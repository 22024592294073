import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import FeedAdvertisement from "../../components/feeds/FeedAdvertisement";
import CourseBanner from "../../components/courses/CourseBanner";
import clinical_banner from "../../assets/images/icons/clinical-banner.jpg";
import { fetchClinicalSessionData } from "../../services/coursesApi";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import CreateClinicalSessionCard from "../../components/clinical-sessions/CreateClinicalSessionCard";
import ClinicalSessionTabMenuCard from "./ClinicalSessionTabMenuCard";
import LiveSessions from "./LiveSessions";
import MySessions from "./MySessions";
import InvitedSessions from "./InvitedSessions";
import ShimmerClinicalType from "./ShimmerClinicalType";

const banner_images = [clinical_banner];

const session_types = [
  {
    id: 1,
    title: "Live Sessions",
    key: "liveSession",
  },
  {
    id: 2,
    title: "My Sessions",
    key: "mySession",
  },
  {
    id: 3,
    title: "Invited Sessions",
    key: "invitedSession",
  },
];

const ClinicalSessions = () => {
  const [selectedSessionType, setSelectedSessionType] = useState(1);
  const [counts, setCounts] = useState({
    liveSession: null,
    mySession: null,
    invitedSession: null,
  });
  const storedValue = JSON.parse(localStorage.getItem("contest_obj"));

  const { data, isLoading, isError,refetch } = useQuery({
    queryKey: ["clinicalSessionData"],
    queryFn: () => fetchClinicalSessionData({ type:"live"
      // : session_types.find(type => type.id === selectedSessionType).key
     }),
    onSuccess: (data) => {
      setCounts({
        liveSession: data?.counts?.live,
        mySession: data?.counts?.my,
        invitedSession: data?.counts?.invited,
      });
    },
    refetchOnWindowFocus: false, 
  });
  useEffect(() => {
   
      setCounts({
        liveSession: data?.counts?.live ,
        mySession: data?.counts?.my ,
        invitedSession: data?.counts?.invited,
      });
    
  }, [data]);
  useEffect(() => {
    // Automatically refetch when the session type changes
    refetch();
  }, [selectedSessionType, refetch]);
  if (isError) {
    toast.error("Error fetching Clinical Session data");
  }
  const titleComponent = selectedSessionType
  ? (
    <div className="my-3">
      <h4 className="mb-0 fs-20 roboto-bold">
        {session_types.find(
          (course_type) => course_type.id === selectedSessionType
        )?.title}
      </h4>
    </div>
  )
  : null;

  const noDataMessage = (
    <div className="centered-message-container">
        <div className="centered-message-text">
            <p className="fs-16 roboto-medium mb-1 text-muted">No Available Sessions!</p>
            <p className="roboto-medium mb-1 text-muted">
                We're sorry, but there are currently no clinical sessions available<br/> at the moment. 
                Please check back later or explore other services.
            </p>
        </div>
    </div>
);

  return (
    <Container fluid>
      <Row>
        <NavBar />
      </Row>
      <Row>
        <div
          className="d-flex justify-content-center gap-5 pb-3"
          style={{ paddingTop: "5rem" }}
        >
          <div style={{ width: "335px" }}>
            <CreateClinicalSessionCard />
            <FeedAdvertisement ads={data?.ads} new_contest_obj={storedValue}/>
          </div>
          <div style={{ width: "922px" }}>
            <CourseBanner banner_images={banner_images} />
            {/* Tabs */}
         
            <Row className="justify-content-between gap-2 my-3">
    {isLoading
        ? // Show shimmer components while loading
          session_types.map((_, index) => (
              <Col key={index}>
                  <ShimmerClinicalType />
              </Col>
          ))
        : // Show actual session types once data is loaded
          session_types.map((course_type) => (
              <Col key={course_type.id}>
                  <ClinicalSessionTabMenuCard
                      active={course_type.id === selectedSessionType}
                      onClick={() => {
                          if (course_type.id === selectedSessionType) {
                              // setSelectedSessionType(null);
                          } else {
                              setSelectedSessionType(course_type.id);
                          }
                      }}
                      title={course_type.title}
                      count={counts[course_type.key]}
                  />
              </Col>
          ))}
</Row>

            {/* end of tabs */}

            {selectedSessionType ? (
  <>
    {selectedSessionType === 1  && counts.liveSession>0&& titleComponent}
 


{selectedSessionType === 1 ? (
             data?.data?.length === 0 ? (
                noDataMessage // Show message if no data is available
            ) : (
                <LiveSessions count={counts.liveSession} data={data?.data} isLoading={isLoading} />
            )
        ) : selectedSessionType === 2 ? (
            <>
                {counts.mySession>0&&titleComponent}
                {counts.mySession === 0 ? (
                    noDataMessage
                ) :  (
                    <MySessions count={counts.mySession} />
                )}
            </>
        ) : selectedSessionType === 3 ? (
            <>
                {counts.invitedSession>0&&titleComponent}
                {counts.invitedSession === 0 ? (
                   noDataMessage
                )  : (
                    <InvitedSessions count={counts.invitedSession} />
                )}
            </>
        ) : null}
  </>
) : 
null
}
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default ClinicalSessions;
