import React, { useContext, useEffect, useState } from "react";
import {
  AddCommentApi,
  AddCommentRepliesApi,
  BaseURL,
  ClinicalStudiesUsers,
  DeleteCommentApi,
  EditPostApi,
  GetCommentsApi,
  GetRepliesApi,
  LikeApi,
  ReportJobApi,
  RepostApi,
  ReserveApi,
  SaveApi,
  UserPolls,
  UsersPostsPhoto,
  UsersWebinars,
} from "../../constants/API";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Carousel,
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
} from "reactstrap";
import NavBar from "../NavBar";
import { toast } from "react-toastify";
import axios from "axios";
import SpecSpinner from "../manage_account/changeInterest/SpecSpinner";
import { PROFILE, WEBINAR } from "../../constants/RoutesUrl";
import SpinnerGif from "../../assets/images/Spinner.gif";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { UnixToRelative } from "../notification/Notification";
import ImageWithAspectRatio from "../common/ImagewithAspectRatio";
import { BsSendFill } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import Dots from "../../assets/images/icon/post-dropdown-dots.png";
import Like from "../../assets/images/icon/like.png";
import ReshareActive from "../../assets/images/icon/repost-active.png";
import Reshare from "../../assets/images/icon/reshare.png";
import Save from "../../assets/images/icon/save.png";
import Saved from "../../assets/images/icon/saved.png";
import Liked from "../../assets/images/icon/liked.png";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import Comment from "../../assets/images/icon/comment.png";
import VideoWithAspectRatio from "../common/VideowithAspectRatio.tsx";
import InfiniteScroll from "react-infinite-scroller";
import { Language } from "../../App.js";
import { authorize } from "../../shared/authorize.js";

function ProfilePost() {
  const localData = JSON.parse(localStorage.getItem("SplashData"));
  const login_id = localData?.user?.id;
  const localDataa = JSON.parse(sessionStorage.getItem("profileData"));
  const user_id = localDataa.user.id;

  const navigate = useNavigate();
  const { lang } = useContext(Language);
  const [loading, setLoading] = useState(false);
  const [postDropDown, setPostDropDown] = useState(false);
  const [clinicalloading, setClinicalLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [totalpagesClinical, setTotalPagesClinical] = useState(null);
  const [editPostInput, setEditPostInput] = useState("");
  const [data, setData] = useState([]);
  const [Clinicaldata, setClinicalData] = useState([]);
  const [postComments, setPostComments] = useState({});
  const [replyDropdown, setReplyDropdown] = useState(false);
  const [comment, setComment] = useState("");
  const [isReply, setIsReply] = useState(false);
  const [postDeleteModal, setPostDeleteModal] = useState(false);
  const [editPostModal, setEditPostModal] = useState(false);
  const [postId, setPostId] = useState("");
  const [userPolls, setUserPolls] = useState([]);
  const [postModal, setPostModal] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [page, setPage] = useState(1);
  const [pageclinical, setPageClinical] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [replyUser, setReplyUser] = useState({});
  const [spin, setSpin] = useState(false);
  const [commentReplies, setCommentReplies] = useState([]);
  const [dropDownId, setDropDownId] = useState(null);
  const [reportInput, setReportInput] = useState("");
  const param = useParams();
  const [postData, setPostData] = useState([]);

  const [tabs, setTabs] = useState(param.id);

  const [myJobs, setMyJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleReserveWebinar = (post_id) => {
    const updatedData = data.map((value) => {
      if (value.id === post_id) {
        return { ...value, isReserve: !value.isReserve };
      }
      return value;
    });

    setData(updatedData);

    axios
      .get(ReserveApi+post_id,authorize())
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEditPost = () => {
    setIsLoading(true);
    const editData = { caption: editPostInput, post_id: dropDownId };
    axios
      .post(EditPostApi, editData,authorize())
      .then((res) => {
        if (res.data.status) {
          toast.success(lang?.edit_success);
          setIsLoading(false);
          setEditPostModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setIsLoading(false);
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleAddReplies = (comment_id) => {
    axios
      .post(`${AddCommentRepliesApi}`,
        {
          parent: comment_id,
          post_id:postId,
          comment,
        },authorize())
      .then((res) => {
        if (res.data.status) {
          setComment("");
          handleGetComments(postId);
          handleGetReplies(commentId);
          setIsReply(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handlePostReport = () => {
    setIsLoading(true);
    const reportData = {
      report_id: dropDownId,
      user_id,
      report: reportInput,
      type: "post",
    };
    axios
      .post(ReportJobApi, reportData)
      .then((res) => {
        if (res.data.status) {
          toast.success("Reported Successfully!");
          setIsLoading(false);
          setReportModal(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleGetReplies = (comment_id) => {
    axios
      .get(`${GetRepliesApi}${comment_id}`
        ,authorize())
      .then((res) => {
        if (res.data.status) {
          setCommentReplies(res.data.data);
          setReplyDropdown(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddComment = () => {
    const data = {
      comment,
    };
    axios
      .post(`${AddCommentApi}`,
        { comment,post_id:postId }
      ,authorize())
      .then((res) => {
        if (res.data.status) {
          setComment("");
          handleGetComments();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeleteComment = () => {
    axios
      .get(`${DeleteCommentApi}${commentId}`
        ,authorize())
      .then((res) => {
        if (res.data.status) {
          setCommentId("");
          setDeleteModal(false);
          handleGetComments();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRepost = (post_id) => {
    const updatedData = Clinicaldata.map((value) => {
      if (value.id === post_id) {
        return { ...value, isShare: !value.isShare };
      }
      return value;
    });

    setClinicalData(updatedData);
    axios
      .get(`${RepostApi}${post_id}`,authorize())
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRepostPost = (post_id) => {
    const updatedData = postData.map((value) => {
      if (value.id === post_id) {
        return { ...value, isShare: !value.isShare };
      }
      return value;
    });

    setPostData(updatedData);
    axios
      .get(`${RepostApi}${post_id}`,authorize())
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRepostPolls = (post_id) => {
    const updatedData = userPolls.map((value) => {
      if (value.id === post_id) {
        return { ...value, isShare: !value.isShare };
      }
      return value;
    });

    setUserPolls(updatedData);
    axios
      .get(`${RepostApi}${post_id}`,authorize())
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleGetComments = (post_id) => {
    setIsLoading(true);
    const id = post_id ? post_id : postId;
    axios
      .get(`${GetCommentsApi}${id}?page=1`
        ,authorize())
      .then((res) => {
        if (res.data.status) {
          setPostComments(res.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFetch = () => {
    setLoading(true);
    axios
      .get(UsersWebinars + user_id)
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data.data);
        } else {
          toast.error(res.data.action);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const handleSave = (post_id) => {
    const updatedData = Clinicaldata.map((value) => {
      if (value.id === post_id) {
        return { ...value, isSave: !value.isSave };
      }
      return value;
    });

    setClinicalData(updatedData);
    axios
      .get(SaveApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSavePost = (post_id) => {
    const updatedData = postData.map((value) => {
      if (value.id === post_id) {
        return { ...value, isSave: !value.isSave };
      }
      return value;
    });

    setPostData(updatedData);
    axios
      .get(SaveApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSavePolls = (post_id) => {
    const updatedData = userPolls.map((value) => {
      if (value.id === post_id) {
        return { ...value, isSave: !value.isSave };
      }
      return value;
    });

    setUserPolls(updatedData);
    axios
      .get(SaveApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLike = (post_id) => {
    const updatedData = Clinicaldata.map((value) => {
      if (value.id === post_id) {
        return { ...value, isLike: !value.isLike };
      }
      return value;
    });

    setClinicalData(updatedData);

    axios
      .get(LikeApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFetchPost = () => {
    setClinicalLoading(true);
    axios
      .get(
        ClinicalStudiesUsers +
          user_id +
          "/" +
          login_id +
          "?page=" +
          JSON.stringify(page + 1)
      )
      .then((res) => {
        if (res.data.status) {
          setClinicalData(res.data.data.data);
        } else {
          toast.error(res.data.action);
        }
        setClinicalLoading(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    handleFetchPost();
  }, []);

  const handlePolls = () => {
    setClinicalLoading(true);
    axios
      .get(UserPolls + user_id + "/" + login_id)
      .then((res) => {
        if (res.data.status) {
          setUserPolls(res.data.data.data);
          console.log("polls", res.data.data.data);
        } else {
          toast.error(res.data.action);
        }
        setClinicalLoading(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    handlePolls();
  }, []);

  const handleLikepost = (post_id) => {
    const updatedData = postData.map((value) => {
      if (value.id === post_id) {
        return { ...value, isLike: !value.isLike };
      }
      return value;
    });

    setPostData(updatedData);

    axios
      .get(LikeApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLikepolls = (post_id) => {
    const updatedData = userPolls.map((value) => {
      if (value.id === post_id) {
        return { ...value, isLike: !value.isLike };
      }
      return value;
    });

    setUserPolls(updatedData);

    axios
      .get(LikeApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setSpin(true);
    axios
      .post(UsersPostsPhoto, {
        user_id: user_id,
        login_id: login_id,
        type: "post",
      })
      .then((res) => {
        // setData(res.data.data);
        setPostData(res.data.data.data);
        console.log("posts", res.data.data.data);
        setSpin(false);
      })
      .catch((error) => {
        console.error(error);
        setSpin(false);
      });
  }, []);

  const handleNewPageData = () => {
    if (!totalPages || page >= totalPages || isLoading) {
      return;
    }

    setIsLoading(true);

    axios
      .post(UsersPostsPhoto + "?page=" + JSON.stringify(page + 1), {
        user_id: user_id,
        login_id: login_id,
        type: "post",
      })
      .then((res) => {
        const updatedValue = res.data.data.data;

        if (updatedValue.length > 0) {
          setPostData(postData.concat(updatedValue));
          setPage(page + 1);
        } else {
          setTotalPages(null); // Reset totalPages if no more data
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleNewPageClinical = () => {
    if (
      !totalpagesClinical ||
      pageclinical >= totalpagesClinical ||
      isLoading
    ) {
      return;
    }

    setIsLoading(true);

    axios
      .get(
        ClinicalStudiesUsers +
          user_id +
          "/" +
          login_id +
          "?page=" +
          JSON.stringify(page + 1)
      )
      .then((res) => {
        const updatedValue = res.data.data.data;

        if (updatedValue.length > 0) {
          setClinicalData(Clinicaldata.concat(updatedValue));
          setPageClinical(pageclinical + 1);
        } else {
          setTotalPagesClinical(null); // Reset totalPages if no more data
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // Assuming you have a useEffect for initial data fetching
  useEffect(() => {
    // Fetch initial data
    axios
      .post(UsersPostsPhoto + "?page=1", {
        user_id: user_id,
        login_id: login_id,
        type: "post",
      })
      .then((res) => {
        const initialData = res.data.data.data;
        setPostData(initialData);
        setTotalPages(res.data.data.last_page);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        ClinicalStudiesUsers +
          user_id +
          "/" +
          login_id +
          "?page=" +
          JSON.stringify(page + 1)
      )
      .then((res) => {
        const initialData = res.data.data.data;
        setClinicalData(initialData);
        setTotalPagesClinical(res.data.data.last_page);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Container fluid className="bg-offwhite vh-100 overflowY-hidden">
      <Row>
        <NavBar />
      </Row>
      <Row className="bg-offwhite p-5">
        <Col
          lg={3}
          className="overflowY-auto scroll-hide vh-100 pb-15-rem pt-9-rem"
        >
          <div className="bg-white border br-12 jobs-side-shadow">
            <div className="p-3">
              <h1 className="fs-20 fs-md-16 roboto-bold">POSTS</h1>
            </div>

            <div
              className={`p-3 ps-4 border-top cursor ${tabs == 1 && "active"}`}
              onClick={() => {
                setTabs(1);
              }}
            >
              <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                Photos or Videos
              </h1>
            </div>
            <div
              className={`p-3 ps-4 border-top cursor ${tabs == 2 && "active"}`}
              onClick={() => {
                setTabs(2);
              }}
            >
              <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                Clinical cases
              </h1>
            </div>
            <div
              className={`p-3 ps-4 border-top cursor ${tabs == 3 && "active"}`}
              onClick={() => {
                setTabs(3);
              }}
            >
              <h1 className="fs-18 fs-md-16 m-0 roboto-medium"> Webinar</h1>
            </div>
            <div
              className={`p-3 ps-4 border-top cursor ${tabs == 4 && "active"}`}
              onClick={() => {
                setTabs(4);
              }}
            >
              <h1 className="fs-18 fs-md-16 m-0 roboto-medium">Surveys </h1>
            </div>
          </div>
        </Col>
        <Col
          lg={9}
          className="overflowY-auto vh-100 pb-15-rem pt-9-rem scroll-hide"
        >
          {tabs == 1 ? (
            <>
              {clinicalloading ? (
                <div className="mt-3 p-2">
                  <SpecSpinner />
                </div>
              ) : (
                <InfiniteScroll
                  pageStart={0}
                  loadMore={handleNewPageData}
                  hasMore={!!totalPages && page < totalPages}
                  loader={
                    <div className="w-100 text-center" key={0}>
                      <img src={SpinnerGif} alt="picture" width={70} />
                    </div>
                  }
                  useWindow={false}
                >
                  <div>
                    {postData.length > 0 ? (
                      postData.map((value) => (
                        <div className="container-fluid" key={value.id}>
                          <div className="row justify-content-center">
                            <div className="col-md-7">
                              <div
                                key={value.id}
                                className="bg-white br-6 my-3 py-3"
                                style={{
                                  border: "0.5px solid rgba(0,0,0,0.25)",
                                }}
                              >
                                {value.repost_user.image &&
                                  value.repost_user.name && (
                                    <>
                                      <Row>
                                        <Col className="d-flex">
                                          <div
                                            className="bg-white br-14 dropdown-avatar-shadow ms-3"
                                            style={{
                                              maxWidth: "2.5rem",
                                              maxHeight: "2.5rem",
                                              padding: "0.8px",
                                            }}
                                          >
                                            <img
                                              src={
                                                BaseURL +
                                                value.repost_user.image
                                              }
                                              alt="picture"
                                              className="br-12 img-fluid"
                                            />
                                          </div>
                                          <div className="align-self-center">
                                            <h6 className="mx-3 mb-0">
                                              {value.repost_user.name} reposted
                                              this
                                            </h6>
                                          </div>
                                        </Col>
                                      </Row>
                                      <hr style={{ opacity: "0.1px" }} />
                                    </>
                                  )}
                                <Row className="justify-content-between px-3 position-relative">
                                  <Col md={10} className="d-flex gap-3">
                                    <div
                                      className="bg-white p-1 dropdown-avatar-shadow br-16 align-self-center"
                                      style={{
                                        maxHeight: "4rem",
                                        maxWidth: "4rem",
                                      }}
                                    >
                                      <Link to={PROFILE + "/" + value.user.id}>
                                        <img
                                          src={
                                            value.user.image
                                              ? BaseURL + value.user.image
                                              : Avatar
                                          }
                                          alt="picture"
                                          className="br-16 img-fluid"
                                        />
                                      </Link>
                                    </div>
                                    <div className="align-self-end">
                                      <Link to={PROFILE + "/" + value.user.id}>
                                        <h1 className="fs-16 roboto-medium text-dark">
                                          {value.user.name}
                                        </h1>
                                      </Link>
                                      <span className="fs-12 roboto-regular">
                                        <UnixToRelative
                                          unixTimestamp={value?.time}
                                        />
                                      </span>
                                    </div>
                                  </Col>
                                  <Col md={1} className="align-self-center">
                                    <img
                                      onClick={() => {
                                        setPostDropDown(!postDropDown);
                                        setDropDownId(value.original_id);
                                      }}
                                      width={25}
                                      className="cursor"
                                      src={Dots}
                                      alt="picture"
                                    />
                                  </Col>
                                  {/* Post DropDown */}
                                  <div className="position-absolute w-25 end-0 mt-5">
                                    <Collapse
                                      isOpen={
                                        postDropDown &&
                                        value.original_id === dropDownId
                                          ? true
                                          : false
                                      }
                                      className="bg-white br-16 p-3 text-center"
                                      style={{
                                        boxShadow:
                                          "5px 5px 6px 6px rgba(0, 0, 0, 0.16)",
                                      }}
                                    >
                                      {value.user.id !== user_id && (
                                        <h6
                                          className="mb-0 text-red cursor"
                                          onClick={() => {
                                            setReportModal(true);
                                            setPostDropDown(false);
                                          }}
                                        >
                                          Report
                                        </h6>
                                      )}
                                      {value.user.id === login_id && (
                                        <>
                                          <h6
                                            onClick={() => {
                                              setPostDeleteModal(true);
                                              setPostDropDown(false);
                                            }}
                                            className="mb-0 text-red cursor"
                                          >
                                            Delete
                                          </h6>
                                          <h6
                                            className="mb-0 cursor mt-3"
                                            onClick={() => {
                                              setEditPostInput(
                                                value.caption
                                                  ? value.caption
                                                  : ""
                                              );
                                              setEditPostModal(true);
                                            }}
                                          >
                                            Edit
                                          </h6>
                                        </>
                                      )}
                                    </Collapse>
                                  </div>
                                  {/* End Post DropDown */}
                                </Row>
                                <Row className="pt-3 pb-2">
                                  <Col>
                                    {value.type === "video" ? (
                                      <>
                                        {value.media &&
                                          value.media.map((media) => (
                                            <VideoWithAspectRatio
                                              src={BaseURL + media}
                                              aspectRatio={value.sizes}
                                            />
                                          ))}
                                      </>
                                    ) : value.media &&
                                      value.media.length === 1 ? (
                                      value.media.map((value) => (
                                        <ImageWithAspectRatio
                                          height={555 / value.sizes}
                                          src={BaseURL + value}
                                          alt="..."
                                        />
                                      ))
                                    ) : (
                                      <Carousel
                                        renderArrowNext={(
                                          onClickHandler,
                                          hasNext,
                                          label
                                        ) =>
                                          hasNext && (
                                            <IoIosArrowDroprightCircle
                                              className="position-absolute m-auto top-0 bottom-0 end-0 me-3"
                                              size={30}
                                              color="white"
                                              onClick={onClickHandler}
                                            />
                                          )
                                        }
                                        renderArrowPrev={(
                                          onClickHandler,
                                          hasPrev,
                                          label
                                        ) =>
                                          hasPrev && (
                                            <IoIosArrowDropleftCircle
                                              className="position-absolute m-auto start-0 ms-3 top-0 bottom-0"
                                              size={30}
                                              style={{ zIndex: "1" }}
                                              color="white"
                                              onClick={onClickHandler}
                                            />
                                          )
                                        }
                                        autoPlay={false}
                                        infiniteLoop={false}
                                        showIndicators={true}
                                        showThumbs={false}
                                        showStatus={false}
                                      >
                                        {value.media &&
                                          value.media.map((data) => (
                                            <ImageWithAspectRatio
                                              height={555 / value.sizes}
                                              src={BaseURL + data}
                                              alt="..."
                                            />
                                          ))}
                                      </Carousel>
                                    )}
                                  </Col>
                                </Row>
                                {value?.caption && (
                                  <>
                                    <Row className="px-3 py-2">
                                      <Col>
                                        <p className="fs-16 roboto-regular">
                                          {value.caption}
                                        </p>
                                      </Col>
                                    </Row>
                                    <hr className="m-0" />
                                  </>
                                )}
                                {value.likes_count > 0 && (
                                  <>
                                    <Row className="ps-4 pe-3 py-2">
                                      <Col className="d-flex">
                                        {value.like_users_images &&
                                          value.like_users_images.map(
                                            (data) => (
                                              <div>
                                                <img
                                                  src={
                                                    data
                                                      ? BaseURL + data
                                                      : Avatar
                                                  }
                                                  width={30}
                                                  className="rounded-circle"
                                                  style={{
                                                    marginLeft: "-10px",
                                                  }}
                                                  alt="picture"
                                                />
                                              </div>
                                            )
                                          )}

                                        <p className="ps-2 fs-14 roboto-regular mb-0 align-self-center">
                                          {value.likes_count} other have liked.
                                        </p>
                                      </Col>
                                    </Row>
                                    <hr className="m-0" />
                                  </>
                                )}
                                <Row className="px-3 py-2 justify-content-between">
                                  <Col md={10} className="align-self-center">
                                    <Row>
                                      <Col className="d-flex gap-1">
                                        <div className="cursor">
                                          <img
                                            width={25}
                                            src={value.isLike ? Liked : Like}
                                            alt="picture"
                                            onClick={() =>
                                              handleLikepost(value.original_id)
                                            }
                                          />
                                        </div>
                                        <div className="align-self-end">
                                          <p className="mb-0">Like</p>
                                        </div>
                                      </Col>
                                      <Col
                                        className="d-flex gap-1 cursor"
                                        onClick={() => {
                                          setPostId(value.original_id);
                                          handleGetComments(value.original_id);
                                          setPostModal(true);
                                        }}
                                      >
                                        <div>
                                          <img
                                            width={25}
                                            src={Comment}
                                            alt="picture"
                                          />
                                        </div>
                                        <div className="align-self-end">
                                          <p className="mb-0">
                                            {value.comments_count > 0
                                              ? value.comments_count
                                              : ""}{" "}
                                            Comments
                                          </p>
                                        </div>
                                      </Col>
                                      <Col
                                        className="d-flex gap-2 cursor"
                                        onClick={() => {
                                          handleRepostPost(value.original_id);
                                        }}
                                      >
                                        <div>
                                          <img
                                            width={25}
                                            src={
                                              value.isShare
                                                ? ReshareActive
                                                : Reshare
                                            }
                                            alt="picture"
                                          />
                                        </div>
                                        <div className="align-self-end">
                                          <p
                                            className={`mb-0 ${
                                              value.isShare && "text-blue"
                                            }`}
                                          >
                                            {value.isShare
                                              ? "Reposted"
                                              : "Repost"}
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md={1} className="align-self-center">
                                    <img
                                      width={20}
                                      src={value.isSave ? Saved : Save}
                                      alt="picture"
                                      className="cursor"
                                      onClick={() =>
                                        handleSavePost(value.original_id)
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="container-fluid">
                        <div className="row justify-content-center">
                          <div className="col-md-7">
                            <div className="bg-white br-6 my-3 py-3 text-center">
                              <p>No post uploaded by this user</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </InfiniteScroll>
              )}
            </>
          ) : tabs == 2 ? (
            <>
              <>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={handleNewPageClinical}
                  hasMore={
                    !!totalpagesClinical && pageclinical < totalpagesClinical
                  }
                  loader={
                    <div className="w-100 text-center" key={0}>
                      <img src={SpinnerGif} alt="picture" width={70} />
                    </div>
                  }
                  useWindow={false}
                >
                  {Clinicaldata.length > 0 ? (
                    Clinicaldata.map((value) => (
                      <div className="container-fluid">
                        <div className="row justify-content-center">
                          <div className="col-md-8 ">
                            <div
                              key={value.id}
                              className="bg-white br-6 my-3 py-3"
                              style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                            >
                              {value.repost_user.image &&
                                value.repost_user.name && (
                                  <>
                                    <Row>
                                      <Col className="d-flex">
                                        <div
                                          className="bg-white br-14 dropdown-avatar-shadow ms-3"
                                          style={{
                                            maxWidth: "2.5rem",
                                            maxHeight: "2.5rem",
                                            padding: "0.8px",
                                          }}
                                        >
                                          <img
                                            src={
                                              BaseURL + value.repost_user.image
                                            }
                                            alt="picture"
                                            className="br-12 img-fluid"
                                          />
                                        </div>
                                        <div className="align-self-center">
                                          <h6 className="mx-3 mb-0">
                                            {value.repost_user.name} reposted
                                            this
                                          </h6>
                                        </div>
                                      </Col>
                                    </Row>
                                    <hr style={{ opacity: "0.1px" }} />
                                  </>
                                )}
                              <Row className="justify-content-between px-3">
                                <Col lg={10} className="d-flex gap-3">
                                  <div
                                    className="bg-white p-1 dropdown-avatar-shadow br-16 align-self-center"
                                    style={{
                                      maxHeight: "4rem",
                                      maxWidth: "4rem",
                                    }}
                                  >
                                    <img
                                      src={
                                        value?.user?.image
                                          ? BaseURL + value?.user?.image
                                          : Avatar
                                      }
                                      alt="picture"
                                      className="br-16 img-fluid"
                                    />
                                  </div>
                                  <div className="align-self-end">
                                    <h1 className="fs-16 roboto-medium">
                                      {value?.user?.name}
                                    </h1>
                                    <span className="fs-12 roboto-regular">
                                      <UnixToRelative
                                        unixTimestamp={value?.time}
                                      />
                                    </span>
                                  </div>
                                </Col>
                                <Col md={1} className="align-self-center">
                                  <img
                                    onClick={() => {
                                      setPostDropDown(!postDropDown);
                                      setDropDownId(value.original_id);
                                    }}
                                    width={25}
                                    className="cursor"
                                    src={Dots}
                                    alt="picture"
                                  />
                                </Col>
                                {/* Post DropDown */}
                                <div className="position-absolute w-25 end-0 mt-5">
                                  <Collapse
                                    isOpen={
                                      postDropDown &&
                                      value.original_id === dropDownId
                                        ? true
                                        : false
                                    }
                                    className="bg-white br-16 p-3 text-center"
                                    style={{
                                      boxShadow:
                                        "5px 5px 6px 6px rgba(0, 0, 0, 0.16)",
                                    }}
                                  >
                                    {value.user.id !== user_id && (
                                      <h6
                                        className="mb-0 text-red cursor"
                                        onClick={() => {
                                          setReportModal(true);
                                          setPostDropDown(false);
                                        }}
                                      >
                                        Report
                                      </h6>
                                    )}
                                    {value.user.id === user_id && (
                                      <>
                                        <h6
                                          onClick={() => {
                                            setPostDeleteModal(true);
                                            setPostDropDown(false);
                                          }}
                                          className="mb-0 text-red cursor"
                                        >
                                          Delete
                                        </h6>
                                        <h6 className="mb-0 cursor mt-3">
                                          Edit
                                        </h6>
                                      </>
                                    )}
                                  </Collapse>
                                </div>
                                {/* End Post DropDown */}
                              </Row>
                              <Row className="pt-3 pb-2">
                                <Col>
                                  {value?.media && value?.media.length === 1 ? (
                                    value?.media.map((data) => (
                                      <ImageWithAspectRatio
                                        height={555 / value?.sizes}
                                        src={BaseURL + data}
                                        alt="..."
                                      />
                                    ))
                                  ) : (
                                    <Carousel
                                      autoPlay={false}
                                      infiniteLoop={false}
                                      showArrows={false}
                                      showStatus={true}
                                      showIndicators={true}
                                      showThumbs={false}
                                    >
                                      {value?.media &&
                                        value?.media.map((data) => (
                                          <ImageWithAspectRatio
                                            height={555 / value?.sizes}
                                            src={BaseURL + data}
                                            alt="..."
                                          />
                                        ))}
                                    </Carousel>
                                  )}
                                </Col>
                              </Row>
                              {value?.caption || value?.title ? (
                                <>
                                  <Row className="px-3 py-2 justify-content-between">
                                    <Col>
                                      <h4>
                                        {value?.title && value.title.length > 26
                                          ? value.title.slice(0, 26) + "..."
                                          : value.title}
                                      </h4>
                                      <p className="fs-16 roboto-regular">
                                        {value?.caption &&
                                        value.caption.length > 100
                                          ? value.caption.slice(0, 100) + "..."
                                          : value.caption}
                                      </p>
                                    </Col>
                                    <Col className="text-end align-self-center">
                                      <button className="btn-blue border-0 fw-bold text-white px-4 py-2">
                                        View Case Study
                                      </button>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <></>
                              )}
                              {value?.likes_count > 0 && (
                                <>
                                  <Row className="ps-4 pe-3 py-2">
                                    <Col className="d-flex">
                                      {value?.like_users_images &&
                                        value?.like_users_images.map((data) => (
                                          <div>
                                            <img
                                              src={
                                                data ? BaseURL + data : Avatar
                                              }
                                              width={30}
                                              className="rounded-circle"
                                              style={{ marginLeft: "-10px" }}
                                              alt="picture"
                                            />
                                          </div>
                                        ))}

                                      <p className="ps-2 fs-14 roboto-regular mb-0 align-self-center">
                                        {value?.likes_count} other have liked.
                                      </p>
                                    </Col>
                                  </Row>
                                  <hr className="m-0" />
                                </>
                              )}
                              <Row className="px-3 py-2 justify-content-between">
                                <Col lg={8} className="align-self-center">
                                  <Row>
                                    <Col className="d-flex gap-2 cursor">
                                      <div>
                                        <img
                                          width={25}
                                          src={value.isLike ? Liked : Like}
                                          alt="picture"
                                          onClick={() => handleLike(value?.id)}
                                        />
                                      </div>
                                      <div className="align-self-end">
                                        <p className="mb-0">Like</p>
                                      </div>
                                    </Col>
                                    <Col
                                      className="d-flex gap-2"
                                      onClick={() => {
                                        setPostId(value.original_id);
                                        handleGetComments(value.original_id);
                                        setPostModal(true);
                                      }}
                                    >
                                      <div>
                                        <img
                                          width={25}
                                          src={Comment}
                                          alt="picture"
                                        />
                                      </div>
                                      <div className="align-self-end">
                                        <p className="mb-0">
                                          {value.comments_count > 0
                                            ? value.comments_count
                                            : ""}{" "}
                                          Comments
                                        </p>
                                      </div>
                                    </Col>
                                    <Col
                                      className="d-flex gap-2 cursor"
                                      onClick={() => {
                                        handleRepost(value.original_id);
                                      }}
                                    >
                                      <div>
                                        <img
                                          width={25}
                                          src={
                                            value.isShare
                                              ? ReshareActive
                                              : Reshare
                                          }
                                          alt="picture"
                                        />
                                      </div>
                                      <div className="align-self-end">
                                        <p
                                          className={`mb-0 ${
                                            value.isShare && "text-blue"
                                          }`}
                                        >
                                          {value.isShare
                                            ? "Reposted"
                                            : "Repost"}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={1} className="align-self-center">
                                  <img
                                    width={20}
                                    src={value?.isSave ? Saved : Save}
                                    alt="picture"
                                    className="cursor"
                                    onClick={() => handleSave(value?.id)}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="container-fluid">
                      <div className="row justify-content-center">
                        <div className="col-md-8">
                          <div className="bg-white br-6 my-3 py-3 text-center">
                            <p>No Clinical data added by this user</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </InfiniteScroll>
              </>
            </>
          ) : tabs == 3 ? (
            <>
              <Container fluid className="job-scroll scroll-hide">
                <Row
                  style={{ marginTop: "1rem" }}
                  className="justify-content-center"
                >
                  <Col md={8} className="bg-white br-16">
                    <Row className="justify-content-center">
                      <Col md={12} className="text-center">
                        <h5 className="my-3">Webinars</h5>
                      </Col>
                      {loading ? (
                        <Spinner className="my-3" />
                      ) : (
                        <>
                          {data.length > 0 ? (
                            data.map((value) => (
                              <div
                                key={value.id}
                                className="bg-white br-6 my-3 py-3"
                                style={{
                                  border: "0.5px solid rgba(0,0,0,0.25)",
                                  maxWidth: "579px",
                                }}
                              >
                                <Row className="justify-content-between px-3 position-relative">
                                  <Col md={10} className="d-flex gap-3">
                                    <div
                                      className="bg-white p-1 dropdown-avatar-shadow br-16 align-self-center"
                                      style={{
                                        maxHeight: "4rem",
                                        maxWidth: "4rem",
                                      }}
                                    >
                                      <Link to={PROFILE + "/" + value.user.id}>
                                        <img
                                          src={
                                            value.user.image
                                              ? BaseURL + value.user.image
                                              : Avatar
                                          }
                                          alt="picture"
                                          className="br-16 img-fluid"
                                        />
                                      </Link>
                                    </div>
                                    <div className="align-self-end ">
                                      <Link to={PROFILE + "/" + value.user.id}>
                                        <h1 className="fs-16 roboto-medium text-dark">
                                          {value?.user?.name}
                                        </h1>
                                      </Link>
                                      <span className="fs-12 roboto-regular">
                                        <UnixToRelative
                                          unixTimestamp={value?.time}
                                        />
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="pt-3 pb-2">
                                  <Col>
                                    {value.media.map((value) => (
                                      <ImageWithAspectRatio
                                        height={555 / value.sizes}
                                        src={BaseURL + value}
                                        alt="..."
                                      />
                                    ))}
                                  </Col>
                                </Row>
                                {value?.caption || value?.title ? (
                                  <>
                                    <Row className="px-3 py-2">
                                      <Col>
                                        <h4>
                                          {value?.title &&
                                          value.title.length > 26
                                            ? value.title.slice(0, 26) + "..."
                                            : value.title}
                                        </h4>
                                        <p className="fs-16 roboto-regular">
                                          {value?.caption &&
                                          value.caption.length > 100
                                            ? value.caption.slice(0, 100) +
                                              "..."
                                            : value.caption}
                                        </p>
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <Row className="px-3 justify-content-between">
                                  <Col className="align-self-center">
                                    <p className="mb-0 text-warning">
                                      Scheduled on
                                    </p>
                                    <small className="mb-0">
                                      {value?.scheduled}
                                    </small>
                                  </Col>
                                  <Col className="text-end align-self-center">
                                    <button
                                      className="btn btn-warning fw-bold text-white px-4 py-2"
                                      onClick={
                                        value.user.id === user_id
                                          ? value.status === 0
                                            ? null
                                            : value.status === 2
                                            ? lang?.webinar_ended
                                            : () => {
                                                sessionStorage.setItem(
                                                  "webinar",
                                                  JSON.stringify({
                                                    user_id: value.user.id,
                                                    title: value.title,
                                                    post_id: value.id,
                                                  })
                                                );
                                                navigate(WEBINAR);
                                              }
                                          : value.status === 0
                                          ? value.isReserve
                                            ? () =>
                                                handleReserveWebinar(value.id)
                                            : () =>
                                                handleReserveWebinar(value.id)
                                          : value.status === 1
                                          ? value.isReserve
                                            ? () => {
                                                sessionStorage.setItem(
                                                  "webinar",
                                                  JSON.stringify({
                                                    user_id: value.user.id,
                                                    title: value.title,
                                                    post_id: value.id,
                                                  })
                                                );
                                                navigate(WEBINAR);
                                              }
                                            : () =>
                                                handleReserveWebinar(value.id)
                                          : () => alert(lang?.webinar_is_ended)
                                      }
                                    >
                                      {value.user.id === user_id
                                        ? value.status === 0
                                          ? null
                                          : value.status === 2
                                          ? lang?.webinar_ended
                                          : lang?.start_webinar
                                        : value.status === 0
                                        ? value.isReserve
                                          ? lang?.unreserve
                                          : lang?.reserve_seat
                                        : value.status === 1
                                        ? value.isReserve
                                          ? lang?.join_webinar
                                          : lang?.reserve_seat
                                        : lang?.webinar_ended}
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            ))
                          ) : (
                            <div className="container-fluid">
                              <div className="row justify-content-center">
                                <div className="col-md-7">
                                  <div className="bg-white br-6 my-3 py-3 text-center">
                                    <p>{lang?.no_webinar_available}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            tabs == 4 && (
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    {userPolls.length > 0 ? (
                      userPolls.map((value) => (
                        <div
                          className="bg-white br-6 my-3 py-3"
                          style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                        >
                          {value.repost_user.image &&
                            value.repost_user.name && (
                              <>
                                <Row>
                                  <Col className="d-flex">
                                    <div
                                      className="bg-white br-14 dropdown-avatar-shadow ms-3"
                                      style={{
                                        maxWidth: "2.5rem",
                                        maxHeight: "2.5rem",
                                        padding: "0.8px",
                                      }}
                                    >
                                      <img
                                        src={BaseURL + value.repost_user.image}
                                        alt="picture"
                                        className="br-12 img-fluid"
                                      />
                                    </div>
                                    <div className="align-self-center">
                                      <h6 className="mx-3 mb-0">
                                        {value.repost_user.name}{" "}
                                        {lang?.reposted_this}
                                      </h6>
                                    </div>
                                  </Col>
                                </Row>
                                <hr style={{ opacity: "0.1px" }} />
                              </>
                            )}
                          <Row className="justify-content-between px-3">
                            <Col md={10} className="d-flex gap-3">
                              <div
                                className="bg-white p-1 dropdown-avatar-shadow br-16 align-self-center"
                                style={{
                                  maxHeight: "4rem",
                                  maxWidth: "4rem",
                                }}
                              >
                                <img
                                  src={
                                    value?.user?.image
                                      ? BaseURL + value?.user?.image
                                      : Avatar
                                  }
                                  alt="picture"
                                  className="br-16 img-fluid"
                                />
                              </div>
                              <div className="align-self-end">
                                <h1 className="fs-16 roboto-medium">
                                  {value?.user?.name}
                                </h1>
                                <span className="fs-12 roboto-regular">
                                  <UnixToRelative unixTimestamp={value?.time} />
                                </span>
                              </div>
                            </Col>
                            <Col md={1} className="align-self-center">
                              <img
                                onClick={() => {
                                  setPostDropDown(!postDropDown);
                                  setDropDownId(value.original_id);
                                }}
                                width={25}
                                className="cursor"
                                src={Dots}
                                alt="picture"
                              />
                            </Col>
                            {/* Post DropDown */}
                            <div className="position-absolute w-25 end-0 mt-5">
                              <Collapse
                                isOpen={
                                  postDropDown &&
                                  value.original_id === dropDownId
                                    ? true
                                    : false
                                }
                                className="bg-white br-16 p-3 text-center"
                                style={{
                                  boxShadow:
                                    "5px 5px 6px 6px rgba(0, 0, 0, 0.16)",
                                }}
                              >
                                {value.user.id !== user_id && (
                                  <h6
                                    className="mb-0 text-red cursor"
                                    onClick={() => {
                                      setReportModal(true);
                                      setPostDropDown(false);
                                    }}
                                  >
                                    {lang?.report}
                                  </h6>
                                )}
                                {value.user.id === user_id && (
                                  <>
                                    <h6
                                      onClick={() => {
                                        setPostDeleteModal(true);
                                        setPostDropDown(false);
                                      }}
                                      className="mb-0 text-red cursor"
                                    >
                                      {lang?.delete}
                                    </h6>
                                    <h6 className="mb-0 cursor mt-3">
                                      {lang?.edit}
                                    </h6>
                                  </>
                                )}
                              </Collapse>
                            </div>
                            {/* End Post DropDown */}
                          </Row>
                          <Row className="pt-3 pb-2">
                            <Col>
                              {value?.media &&
                                value?.media.map((data) => (
                                  <ImageWithAspectRatio
                                    height={555 / data?.sizes}
                                    src={BaseURL + data}
                                    alt="..."
                                  />
                                ))}
                            </Col>
                          </Row>
                          {value?.title && (
                            <>
                              <Row className="px-3 py-2">
                                <Col>
                                  <p className="fs-20 fs-md-16 roboto-medium">
                                    {value?.title}
                                  </p>
                                </Col>
                              </Row>
                              <hr className="m-0" />
                            </>
                          )}

                          {[
                            value?.option_1,
                            value?.option_2,
                            value?.option_3,
                            value?.option_4,
                          ].map((data, index) => (
                            <CheckPoll
                              id={index}
                              title={data}
                              percentage={value.answer_percentage[index]}
                            />
                          ))}
                          <hr />

                          {value?.likes_count > 0 && (
                            <>
                              <Row className="ps-4 pe-3 py-2">
                                <Col className="d-flex">
                                  {value?.like_users_images &&
                                    value?.like_users_images.map((data) => (
                                      <div>
                                        <img
                                          src={data ? BaseURL + data : Avatar}
                                          width={30}
                                          className="rounded-circle"
                                          style={{ marginLeft: "-10px" }}
                                          alt="picture"
                                        />
                                      </div>
                                    ))}

                                  <p className="ps-2 fs-14 roboto-regular mb-0 align-self-center">
                                    {value?.likes_count}{" "}
                                    {lang?.other_have_liked}
                                  </p>
                                </Col>
                              </Row>
                              <hr className="m-0" />
                            </>
                          )}
                          <Row className="px-3 py-2 justify-content-between">
                            <Col md={8} className="align-self-center">
                              <Row>
                                <Col className="d-flex gap-2 cursor">
                                  <div>
                                    <img
                                      width={25}
                                      src={value.isLike ? Liked : Like}
                                      alt="picture"
                                      onClick={() => handleLikepolls(value.id)}
                                    />
                                  </div>
                                  <div className="align-self-end">
                                    <p className="mb-0">{lang?.like}</p>
                                  </div>
                                </Col>
                                <Col className="d-flex gap-2">
                                  <div>
                                    <img
                                      width={25}
                                      src={Comment}
                                      alt="picture"
                                    />
                                  </div>
                                  <div className="align-self-end">
                                    <p className="mb-0">
                                      {value.comments_count > 0
                                        ? value.comments_count
                                        : ""}{" "}
                                      {lang?.comments}
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  className="d-flex gap-2 cursor"
                                  onClick={() => {
                                    handleRepostPolls(value.original_id);
                                  }}
                                >
                                  <div>
                                    <img
                                      width={25}
                                      src={
                                        value.isShare ? ReshareActive : Reshare
                                      }
                                      alt="picture"
                                    />
                                  </div>
                                  <div className="align-self-end">
                                    <p
                                      className={`mb-0 ${
                                        value.isShare && "text-blue"
                                      }`}
                                    >
                                      {value.isShare
                                        ? lang?.reposted
                                        : lang?.repost}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={1} className="align-self-center">
                              <img
                                width={20}
                                src={value?.isSave ? Saved : Save}
                                alt="picture"
                                className="cursor"
                                onClick={() => handleSavePolls(value?.id)}
                              />
                            </Col>
                          </Row>
                        </div>
                      ))
                    ) : (
                      <div className="container-fluid">
                        <div className="row justify-content-center">
                          <div className="col-md-7">
                            <div className="bg-white br-6 my-3 py-3 text-center">
                              <p>{lang?.no_pols_upload_user}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
        </Col>
      </Row>

      {/* Post Modal */}
      <Offcanvas
        direction="end"
        scrollable
        isOpen={postModal}
        zIndex={9}
        style={{ width: "30%" }}
        toggle={() => {
          setPostModal(false);
        }}
      >
        <OffcanvasHeader
          className="bg-grey"
          toggle={() => {
            setPostModal(false);
          }}
        >
          {lang?.comments}
        </OffcanvasHeader>
        <hr className="m-0" style={{ opacity: "0.1" }} />
        <OffcanvasBody
          className="bg-grey scroll-hide"
          style={{ paddingBottom: "5rem" }}
        >
          {isLoading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div>
                {postComments?.data &&
                  postComments.data.map((content, index) => (
                    <div className="bg-white br-12 p-2 my-3" key={index}>
                      <div className="d-flex gap-3">
                        {/* Profile Image */}
                        <div>
                          <div
                            className="cursor bg-white br-16 dropdown-avatar-shadow"
                            style={{
                              maxWidth: "3rem",
                              maxHeight: "3rem",
                              padding: "0.8px",
                            }}
                          >
                            <img
                              src={
                                content.user.image
                                  ? BaseURL + content.user.image
                                  : Avatar
                              }
                              className="br-14 img-fluid"
                              alt="picture"
                            />
                          </div>
                        </div>
                        {/* Div End Profile Image */}
                        {/* Profile Detials */}
                        <div>
                          <p className="roboto-bold fs-16 mb-0 cursor">
                            {content.user.name}
                          </p>
                          <p className="roboto-regular fs-14 text-gray">
                            {content.comment}
                          </p>
                          <div className="d-flex gap-4">
                            <p className="roboto-medium fs-12">
                              {<TimestampConverter timestamp={content.time} />}
                            </p>
                            <p
                              className="roboto-medium fs-12 cursor text-blue"
                              onClick={() => {
                                setIsReply(true);
                                setReplyUser(content.user);
                                setCommentId(JSON.stringify(content.id));
                              }}
                            >
                              {lang?.reply}
                            </p>
                            <p
                              className="roboto-medium fs-12 cursor text-red"
                              onClick={() => {
                                setCommentId(JSON.stringify(content.id));
                                setDeleteModal(true);
                              }}
                            >
                              {lang?.delete}
                            </p>
                          </div>
                        </div>
                        {/* Div ended Profile Detials */}
                      </div>

                      {content.replies > 0 && (
                        <div>
                          <p
                            className="fw-bold text-gray fs-12 text-end me-3 cursor mb-0"
                            onClick={() => {
                              if (content.id === commentId) {
                                setCommentId("");
                                setCommentReplies([]);
                              } else {
                                setCommentId(content.id);
                                handleGetReplies(content.id);
                              }
                            }}
                          >
                            {content.id === commentId ? lang?.hide : lang?.view}{" "}
                            {content.replies}{" "}
                            {content.replies > 1 ? lang?.replies : lang?.reply}
                          </p>

                          {content.id === commentId &&
                            commentReplies.map((val) => {
                              return (
                                <>
                                  <div>
                                    <Collapse horizontal isOpen={true}>
                                      <div
                                        className="pt-3 ms-5 mt-4"
                                        key={val.id}
                                      >
                                        <div className="d-flex gap-3">
                                          {/* Profile Image */}
                                          <div>
                                            <div
                                              className="cursor bg-white br-16 dropdown-avatar-shadow"
                                              style={{
                                                maxWidth: "3rem",
                                                maxHeight: "3rem",
                                                padding: "0.8px",
                                              }}
                                            >
                                              <img
                                                src={
                                                  val.user.image
                                                    ? BaseURL + val.user.image
                                                    : Avatar
                                                }
                                                className="br-14 img-fluid"
                                                alt="picture"
                                              />
                                            </div>
                                          </div>
                                          {/* Div End Profile Image */}
                                          {/* Profile Detials */}
                                          <div>
                                            <p className="roboto-bold fs-16 mb-0 cursor">
                                              {val.user.name}
                                            </p>
                                            <p className="roboto-regular fs-14 text-gray">
                                              {val.comment}
                                            </p>
                                            <div className="d-flex gap-4">
                                              <p className="roboto-medium fs-12">
                                                {
                                                  <TimestampConverter
                                                    timestamp={val.time}
                                                  />
                                                }
                                              </p>
                                              <p
                                                className="roboto-medium fs-12 cursor text-blue"
                                                onClick={() => {
                                                  setIsReply(true);
                                                  setReplyUser(val.user);
                                                  setCommentId(
                                                    JSON.stringify(content.id)
                                                  );
                                                }}
                                              >
                                                {lang?.reply}
                                              </p>
                                              <p
                                                className="roboto-medium fs-12 cursor text-red"
                                                onClick={() => {
                                                  setCommentId(
                                                    JSON.stringify(val.id)
                                                  );
                                                  setDeleteModal(true);
                                                }}
                                              >
                                                {lang?.delete}
                                              </p>
                                            </div>
                                          </div>
                                          {/* Div ended Profile Detials */}
                                        </div>
                                      </div>
                                    </Collapse>
                                    <hr
                                      className="ms-5"
                                      style={{ opacity: "0.1" }}
                                    />
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  ))}
              </div>

              {/* if NO comments available */}
              {postComments.data && postComments.data.length === 0 && (
                <div className="text-center">
                  <h3 className="text-gray">{lang?.there_no_comment}</h3>
                  <p className="text-gray">{lang?.no_one_commented_post}</p>
                </div>
              )}
            </>
          )}

          {/* Comment input section */}
          <div className="position-absolute bottom-0 m-auto start-0 end-0">
            {isReply && (
              <Row>
                <Col
                  className="bg-blue br-12 d-flex justify-content-between"
                  style={{ marginLeft: "1.3rem", marginRight: "6.3rem" }}
                >
                  <h6 className="text-white mb-0 py-2">
                    {lang?.replying_to} {replyUser.name}
                  </h6>
                  <div
                    className="align-self-center cursor"
                    onClick={() => setIsReply(false)}
                  >
                    <MdOutlineCancel color="white" size={20} />
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="d-flex">
                <input
                  type="text"
                  placeholder={lang?.write_your_coment}
                  className="form-control m-2 py-3"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <div className="align-self-center">
                  <button
                    type="button"
                    disabled={comment === "" ? true : false}
                    className="btn-blue border-0 ms-1 me-2 py-3 px-4"
                    onClick={() => {
                      isReply
                        ? handleAddReplies(commentId)
                        : handleAddComment();
                    }}
                  >
                    <BsSendFill size={20} />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          {/* Comment input section */}
        </OffcanvasBody>
      </Offcanvas>

      <Modal isOpen={deleteModal} centered zIndex={9}>
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_comment}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_comment_able_comment}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeleteComment}
            >
              {lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={reportModal} centered zIndex={9}>
        <ModalHeader
          toggle={() => {
            setReportModal(false);
          }}
        >
          {lang?.report_post}
        </ModalHeader>
        <ModalBody>
          <h6>{lang?.modal_enter_report_para}</h6>

          <textarea
            rows="7"
            className="form-control"
            placeholder={lang?.enter_your_report}
            onChange={(e) => setReportInput(e.target.value)}
          ></textarea>

          <button
            className="btn-blue w-100 border-0 rounded py-3 my-3"
            onClick={() => {
              handlePostReport();
            }}
          >
            {isLoading ? <Spinner size="sm" /> : lang?.submit_report}
          </button>
        </ModalBody>
      </Modal>

      <Modal isOpen={editPostModal} centered zIndex={9}>
        <ModalHeader
          toggle={() => {
            setEditPostModal(false);
          }}
        >
          {lang?.edit_post}
        </ModalHeader>
        <ModalBody>
          <h6>{lang?.what_your_mind}</h6>
          <textarea
            rows="7"
            className="form-control my-3"
            value={editPostInput}
            onChange={(e) => {
              setEditPostInput(e.target.value);
            }}
          ></textarea>
          <button
            className="btn-blue border-0 py-3 w-100"
            disabled={!editPostInput}
            onClick={handleEditPost}
          >
            {isLoading ? <Spinner size="sm" /> : lang?.update}
          </button>
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default ProfilePost;

function TimestampConverter({ timestamp }) {
  const dt = new Date(timestamp * 1000); // Convert to milliseconds

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
  };

  const formattedDate = dt.toLocaleString("en-US", options);

  return <div>{formattedDate}</div>;
}

function CheckPoll(props) {
  return (
    <Row className="mx-3 my-3" key={props?.id}>
      {props.title !== "" && (
        <Col
          className="py-2 px-4"
          style={{
            border: "1px solid #00c5de",
            borderRadius: "10px",
            background: "rgba(0, 197, 222,0.1)",
          }}
        >
          <Row className="justify-content-between">
            <Col>
              <h5>{props?.title}</h5>
            </Col>
            <Col className="d-flex gap-2 justify-content-end">
              <label className="form-check-label">{props?.percentage} %</label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="poll"
                  id="poll"
                />
              </div>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
}
