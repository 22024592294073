import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import login_top_img from "../../assets/images/auth/login_top_img.svg";
import login_bottom_img from "../../assets/images/auth/login_bottom_img.svg";
import email_icon from "../../assets/images/auth/email_A.svg";
import { LOGIN, OTP_VERIFICATION } from "../../constants/RoutesUrl";
import { ForgotPasswordApi } from "../../constants/API";
import axios from "axios";
import { toast } from "react-toastify";
import { Language } from "../../App";

const ForgotPassword = () => {
  const { lang } = useContext(Language);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Email Validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(email)) {
      toast.error(lang?.please_enter_valid_email);
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await axios.post(ForgotPasswordApi, { email });

      if (data.status) {
        sessionStorage.setItem("ForgotPasswordEmail", email);
        navigate(OTP_VERIFICATION);
      } else {
        toast.error(data.action);
      }
    } catch (error) {
      console.error("Error initiating password recovery", error);
      toast.error(lang?.an_error_ocured_please_try_again);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="d-flex justify-content-center align-items-center py-2 min-vh-100 login-bg bg-auth">
      <div className="container">
        <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto">
          <div className="position-relative">
            <img
              src={login_top_img}
              className="position-absolute"
              alt="loginTop"
            />
            <img
              src={login_bottom_img}
              className="position-absolute"
              alt="loginBottom"
            />
            <div className="card position-relative border-0">
              <div className="card-body px-4 py-4">
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <h5 className="text-normal-black">
                      {lang?.forgot_Password}!
                    </h5>
                    <p className="text-normal-black">
                      {lang?.forgetpassword_desc}
                    </p>
                  </div>

                  <div className="mb-3 position-relative">
                    <img
                      src={email_icon}
                      className="position-absolute"
                      alt="forgot-email"
                    />
                    <input
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      style={{ paddingLeft: "40px" }}
                      placeholder={lang?.support_medicalradar_com}
                    />
                  </div>

                  <div className="mb-3">
                    <button
                      type="submit"
                      className="btn btn-login text-white fw-bold w-100"
                      disabled={!email}
                    >
                      {isLoading ? (
                        <div className="loader"></div>
                      ) : (
                        lang?.recover
                      )}
                    </button>
                  </div>
                  <p className="text-center text-normal-black">
                    {lang?.go_login_page}?{" "}
                    <Link
                      to={LOGIN}
                      className="text-decoration-none text-normal-primay"
                    >
                      {lang?.login}
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;
