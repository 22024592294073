// import React, { useContext, useEffect } from "react";
// import { Modal, ModalBody, ModalHeader } from "reactstrap";
// import { Language } from "../../App";
// import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
// import { Link } from "react-router-dom";
// import ImageComponent from "../common/ImageComponent";
// import { IoIosArrowForward } from "react-icons/io";
// import { PROFILE } from "../../constants/RoutesUrl";
// import { adminBaseURL } from "../../constants/API";
// import { getToken } from "../../shared/token";
// import axios from "axios";

// const GroupParticipantsModal = ({ isOpen, toggle , currentId}) => {
//   const { lang } = useContext(Language);
//   const getGroupParticipants = async () => {
//     try {
//       const response = await axios.get(
//         `${adminBaseURL}user/group-chat/conversation/users/${currentId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${getToken()}`,
//           },
//         }
//       );
//       console.log("response.data",response.data)
//       return response.data; // Return data as needed
//     } catch (error) {
//       console.error("Error fetching group chat users:", error);
//       throw error;
//     }
//   };
//   useEffect(()=>{
//     getGroupParticipants()
//   },[])
//   return (
//     <Modal isOpen={isOpen} centered toggle={toggle} size="lg">
//       <ModalHeader toggle={toggle}>{lang?.participants_list}</ModalHeader>
//       <ModalBody>
//         <div>
//           <div className="container-fluid">
//             <div className="row">
//               <div className="d-flex p-2 justify-content-between manue-hover rounded">
//                 <Link
//                   to={`${PROFILE}/1`}
//                   className="d-flex gap-2 text-dark w-100"
//                 >
//                   <div
//                     className="bg-white rounded-circle align-self-center"
//                     style={{
//                       padding: "2px",
//                       width: "48px",
//                       height: "48px",
//                       border: "1px solid #1DC1DD",
//                     }}
//                   >
//                     <ImageComponent
//                       src={Avatar}
//                       img_height="100%"
//                       img_width="100%"
//                       loader_height="100%"
//                       loader_width="100%"
//                       compClassName="rounded-circle"
//                       roundShimmerSize={48}
//                     />
//                   </div>

//                   <div className="align-self-center">
//                     <p className="fs-14 roboto-medium mb-0">
//                       John Doe{" "}
//                       <span
//                         className="roboto-medium fs-12 ms-2"
//                         style={{ color: "#1DC1DD" }}
//                       >
//                         (Admin)
//                       </span>
//                     </p>
//                     <p className="roboto-regular fs-10 mb-0">john_doe</p>
//                   </div>
//                 </Link>
//               </div>
//               <hr />
//             </div>
//           </div>
//         </div>
//       </ModalBody>
//     </Modal>
//   );
// };

// export default GroupParticipantsModal;

import React, { useContext, useEffect, useState, useCallback } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { Language } from "../../App";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Link } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";
import { PROFILE } from "../../constants/RoutesUrl";
import { _imagesURL, adminBaseURL } from "../../constants/API";
import { getToken } from "../../shared/token";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

const GroupParticipantsModal = ({ isOpen, toggle, currentId }) => {
  const { lang } = useContext(Language);
  const [participants, setParticipants] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getGroupParticipants = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${adminBaseURL}user/group-chat/conversation/users/${currentId}?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      const newParticipants = response?.data?.data?.data || []; // Handle potential undefined data

      setParticipants((prevParticipants) => [
        ...prevParticipants,
        ...newParticipants,
      ]);

      setHasMore(response.data.current_page < response.data.last_page);
    } catch (error) {
      console.error("Error fetching group chat users:", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentId, page]);

  useEffect(() => {
    if (isOpen) {
      getGroupParticipants();
    }
  }, [isOpen, getGroupParticipants]);

  const loadMore = () => setPage((prevPage) => prevPage + 1);
  const uniqueParticipants = (participants) => {
    const seen = new Map();
    return participants.filter((participant) => {
      if (!seen.has(participant.user_id)) {
        seen.set(participant.user_id, true);
        return true;
      }
      return false;
    });
  };
  const renderParticipants = (participants) =>
    uniqueParticipants(participants).map((participant) => (
      <div
        key={participant.user_id}
        className="d-flex p-2 justify-content-between manue-hover rounded"
      >
        <Link
          to={`${PROFILE}/${participant.user.id}`}
          className="d-flex gap-2 text-dark w-100"
        >
          <div
            className="bg-white rounded-circle align-self-center"
            style={{
              padding: "2px",
              width: "48px",
              height: "48px",
              border: "1px solid #1DC1DD",
            }}
          >
            <ImageComponent
              src={participant.user.image ? `${_imagesURL}${participant.user.image}` : Avatar}
              img_height="100%"
              img_width="100%"
              loader_height="100%"
              loader_width="100%"
              compClassName="rounded-circle"
              roundShimmerSize={48}
            />
          </div>

          <div className="align-self-center">
            <p className="fs-14 roboto-medium mb-0">
              {participant.user.name}{" "}
              {participant.admin === 1 && (
                <span
                  className="roboto-medium fs-12 ms-2"
                  style={{ color: "#1DC1DD" }}
                >
                  (Admin)
                </span>
              )}
            </p>
            <p className="roboto-regular fs-10 mb-0">
              {participant.user.user_name}
            </p>
          </div>
        </Link>
      </div>
    ));

  return (
    <Modal isOpen={isOpen} centered toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{lang?.participants_list}</ModalHeader>
      <ModalBody>
        <InfiniteScroll
          dataLength={participants.length}
          next={loadMore}
          hasMore={hasMore}
          loader={
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Spinner />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <div className="container-fluid">
            {renderParticipants(participants)}
          </div>
        </InfiniteScroll>
      </ModalBody>
    </Modal>
  );
};

export default GroupParticipantsModal;

