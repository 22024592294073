import { IoSearch } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { SEARCH } from "../../constants/RoutesUrl";
import { debounce } from "lodash";
import { useContext, useState, useEffect, useRef, useCallback } from "react";
import { Language } from "../../App";
import RecentSearchIcon from "../../assets/images/icons/recent-search.svg";
import SearchIcon from "../../assets/images/icons/search.svg";
import "./SearchInput.css";
import {
  getRecentSearchApi,
  getSearchAgainstKeywordApi,
} from "../../services/searchApis";
import { useQuery } from "react-query";

export default function SearchInput({topSearches=[],recentSearches=[]}) {
  const { lang } = useContext(Language);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [search, setSearch] = useState("");
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  
  const query = useLocation().search;
  const searchKeyword = decodeURIComponent(query.split("=")[1] || "");

  // const { data: recentSearch } = useQuery({
  //   queryKey: ["recent-search"],
  //   queryFn: () => getRecentSearchApi(),
  // });

  const { data: searchAgainstKeyword, isLoading } = useQuery({
    queryKey: ["search-against-keyword", search,inputValue],
    queryFn: () => getSearchAgainstKeywordApi(search),
    enabled: !!search, // Trigger only when `search` has a value
    refetchOnWindowFocus:false,
  });

  useEffect(() => {
    const keyword = searchKeyword !== "undefined" ? searchKeyword : "";
    setInputValue(keyword);
    setSearch(keyword); // Set both search and inputValue on mount
  }, [searchKeyword]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchClick = () => {
    if (inputValue) {
      setSearch(inputValue); // Update search state to trigger search query
      setIsActive(false);
      navigate(SEARCH + "?keyword=" + inputValue);
    }
  };

  const handleInputClick = () => {
    setIsActive(true);
  };

  const debouncedSetInputValue = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setInputValue(value); // Immediate update for input display
    debouncedSetInputValue(value); // Delayed update for search API
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue) {
      setSearch(inputValue); // Directly trigger search on submit
      setIsActive(false);
      navigate(`/search?keyword=${inputValue}`);
    }
  };
  return (
    <>
      {isActive && <div className="overlay" style={{ zIndex: "1" }}></div>}
      <div className="position-relative d-none-991 w-100" ref={inputRef}>
        <IoSearch
          className="position-absolute m-auto top-0 bottom-0 ms-lg-3 ms-md-2 ms-sm-2 ms-1 cursor"
          size={20}
          color="grey"
          onClick={handleSearchClick}
        />
        <form onSubmit={handleSubmit}>
          <input
            onClick={handleInputClick}
            type="text"
            placeholder={lang?.search_medical_radar}
            className={`search-input ${isActive ? "active" : ""}`}
            value={inputValue}
            onChange={handleSearchChange}
          />
        </form>
        {isActive && search === "" && (
          <div className="search-dropdown">
            {recentSearches?.length > 0 && (
              <div>
                <h6 className="px-3 pt-3 fs-14 roboto-medium text-muted">
                  {lang?.your_recent_searches}:
                </h6>
                <div>
                  {recentSearches
                    ?.slice(0, 5)
                    .flatMap((value, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center gap-2 cursor manue-hover px-3 py-2"
                        onClick={() => {
                          setInputValue(value);
                          setSearch(value);
                          setIsActive(false);
                          navigate(SEARCH + "?keyword=" + value);
                        }}
                      >
                        <img
                          src={RecentSearchIcon}
                          alt="recent-search"
                          width={20}
                        />
                        <h5 className="mb-0 fs-14 roboto-bold">{value}</h5>
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div>
              <h6 className="px-3 pt-3 fs-14 roboto-medium text-muted">
                {lang?.popular_searches}
              </h6>
              <div>
                {topSearches
                  ?.slice(0, 5)
                  .flatMap((value, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center gap-2 cursor px-3 py-2"
                      onClick={() => {
                        setInputValue(value);
                        setSearch(value);
                        setIsActive(false);
                        navigate(SEARCH + "?keyword=" + value);
                      }}
                    >
                      <img src={SearchIcon} alt="search" width={20} />
                      <h5 className="mb-0 fs-14 roboto-bold">{value}</h5>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        {isActive && search && (
          <div className="search-dropdown">
            <div>
              {isLoading ? (
                <div className="w-100 p-3">
                  <h5 className="mb-0 fs-14 roboto-bold text-center text-muted">
                    Searching...
                  </h5>
                </div>
              ) : searchAgainstKeyword?.data?.length > 0 ? (
                <>
                  <div className="border-bottom">
                    {searchAgainstKeyword?.data
                      ?.slice(0, 10)
                      .flatMap((value, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center gap-2 m-3 cursor"
                          onClick={() => {
                            setInputValue(value);
                            setSearch(value);
                            setIsActive(false);
                            navigate(SEARCH + "?keyword=" + value);
                          }}
                        >
                          <img src={SearchIcon} alt="search" width={20} />
                          <h5 className="mb-0 fs-14 roboto-bold">{value}</h5>
                        </div>
                      ))}
                  </div>
                  <div className="d-flex justify-content-center">
                    <h5
                      className="fs-14 roboto-bold cursor my-3 text-primary"
                      onClick={handleSearchClick}
                    >
                      {lang?.see_all_results}
                    </h5>
                  </div>
                </>
              ) : (
                <div className="w-100 p-3">
                  <h5 className="mb-0 fs-14 roboto-bold text-center text-muted">
                    No results found
                  </h5>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
