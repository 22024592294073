import { MdArrowForwardIos } from "react-icons/md";
import CourseCard from "./CourseCard";
import { ShimmerPostItem } from "react-shimmer-effects";
import { useNavigate } from "react-router-dom";

export default function FollowingDoctorCourses({
  followingDoctorCourses,
  loading,
}) {
  const navigate = useNavigate()
  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-3">
        <h4 className="mb-0 fs-20 roboto-bold">Following Doctor Courses</h4>
        <div className="d-flex align-items-center gap-2">
          <h5 className="mb-0 fs-16 roboto-bold cursor" onClick={()=>navigate("/courses/all/following-doctor")}>See all</h5>
          <MdArrowForwardIos size={20} />
        </div>
      </div>
      <div className="d-flex flex-wrap gap-3 justify-content-between">
        {loading
          ? [1, 2, 3, 4, 5, 6]?.map(() => (
              <div style={{ width: "280px" }}>
                <ShimmerPostItem imageHeight={200} card title />
              </div>
            ))
          : followingDoctorCourses?.map((course) => (
              <CourseCard key={course.id} course={course} />
            ))}
      </div>
    </>
  );
}
