import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import GoLiveIcon from "../../assets/images/icons/go-live-icon.svg";
import PollIcon from "../../assets/images/icons/poll-icon.svg";
import WebinarIcon from "../../assets/images/icons/webinar-icon.svg";
import ClinicalStudyIcon from "../../assets/images/icons/clinical-study-icon.svg";
import { _imagesURL, BaseURL } from "../../constants/API";
import { useNavigate } from "react-router-dom";
import { ADDPOST } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";
import { Language, LoginUserData } from "../../App";
import { RiEditLine } from "react-icons/ri";
import { CiCamera } from "react-icons/ci";
import { GoDeviceCameraVideo } from "react-icons/go";

const PostForm = () => {
  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);
  const Navigate = useNavigate();

  const handleNavigate = (postType) => {
    Navigate(ADDPOST + `/${postType}`);
  };

  return (
    <div
      className="bg-white feed-form-shadow rounded scroll-hide cursor"
      onClick={() => {
        handleNavigate(1);
      }}
      style={{
        border: "0.5px solid rgba(0,0,0,0.25)",
        // height: "173px",
        overflowX:"auto",
        width: "570px",
        whiteSpace:"nowrap"
      }}
    >
      <div className="d-flex">
        <div className="d-flex align-items-center gap-2 mx-3">
          <RiEditLine size={16} />{" "}
          <p className="roboto-medium fs-14 mb-0">Publish</p>
        </div>
        <div
          className="d-flex w-100"
          style={{
            borderBottom: "1px solid rgba(29, 193, 221, 0.2)",
            borderLeft: "1px solid rgba(29, 193, 221, 0.2)",
          }}
        >
          {pages.map((val, i) => (
            <div className="d-flex align-items-center gap-2 p-3" key={i}>
              <img src={val.src} width={16} height={16} alt="icon" />
              <p
                className="roboto-regular fs-14 mb-0"
                style={{ color: "#848798" }}
              >
                {val.label}
              </p>
            </div>
          ))}
        </div>
      </div>
      <Row className="py-2 px-3">
        <Col className="d-flex">
          <div>
            <div
              className="rounded-circle"
              style={{
                border: "1px solid #1DC1DD",
                maxWidth: "48px",
                maxHeight: "48px",
                padding: "1px",
              }}
            >
              <ImageComponent
                src={
                  splashData && splashData.user && splashData.user.image !== ""
                    ? _imagesURL + splashData.user.image
                    : Avatar
                }
                compClassName="rounded-circle"
                loader_height="44px"
                loader_width="44px"
                img_height="44px"
                img_width="44px"
                roundShimmerSize={44}
              />
            </div>
          </div>
          <div className="w-100 align-self-center">
            <textarea
              className="form-control border-0 cursor fs-md-14"
              placeholder={lang?.post_form_placeholder}
              rows="1"
            ></textarea>
          </div>
        </Col>
      </Row>
      <div
        className="d-flex align-items-center"
        style={{ borderTop: "1px solid rgba(29, 193, 221, 0.2)" }}
      >
        <button
          className="d-flex align-items-center gap-2 rounded-pill border-0 px-3 py-1 m-2"
          style={{ backgroundColor: "rgba(247, 247, 247, 1)" }}
        >
          <CiCamera size={16} color="rgba(132, 135, 152, 1)" />{" "}
          <p
            className="roboto-regular fs-14 mb-0"
            style={{ color: "rgba(132, 135, 152, 1)" }}
          >
            Images
          </p>
        </button>
        <button
          className="d-flex align-items-center gap-2 rounded-pill border-0 px-3 py-1 mx-2"
          style={{ backgroundColor: "rgba(247, 247, 247, 1)" }}
        >
          <GoDeviceCameraVideo size={16} color="rgba(132, 135, 152, 1)" />{" "}
          <p
            className="roboto-regular fs-14 mb-0"
            style={{ color: "rgba(132, 135, 152, 1)" }}
          >
            Videos
          </p>
        </button>
      </div>
    </div>
  );
};

export default PostForm;

const pages = [
  { src: PollIcon, label: "Poll", type: 2 },
  { src: WebinarIcon, label: "Webinar", type: 3 },
  { src: ClinicalStudyIcon, label: "Clinical Study", type: 4 },
  { src: GoLiveIcon, label: "Go Live", type: 5 },
];
