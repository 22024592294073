import { Col, Row } from "reactstrap";
import { BaseURL, RepostApi } from "../../constants/API";
import { PostCarousel } from "./PostsResultsComp";
import SearchedPostHeader from "./SearchedPostHeader";
import SearchResultCard from "./SearchResultCard";
import { useContext, useEffect, useState } from "react";
import { Language, LoginUserData } from "../../App";
import PostFooter from "../feeds/PostFooter";
import { ReadMore } from "../../pages/Feed";
import axios from "axios";
import { toast } from "react-toastify";
import { likePostApi, savePostApi } from "../../services/feedApis";
import { authorize } from "../../shared/authorize";

export default function ClinicalStudiesResults({ data, loading, keyword }) {
  return (
    <SearchResultCard
      title="Clinical Studies"
      link={`/search/clinicals?keyword=${keyword}`}
      seeAll={!loading && data?.length > 2}
    >
      <div>
        {data?.map((item) => (
          <ClinicalStudiesCard key={item.id} post={item} />
        ))}
      </div>
    </SearchResultCard>
  );
}

export const ClinicalStudiesCard = ({ post }) => {
  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);
  const [data, setData] = useState({});

  const handleRepost = async () => {
    try {
      setData({ ...data, isShare: !data.isShare });
      const response = await axios.get(
        `${RepostApi}${post?.id}`,authorize()
      );
      if (response.data.status) {
        toast.success(response.data.action);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSave = async () => {
    try {
      setData({ ...data, isSave: !data.isSave });
      const response = await savePostApi({post_id:data?.id});
      if (!response.data.status) {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleLike = async () => {
    try {
      const updatedData = {
        ...data,
        isLike: !data.isLike,
        likes_count: data.isLike ? data.likes_count - 1 : data.likes_count + 1,
      };
      setData(updatedData);
      await likePostApi({post_id:data.id});
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setData(post);
  }, [post]);
  return (
    <div className="border border-inherit p-2">
      <SearchedPostHeader postDetails={post} />
      <PostCarousel post={post} />
      <Row className="justify-content-between p-2">
        <Col className="align-self-center">
          <h4 className="mb-0">
            {post?.title && post.title.length > 26
              ? post.title.slice(0, 26) + "..."
              : post.title}
          </h4>
        </Col>
        {post?.pdf && (
          <Col className="text-end">
            <button
              className="border-0 px-3 py-1 text-white btn-blue"
              onClick={() => {
                window.open(BaseURL + post.pdf, "_blank");
              }}
            >
              {lang?.view_Case_Study}
            </button>
          </Col>
        )}
      </Row>
      {post?.caption && (
        <>
          <Row className="p-2">
            <Col>
              <ReadMore text={post.caption} />
            </Col>
          </Row>
          <hr className="m-0" />
        </>
      )}
      <PostFooter
        likes_count={post?.likes_count}
        setPostId={() => {}}
        original_id={post?.original_id}
        handleLikeUsers={() => {}}
        like_users_images={post?.like_users_images}
        handleLike={handleLike}
        isLike={post?.isLike}
        handleGetComments={() => {}}
        setPostModal={() => {}}
        comments_count={post?.comments_count}
        handleRepost={handleRepost}
        isShare={post?.isShare}
        isSave={post?.isSave}
        handleSave={handleSave}
      />
    </div>
  );
};
