import React, { useContext, useRef, useState } from "react";
import upload from "../../src/assets/images/icon/upload-icon.svg";
import Cropper from "../../src/cropper/components/CroppersWizard.tsx";
import close from "../../src/assets/images/icon/close-icon.svg";
import { PhotoProvider, PhotoView } from "react-photo-view";
import Modal from "react-bootstrap/Modal";
import "react-photo-view/dist/react-photo-view.css";
import axios from "axios";
import { adminBaseURL, BaseURL } from "../constants/API";
import { Col, Progress, Row } from "reactstrap";
import { FEED } from "../constants/RoutesUrl.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Language, LoginUserData } from "../App.js";
const UploadPost = (props) => {
  const Navigate = useNavigate();

  const { splashData } = useContext(LoginUserData);
  const user_id = splashData?.user?.id;
  const {data:userToken} = JSON.parse(localStorage.getItem("SignupData"));

  const maxNumber = 5;
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState(false);
  const [captionError, setCaptionError] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesArr, setImagesArr] = useState([]);
  const [fileUploadRefs, setFileUploadRefs] = useState({});
  const [numberOfImages, setNumberOfImages] = useState(
    Object.keys(images).length < maxNumber
      ? Object.keys(images).length
      : maxNumber
  );
  const [video, setVideo] = useState([]);
  const [progress, setProgress] = useState(0);
  const [caption, setCaption] = useState(null);
  const [source, setSource] = useState();
  const [showUploadStatus, setShowUploadStatus] = useState(false);
  const [ar, setAR] = useState(undefined);
  const [minARatio, setMinAR] = useState(undefined);
  const [maxARatio, setMaxAR] = useState(undefined);
  const [height, setHeight] = useState(500);
  const [width, setWidth] = useState(500);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [resizable, setResizable] = useState(true);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [updateCoordinatesCheck, setUpdateCoordinatesCheck] = useState(true);
  const [hasSettings, setHasSettings] = useState(true);
  const { lang } = useContext(Language);
  const inputRef = useRef();
  const updateCaption = (event) => {
    setCaption(event.target.value);
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const handleClose = () => {
    setModalShow(false);
  };
  const onImageRemove = (index) => {
    const updatedImages = [...imagesArr];
    updatedImages.splice(index, 1);
    setImagesArr(updatedImages);
    setImages(updatedImages);
    if (!updatedImages.length) {
      setHasSettings(true);
      setAR(undefined);
      setMinAR(undefined);
      setMaxAR(undefined);
      setWidth(500);
      setHeight(500);
      setTop(0);
      setLeft(0);
    }
  };
  const onVideoRemove = () => {
    setSource(null);
    setVideo([]);
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (!video.length > 0 && !imagesArr.length > 0) {
      toast.error("Media is required.");
      return;
    }

    try {
      setShowUploadStatus(true);

      const data = {
        mediaBase64: imagesArr,
        media: video,
        type: video.length > 0 ? "video" : "image",
        caption,
      };
      const response = await axios.post(
        adminBaseURL + "user/post/create" ,
        data,
        {
          onUploadProgress: (progressEvent) => {
            setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken?.token}`,
          },
        }
      );
      setShowUploadStatus(false);
      if (response.data.status) {
        toast.success(response.data.action);
        Navigate(FEED);
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      setShowUploadStatus(false);
      console.error("Error uploading:", error);
      toast.error(lang?.failed_upload_please_try_again_later);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <div className="modal-heading">
          <p>{lang?.choose_option}</p>
          <p>{lang?.what_you_want_upload}</p>
          <hr className="dividerr" />
          <>
            <input
              ref={inputRef}
              className="VideoInput_input"
              type="file"
              required
              onChange={(e) => {
                const url = URL.createObjectURL(e.target.files[0]);
                setSource(url);
                setVideo(e.target.files);
                handleClose();
              }}
              accept=".mov,.mp4"
            />
            {!imagesArr.length && (
              <button
                className="file-btn c-pointer"
                onClick={() => {
                  handleChoose();
                }}
                disabled={imagesArr.length > 0}
              >
                {lang?.choose_video_from_computer}
              </button>
            )}
            {!imagesArr.length && !source && <hr className="dividerr" />}
            {!source && (
              <>
                <div
                  role="button"
                  onClick={() => {
                    fileUploadRefs[numberOfImages - 1].current.click();
                    setError("");
                  }}
                  style={{ cursor: "pointer" }}
                  className="upload__image-wrapper"
                >
                  <button className="file-btn c-pointer">
                    {lang?.choose_image_from_computer}
                  </button>
                </div>
                <Cropper
                  images={images}
                  setImages={setImages}
                  imagesArr={imagesArr}
                  setImagesArr={setImagesArr}
                  removeSource={onVideoRemove}
                  max={maxNumber}
                  allowCrop={true}
                  ar={ar}
                  minARatio={minARatio}
                  maxARatio={maxARatio}
                  setAR={setAR}
                  setMaxAR={setMaxAR}
                  setMinAR={setMinAR}
                  resizable={resizable}
                  setResizable={setResizable}
                  height={height}
                  setHeight={setHeight}
                  width={width}
                  setWidth={setWidth}
                  top={top}
                  setTop={setTop}
                  left={left}
                  setLeft={setLeft}
                  hasSettings={hasSettings}
                  setHasSettings={setHasSettings}
                  updateCoordinatesCheck={updateCoordinatesCheck}
                  setUpdateCoordinatesCheck={setUpdateCoordinatesCheck}
                  handleClose={handleClose}
                  fileUploadRefs={fileUploadRefs}
                  setFileUploadRefs={setFileUploadRefs}
                  numberOfImages={numberOfImages}
                  setNumberOfImages={setNumberOfImages}
                  cropperOpen={cropperOpen}
                  setCropperOpen={setCropperOpen}
                />
              </>
            )}
          </>
          <hr className="dividerr" />
          <p className="cancel-btn c-pointer" onClick={handleClose}>
            {lang?.cancel}
          </p>
        </div>
      </Modal>

      <Col md={8} className="bg-white rounded p-3">
        <Row>
          <Col className="text-center">
            <h5>{lang?.create_post_sm}</h5>
          </Col>
        </Row>
        <div className="d-flex f-direction">
          <div className="d-flex content-overflow scroll-hide">
            <div
              className="upload-file  c-pointer"
              onClick={() => setModalShow(true)}
              onChange={(event) => setError("")}
            >
              <img src={upload} />
              <p>{lang?.choose_file_upload}</p>
            </div>
            <div className="d-flex p-relative">
              {Array(maxNumber)
                .fill()
                .map((_, index) => (
                  <>
                    {imagesArr[index] ? (
                      <>
                        <PhotoProvider
                          speed={() => 800}
                          easing={(type) =>
                            type === 2
                              ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                              : "cubic-bezier(0.34, 1.56, 0.64, 1)"
                          }
                        >
                          <PhotoView src={imagesArr[index]}>
                            <div
                              key={index}
                              className="uploaded-image c-pointer "
                            >
                              <img src={imagesArr[index]} alt="picture" />
                            </div>
                          </PhotoView>
                        </PhotoProvider>
                        <img
                          className="close c-pointer"
                          onClick={() => onImageRemove(index)}
                          src={close}
                          alt="Remove"
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}

              <div className="p-relative">
                {source && (
                  <>
                    <video className="post-video" controls src={source} />
                    <img
                      className="close-video c-pointer position-absolute"
                      onClick={onVideoRemove}
                      src={close}
                      alt="Remove"
                    />
                  </>
                )}
              </div>
            </div>
            {error === "" ? "" : <p className="postImg-error"> {error} </p>}
          </div>
        </div>
        <Row className="my-3">
          <Col lg={6}>
            <label className="mb-1">{lang?.what_in_your_mind}</label>
            <textarea
              className="form-control"
              placeholder={lang?.write_something}
              rows={7}
              onChange={(e) => {
                setCaptionError("");
                updateCaption(e);
              }}
              value={caption}
            />
            {!showUploadStatus && (
              <div className="mt-3">
                <button
                  className="btn-blue border-0 rounded w-100 py-3"
                  onClick={(event) => handleUpload(event)}
                >
                  {lang?.submit}
                </button>
              </div>
            )}
          </Col>
        </Row>
        <>
          {showUploadStatus && (
            <div className="upload-status">
              <p className="f-weight650">
                {lang?.upload_status} {progress}%
              </p>
              <p>{lang?.upload_status_para}</p>

              <div>
                <Progress animated color="primary" striped value={progress} />
              </div>
            </div>
          )}
        </>
      </Col>
    </>
  );
};
export default UploadPost;
