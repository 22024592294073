import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import show_eye from "../../assets/images/auth/show_eye.svg";
import hide_eye from "../../assets/images/auth/hide_eye.svg";
import { LOGIN } from "../../constants/RoutesUrl";
import axios from "axios";
import { adminBaseURL, BaseURL } from "../../constants/API";
import login_top_img from "../../assets/images/auth/login_top_img.svg";
import login_bottom_img from "../../assets/images/auth/login_bottom_img.svg";
import { CiLocationOn } from "react-icons/ci";
import email_icon from "../../assets/images/auth/login_email.svg";
import lock from "../../assets/images/auth/lock.svg";
import { MdEmail } from "react-icons/md";
import { FaHospitalAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { Language } from "../../App";

const HospitalSignup = ({ setAccountType }) => {
  const { lang } = useContext(Language);
  const navigate = useNavigate();
  const [type, setType] = useState(true);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const [formData, setFormData] = useState({
    account_type: "",
    hospital_full_name: "",
    hospital_username: "",
    email: "",
    password: "",
    country: "",
    city: "",
  });

  const [DisableBtn, setDisableBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ModalStatus, setModalStatus] = useState(false);

  // fetch countries from local storage
  useEffect(() => {
    const countriesData = sessionStorage.getItem("countriesData");
    if (countriesData) {
      setCountries(JSON.parse(countriesData));
    }
  }, []);

  // Function to handle form submission
  const HandleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (formData.account_type === "") {
      toast.error("Select account type.");
      setIsLoading(false);
      return;
    }

    // Email Validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (formData.email === "") {
      toast.error("Enter email address.");
      setIsLoading(false);
      return;
    }
    if (!emailRegex.test(formData.email)) {
      toast.error(lang?.please_enter_valid_email);
      setIsLoading(false);
      return;
    }

    // Password Validation rules
    if (formData.password === "") {
      toast.error("Enter password.");
      setIsLoading(false);
      return;
    }
    if (formData.password.length < 6) {
      toast.error(lang?.password_should_least_6char_long);
      setIsLoading(false);
      return;
    } else if (formData.password.length > 30) {
      toast.error(lang?.password_should_not_exced_30_char);
      setIsLoading(false);
      return;
    } else if (/\s/.test(formData.password)) {
      toast.error(lang?.space_not_allowed_pass);
      setIsLoading(false);
      return;
    }

    // UserName Validation Code
    const usernameRegex = /^[a-zA-Z0-9_]{0,30}$/;
    if (formData.hospital_username === "") {
      toast.error("Enter hospital username.");
      setIsLoading(false);
      return;
    }
    if (!usernameRegex.test(formData.hospital_username)) {
      toast.error(lang?.username_formate);
      setIsLoading(false);
      return;
    }

    // FullName Validation
    const fullNameRegex = /^[A-Z][a-zA-Z\s]{0,29}$/;

    if (formData.hospital_full_name === "") {
      toast.error("Enter hospital full name.");
      setIsLoading(false);
      return;
    }

    if (!fullNameRegex.test(formData.hospital_full_name)) {
      toast.error(lang?.fullname_formate);
      setIsLoading(false);
      return;
    }

    if (formData.country === "") {
      toast.error("Select country.");
      setIsLoading(false);
      return;
    }
    if (formData.city === "") {
      toast.error("Select country.");
      setIsLoading(false);
      return;
    }

    // const timeZone = sessionStorage.getItem("user_timezone");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    axios
      .post(adminBaseURL + "user/auth/register", {
        name: formData.hospital_full_name,
        user_name: formData.hospital_username,
        sub_type: formData.account_type,
        email: formData.email,
        password: formData.password,
        country: formData.country,
        city: formData.city,
        user_type: "hospital",
        device: "website",
        device_name: "web_abc",
        token: "web_abc",
        timezone: timeZone,
      })
      .then(function (response) {
        const status = response.data.status;
        if (status) {
          localStorage.setItem("SignupData", JSON.stringify(response.data));
          setIsLoading(false);
          setModalStatus(true);
        } else {
          toast.error(response.data.action);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const fetchCities = () => {
    const url = adminBaseURL + "app/country/" + formData.country;

    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setCities(data.data);
      })
      .catch((err) => {
        console.error("Fetch error:", err);
      });
  };

  return (
    <>
      <main className="overflow-scroll vh-100 py-2 login-bg lr-center2 bg-auth">
        <Modal isOpen={ModalStatus} zIndex={9} centered>
          <ModalBody className="bg-white rounded p-4">
            <div>
              <h2 className="text-blue fs-24 fs-md-16 roboto-bold">
                {lang?.your_request_submitted}{" "}
              </h2>
              <p className="fs-18 fs-md-16 roboto-regular">
                {lang?.your_request_submitted_desc1} <br />{" "}
                {lang?.your_request_submitted_desc2}
              </p>
            </div>
            <hr />
            <div className="text-end">
              <button
                className="btn-blue border-0 rounded px-md-3 w-lg-100 py-2"
                onClick={() => {
                  setModalStatus(false);
                  navigate(LOGIN);
                }}
              >
                OK
              </button>
            </div>
          </ModalBody>
        </Modal>
        <div className="container">
          <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto">
            <div className="position-relative">
              <img
                src={login_top_img}
                className="position-absolute"
                alt="loginTop"
              />
              <img
                src={login_bottom_img}
                className="position-absolute"
                alt="loginBottom"
              />
              <div className="card position-relative border-0">
                <div className="card-body px-4 py-4">
                  <div>
                    <h5 className="fs-md-16 text-normal-black">
                      {lang?.welcome_to} Medical Radar!
                    </h5>
                    <p className="text-normal-black m-0">
                      {lang?.please_sign_up_account}
                    </p>
                  </div>

                  <div className="my-3">
                    <form onSubmit={(e) => HandleSubmit(e)}>
                      <div className="mb-3">
                        <div className="position-relative">
                          <img
                            src={email_icon}
                            className="position-absolute"
                            alt="login-email"
                          />
                          <select
                            style={{ paddingLeft: "40px" }}
                            className="form-control"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                account_type: e.target.value,
                              })
                            }
                          >
                            <option value="">
                              {lang?.select_account_type}
                            </option>
                            <option value="Hospital">{lang?.hospital}</option>
                            <option value="Private Clinic">
                              {lang?.private_clinic}
                            </option>
                            <option value="Societies">{lang?.societies}</option>
                          </select>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="position-relative">
                          <FaHospitalAlt
                            className="position-absolute input-icon"
                            size={18}
                          />

                          <input
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                hospital_full_name: e.target.value,
                              });
                            }}
                            type="text"
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                            placeholder={lang?.enter_hospital_fullname}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="position-relative">
                          <FaHospitalAlt
                            className="position-absolute input-icon"
                            size={18}
                          />

                          <input
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                hospital_username: e.target.value,
                              });
                            }}
                            type="text"
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                            placeholder={lang?.enter_hospital_user_name}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="position-relative">
                          <MdEmail
                            className="position-absolute input-icon"
                            size={19}
                          />
                          <input
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              });
                            }}
                            type="text"
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                            placeholder={lang?.support_medicalradar_com}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="position-relative">
                          <img
                            src={lock}
                            className="position-absolute"
                            alt="login-email"
                          />
                          <input
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                password: e.target.value,
                              });
                            }}
                            type={type ? "password" : "text"}
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                            placeholder={lang?.enter_password}
                          />
                          <div>
                            <img
                              src={show_eye}
                              onClick={() => setType(!type)}
                              className={`position-absolute cursor ${
                                !type ? "d-none" : "d-block"
                              }`}
                              alt="login-show-eye"
                            />
                            <img
                              src={hide_eye}
                              onClick={() => setType(!type)}
                              className={`position-absolute cursor ${
                                type ? "d-none" : "d-block"
                              }`}
                              alt="login-show-eye"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="position-relative">
                          <CiLocationOn
                            className="position-absolute input-icon"
                            size={20}
                          />
                          <select
                            style={{ paddingLeft: "40px" }}
                            className="form-control"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                country: e.target.value,
                              })
                            }
                          >
                            <option value="" disabled selected>
                              {" "}
                              {lang?.select_country}
                            </option>
                            {countries.length === 0 && (
                              <option value="" disabled>
                                <h6>Loading ...</h6>
                              </option>
                            )}
                            {countries.map((value) => (
                              <option value={value.id}>{value.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {formData.country && (
                        <div className="mb-3">
                          <div className="position-relative">
                            <CiLocationOn
                              className="position-absolute input-icon"
                              size={20}
                            />
                            <select
                              onClick={fetchCities}
                              style={{ paddingLeft: "40px" }}
                              className="form-control"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  city: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled selected>
                                {lang?.select_city}
                              </option>
                              {cities.length === 0 && (
                                <option value="" disabled>
                                  <h6>Loading ...</h6>
                                </option>
                              )}
                              {cities.map((value) => (
                                <option value={value.id}>{value.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                      <div className="mb-4 form-check">
                        <input
                          className="form-check-input p-10"
                          type="checkbox"
                          checked={DisableBtn}
                          onChange={(e) => {
                            const { checked } = e.target;
                            setDisableBtn(checked);
                          }}
                        />
                        <label className="fs-14 roboto-medium form-check-label ps-1 pt-1">
                          {lang?.by_creating_account_agree}{" "}
                          <a
                            href="https://medicalradar.es/terms-and-conditions"
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue"
                          >
                            {lang?.terms_of_Use}
                          </a>{" "}
                          &{" "}
                          <a
                            href="https://medicalradar.es/privacy-policy"
                            rel="noreferrer"
                            className="text-blue"
                          >
                            {lang?.privacy_policy}
                          </a>
                        </label>
                      </div>

                      <div className="mb-4">
                        <button
                          type="submit"
                          className="py-3 btn-blue w-100 border-0"
                          disabled={!DisableBtn || isLoading}
                        >
                          {isLoading ? (
                            <div className="loader"></div>
                          ) : (
                            lang?.lets_go
                          )}
                        </button>
                      </div>

                      <div className="text-center">
                        <p className="fs-16 roboto-medium text-gray">
                          {lang?.already_account}{" "}
                          <Link
                            onClick={() => setAccountType(null)}
                            className="fs-16 roboto-bold text-blue"
                          >
                            {lang?.login}
                          </Link>{" "}
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default HospitalSignup;
